import { React, Header, Footer } from "../../../cIncludes";

const DashBoardComponent = () => {
  return (
    <>
      <Header hname="bd" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <div className="loanSummary">
                <ul className="dashboard_summaryblk">
                  <li className="dashboard_summaryblk">
                    <div className="txtHead">N/A</div>
                    <div className="txtNormal">Loan Amount</div>
                  </li>
                  <li className="dashboard_summaryblk">
                    <div className="txtHead">N/A</div>
                    <div className="txtNormal">Repayment Schedule</div>
                  </li>
                  <li className="dashboard_summaryblk">
                    <div className="txtHead">100</div>
                    <div className="txtNormal">MyPAZA</div>
                  </li>
                </ul>
              </div>
              <ul className="dashboard_contentblk">
                <li className="dashboard_contentblk">
                  <div className="txtHead">Pre-qualify</div>
                  <div className="txtDesc">
                    Accurate and intuitive. We will hand-hold you to ensure
                    faster loan approval.
                  </div>
                </li>
                <li className="dashboard_contentblk">
                  <div className="txtHead">Purchase Home</div>
                  <div className="txtDesc">
                    Get your custom mortgage rate quote. Earn PAZAs instantly on
                    loan approval.
                  </div>
                </li>
                <li className="dashboard_contentblk">
                  <div className="txtHead">Easy Refinance</div>
                  <div className="txtDesc">
                    Simple, smooth &amp; quicker. Unlock your PAZAs for
                    discounts.
                  </div>
                </li>
                <li className="dashboard_contentblk">
                  <div className="txtHead">Access &amp; Share Documents</div>
                  <div className="txtDesc">
                    100% online, 24/7 availability and secured by Blockchain.
                  </div>
                  <input
                    type="button"
                    className="button_apply"
                    value="Click Here"
                  />
                </li>
                <li className="dashboard_contentblk">
                  <div className="txtHead">Mortgage Tools</div>
                  <div className="txtDesc">Take control of your payments.</div>
                </li>
                <li className="dashboard_contentblk">
                  <div className="txtHead">Home Services</div>
                  <div className="txtDesc">
                    One-stop shop for all your home related needs - from moving
                    in to furnishing.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DashBoardComponent;
