import { React, Link, Header, Footer, Loader, Helper } from "../../../cIncludes";
import { GetShort } from "../../../services/entitiesApi";
import { GetEntityModules, SetEntityModules } from "../../../services/adminApi";
import { GetUserTypes } from "../../../services/commonApi";

const RenderChildItem = ({ item, onUpdateChild }) => {
  const OnDummyChanged = async (e, keyid) => {
    let tmpRows = item.childs;
    let index = tmpRows.findIndex((x) => parseInt(x.id) === parseInt(keyid));
    if (index > -1) {
      tmpRows[index].statusid = e.target.checked ? 1 : 0;
      if (onUpdateChild) onUpdateChild(item.id, tmpRows);
    }
  };

  return (
    item.childs &&
    item.childs.map((x) => (
      <li className="configStructure_child" data={x.id} key={x.id}>
        <input
          type="checkbox"
          id={`chk_child_${x.id}`}
          onChange={(e) => OnDummyChanged(e, x.id)}
          checked={Helper.FromIntToBoolean(x.statusid)}
        />
        <label htmlFor={`chk_child_${x.id}`}>{x.name}</label>
      </li>
    ))
  );
};

const ModuleConfigComponent = ({ refresh, onTabClicked }) => {
  const [initlized, setInitlized] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [rows, setRows] = React.useState({ childs: [] });
  const [entities, setEntities] = React.useState([]);
  const [selectedEntityId, setSelectedEntityId] = React.useState(0);
  const [optionid, setOptionId] = React.useState(0);
  const [defaultSelection, setDefaultSelection] = React.useState(0);
  const [displayName, setDisplayName] = React.useState("");

  React.useEffect(() => {
    setInitlized(true);
  }, [refresh]);

  const FetchResults = async () => {
    setProgress(true);
    let rslt = await GetShort();
    if (rslt.status === 100) {
      rslt.data[0].display_name = "Select Entity";
      setEntities(rslt.data);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  React.useEffect(() => {
    if (selectedEntityId > 0) {
      let tmp = entities.filter(
        (x) => parseInt(x.id) === parseInt(selectedEntityId)
      );
      setDisplayName(tmp[0].display_name);
      const fn = async () => {
        setProgress(true);
        let data = {
          entityid: selectedEntityId,
          usertype: false
        };
        let rslt = await GetEntityModules(data);
        if (rslt.status === 100) {
          let tmp = rslt.data;
          setDefaultSelection(tmp.defaultSelection);
          setOptionId(tmp.optionid);
          setRows({ childs: tmp.childs });
        }
        setProgress(false);
      };

      fn();
    }
  }, [selectedEntityId, entities, setProgress]);

  const OnUpdateClicked = async (e) => {
    let data = {
      list: rows.childs,
      defaultSelection: defaultSelection,
      optionid: optionid,
      usertype: true
    };
    setProgress(true);
    let rslt = await SetEntityModules(data);
    if (rslt.status !== 100) {
      console.log(rslt.statusText);
    }
    setProgress(false);
  };

  const OnCheckedUpdate = async (name, val) => {
    setRows((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnUpdateChild = async (parent, data) => {
    let tmpRows = rows.childs;
    let index = tmpRows.findIndex((x) => parseInt(x.id) === parseInt(parent));
    if (index > -1) {
      tmpRows[index].childs = data;
      let cnt = data.filter((x) => parseInt(x.statusid) > 0);
      if (cnt.length === 0) {
        tmpRows[index].statusid = 0;
      } else {
        tmpRows[index].statusid = 1;
      }
      OnCheckedUpdate("childs", tmpRows);
    }
  };

  const OnDummyChanged = async (e, keyid) => {
    let tmpRows = rows.childs;
    let index = tmpRows.findIndex((x) => parseInt(x.id) === parseInt(keyid));
    if (index > -1) {
      tmpRows[index].statusid = e.target.checked ? 1 : 0;
      OnCheckedUpdate("childs", tmpRows);
    }
  };

  return (
    <>
      <Loader show={progress} />
      <div className="head_blk">
        <div className="blkName">Module Configuration</div>
      </div>
      <ul className="subHead_blk">
        <li className="subHead_blk"><Link to="#" className="current">Modules</Link></li>
        <li className="subHead_blk"><Link to="#" onClick={() => onTabClicked("UserTypes")}>User Types</Link></li>
      </ul>
      <div className="contentarea">
        <div className="noContent" style={{ display: "none" }}>
          No data available
        </div>
        <div className="config">
          <div className="selectModuleBlk">
            <ul className="formColumn">
              <li className="formColumn_III">
                <div className="entityFormicon"></div>
                <select
                  name="filterType"
                  required
                  value={Helper.ToInteger(selectedEntityId, 0)}
                  onChange={(e) =>
                    setSelectedEntityId(e.target.value)
                  }
                >
                  {entities &&
                    entities.map((x, index) => {
                      return (
                        <option value={x.id} key={index}>
                          {x.display_name}
                        </option>
                      );
                    })}
                </select>
              </li>
            </ul>
          </div>
          {selectedEntityId > 0 && (
            <>
              <div className="configHead">{displayName}</div>
              <ul className="formColumn">
                <li className="formColumn_III">
                  <ul className="configStructure">
                    {rows &&
                      rows.childs &&
                      rows.childs.map((x) => (
                        <span key={x.id}>
                          <li
                            className="configStructure_parent"
                            data={x.id}
                          >
                            <input
                              type="checkbox"
                              id={`chk${x.id}`}
                              checked={Helper.FromIntToBoolean(
                                x.statusid
                              )}
                              onChange={(e) =>
                                OnDummyChanged(e, x.id)
                              }
                            />
                            <label htmlFor={`chk${x.id}`}>
                              {x.name}
                            </label>
                          </li>
                          <RenderChildItem
                            item={x}
                            onUpdateChild={OnUpdateChild}
                          />
                        </span>
                      ))}
                  </ul>
                </li>
                <li className="formColumn_III">
                  <ul className="configStructure">
                    <li className="configStructure_parent">
                      <div className="configHead">
                        Loan Search Selection Type
                      </div>
                      <div className="searchSettingFormicon"></div>
                      <div>
                        <select
                          name="filterType"
                          required
                          value={Helper.ToInteger(optionid, 0)}
                          onChange={(e) =>
                            setOptionId(e.target.value)
                          }
                        >
                          <option value="0">
                            Loan Search Selection Type
                          </option>
                          <option value="1">Text Box</option>
                          <option value="2">Dropdown</option>
                        </select>
                      </div>
                    </li>
                    <li
                      className="configStructure_parent"
                      style={{ fontWeight: "normal" }}
                    >
                      <input
                        type="checkbox"
                        id="config11"
                        checked={Helper.FromIntToBoolean(
                          defaultSelection
                        )}
                        onChange={(e) =>
                          setDefaultSelection(
                            e.target.checked ? 1 : 0
                          )
                        }
                      />
                      <label htmlFor="config11">
                        Loan Default Selections
                      </label>
                    </li>
                  </ul>
                </li>
                <li className="formColumn_III">&nbsp;</li>
              </ul>
            </>
          )}
        </div>
        {selectedEntityId > 0 && (
          <>
            <div className="form_btn_label">
              <input
                type="button"
                value="Update"
                className="form_btn"
                onClick={(e) => OnUpdateClicked(e)}
              />
              <input
                type="button"
                value="Reset"
                className="form_btn"
              />
            </div>
            <div
              className="errormessage"
              style={{ display: "none" }}
            >
              All fields are mandatory
            </div>
          </>
        )}
      </div>
    </>
  );
}

const UserTypeConfigComponent = ({ refresh, onTabClicked }) => {
  const [initlized, setInitlized] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [rows, setRows] = React.useState({ childs: [] });
  const [userTypes, setUserTypes] = React.useState([]);
  const [entities, setEntities] = React.useState([]);
  const [selectedEntityId, setSelectedEntityId] = React.useState(0);
  const [optionid, setOptionId] = React.useState(0);
  const [defaultSelection, setDefaultSelection] = React.useState(0);
  const [displayName, setDisplayName] = React.useState("");

  React.useEffect(() => {
    setInitlized(true);
  }, [refresh]);

  const FetchResults = async () => {
    setProgress(true);
    let rslt = await GetShort();
    if (rslt.status === 100) {
      setEntities(rslt.data);
    }
    rslt = await GetUserTypes();
    if (rslt.status === 100) {
      rslt.data[0].label = "Select User Type";
      setUserTypes(rslt.data);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  React.useEffect(() => {
    if (selectedEntityId > 0) {
      let tmp = userTypes.filter(
        (x) => parseInt(x.value) === parseInt(selectedEntityId)
      );
      setDisplayName(tmp[0].label);
      const fn = async () => {
        setProgress(true);
        let data = {
          entityid: selectedEntityId,
          usertype: true
        };
        let rslt = await GetEntityModules(data);
        if (rslt.status === 100) {
          let tmp = rslt.data;
          setDefaultSelection(tmp.defaultSelection);
          setOptionId(tmp.optionid);
          setRows({ childs: tmp.childs });
        }
        setProgress(false);
      };

      fn();
    }
  }, [selectedEntityId, entities, setProgress, userTypes]);

  const OnUpdateClicked = async (e) => {
    let data = {
      list: rows.childs,
      defaultSelection: defaultSelection,
      optionid: optionid,
      usertype: true
    };
    setProgress(true);
    let rslt = await SetEntityModules(data);
    if (rslt.status !== 100) {
      console.log(rslt.statusText);
    }
    setProgress(false);
  };

  const OnCheckedUpdate = async (name, val) => {
    setRows((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnUpdateChild = async (parent, data) => {
    let tmpRows = rows.childs;
    let index = tmpRows.findIndex((x) => parseInt(x.id) === parseInt(parent));
    if (index > -1) {
      tmpRows[index].childs = data;
      let cnt = data.filter((x) => parseInt(x.statusid) > 0);
      if (cnt.length === 0) {
        tmpRows[index].statusid = 0;
      } else {
        tmpRows[index].statusid = 1;
      }
      OnCheckedUpdate("childs", tmpRows);
    }
  };

  const OnDummyChanged = async (e, keyid) => {
    let tmpRows = rows.childs;
    let index = tmpRows.findIndex((x) => parseInt(x.id) === parseInt(keyid));
    if (index > -1) {
      tmpRows[index].statusid = e.target.checked ? 1 : 0;
      OnCheckedUpdate("childs", tmpRows);
    }
  };

  return (
    <>
      <Loader show={progress} />
      <div className="head_blk">
        <div className="blkName">User Type Configuration</div>
      </div>
      <ul className="subHead_blk">
        <li className="subHead_blk"><Link to="#" onClick={() => onTabClicked("Modules")}>Modules</Link></li>
        <li className="subHead_blk"><Link to="#" className="current">User Types</Link></li>
      </ul>
      <div className="contentarea">
        <div className="noContent" style={{ display: "none" }}>
          No data available
        </div>
        <div className="config">
          <div className="selectModuleBlk">
            <ul className="formColumn">
              <li className="formColumn_III">
                <div className="entityFormicon"></div>
                <select
                  name="filterType"
                  required
                  value={Helper.ToInteger(selectedEntityId, 0)}
                  onChange={(e) =>
                    setSelectedEntityId(e.target.value)
                  }
                >
                  {userTypes &&
                    userTypes.map((x, index) => (
                      <option value={x.value} key={index}>
                        {x.label}
                      </option>
                    ))}
                </select>
              </li>
            </ul>
          </div>
          {selectedEntityId > 0 && (
            <>
              <div className="configHead">{displayName}</div>
              <ul className="formColumn">
                <li className="formColumn_III">
                  <ul className="configStructure">
                    {rows &&
                      rows.childs &&
                      rows.childs.map((x) => (
                        <span key={x.id}>
                          <li
                            className="configStructure_parent"
                            data={x.id}
                          >
                            <input
                              type="checkbox"
                              id={`chk${x.id}`}
                              checked={Helper.FromIntToBoolean(
                                x.statusid
                              )}
                              onChange={(e) =>
                                OnDummyChanged(e, x.id)
                              }
                            />
                            <label htmlFor={`chk${x.id}`}>
                              {x.name}
                            </label>
                          </li>
                          <RenderChildItem
                            item={x}
                            onUpdateChild={OnUpdateChild}
                          />
                        </span>
                      ))}
                  </ul>
                </li>
                <li className="formColumn_III">
                  <ul className="configStructure">
                    <li className="configStructure_parent">
                      <div className="configHead">
                        Loan Search Selection Type
                      </div>
                      <div className="searchSettingFormicon"></div>
                      <div>
                        <select
                          name="filterType"
                          required
                          value={Helper.ToInteger(optionid, 0)}
                          onChange={(e) =>
                            setOptionId(e.target.value)
                          }
                        >
                          <option value="0">
                            Loan Search Selection Type
                          </option>
                          <option value="1">Text Box</option>
                          <option value="2">Dropdown</option>
                        </select>
                      </div>
                    </li>
                    <li
                      className="configStructure_parent"
                      style={{ fontWeight: "normal" }}
                    >
                      <input
                        type="checkbox"
                        id="config11"
                        checked={Helper.FromIntToBoolean(
                          defaultSelection
                        )}
                        onChange={(e) =>
                          setDefaultSelection(
                            e.target.checked ? 1 : 0
                          )
                        }
                      />
                      <label htmlFor="config11">
                        Loan Default Selections
                      </label>
                    </li>
                  </ul>
                </li>
                <li className="formColumn_III">&nbsp;</li>
              </ul>
            </>
          )}
        </div>
        {selectedEntityId > 0 && (
          <>
            <div className="form_btn_label">
              <input
                type="button"
                value="Update"
                className="form_btn"
                onClick={(e) => OnUpdateClicked(e)}
              />
              <input
                type="button"
                value="Reset"
                className="form_btn"
              />
            </div>
            <div
              className="errormessage"
              style={{ display: "none" }}
            >
              All fields are mandatory
            </div>
          </>
        )}
      </div>
    </>
  );
}

const AdminModuleConfigComponent = () => {
  const [selModule, setSelModule] = React.useState("Modules");
  const [refresh, setRefresh] = React.useState(false);

  const OnTabClicked = (e) => {
    setSelModule(e)
    setRefresh(!refresh);
  }

  return (
    <>
      <Header hname="as" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_15by85">
                <li className="colLeft250">
                  <div className="blk_container">
                    <ul className="accLeftNavi">
                      <li className="accLeftNavi">
                        <Link to="#" className="current">
                          <div className="leftNavi_configIcon"></div>Module
                          Configuration
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/asd">
                          <div className="leftNavi_userIcon"></div>Document Mapping
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/ade">
                          <div className="leftNavi_userIcon"></div>Document Entity Mapping
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/asr">
                          <div className="leftNavi_userIcon"></div>Role Mapping
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/asa">
                          <div className="leftNavi_settingIcon"></div>Account
                          Settings
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="col_100pc-250">
                  <div className="blk_container">
                    {selModule === "Modules" && <ModuleConfigComponent onTabClicked={OnTabClicked} refresh={refresh} />}
                    {selModule === "UserTypes" && <UserTypeConfigComponent onTabClicked={OnTabClicked} refresh={refresh} />}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AdminModuleConfigComponent;
