import { React, Helper, Link, Loader, Pagination, Session } from "../../../cIncludes";
import { Get as GetEntities } from "../../../services/entitiesApi";
import { Get as GetStatusList } from "../../../services/statuslistApi";

const RenderEntitiesListComponent = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [listItems, setListItems] = React.useState([]);
  const [filterItems, setFilterItems] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [statustypes, setStatusTypes] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);

  const [doSearch, setDoSearch] = React.useState(false);
  const [showFilter, setShowFilters] = React.useState(false);
  const [filtername, setFilterName] = React.useState(null);
  const [search, setSearch] = React.useState({});

  const PageSize = 10;

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);
    let rslt = await GetStatusList();
    if (rslt.status === 100) {
      setStatusTypes(rslt.data);
    }

    rslt = await GetEntities();
    if (rslt.status === 100) {
      setListItems(rslt.data);
      OnAddPaging(rslt.data);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const OnPageChanged = (start, end) => {
    let tmp = filterItems.slice(start, end);
    setRows(tmp);
  };

  const OnSearchInputChanged = (name, val) => {
    setSearch((prev) => ({
      ...prev,
      [name]: val,
    }));

    if (name === "filter") {
      setDoSearch(true);
    }
  };

  const SearchByName = (x, s) => {
    let _name = Helper.GetStringJoin(x, ["entity_name", "display_name", "isp_link_name"]);
    return _name.toUpperCase().indexOf(s.toUpperCase()) > -1;
  }

  const OnFilterItems = () => {
    let items = listItems;
    let filterby = Helper.ToInteger(search.filter, 0);
    let searchby = Helper.ToString(search.name);

    if (!Helper.IsNullValue(searchby)) {
      items = items.filter((x) => SearchByName(x, searchby));
    }

    if (parseInt(filterby) > 0) {
      items = items.filter((x) => parseInt(x.statusid) === parseInt(filterby));
      let tmpFilter = statustypes.filter(
        (x) => parseInt(x.id) === parseInt(filterby)
      );
      if (tmpFilter && tmpFilter.length > 0) {
        setFilterName(tmpFilter[0].name);
      }
    }

    OnAddPaging(items);
    setShowFilters(!Helper.IsNullValue(searchby) || parseInt(filterby) > 0);
  };

  const OnAddPaging = (items) => {
    Session.Store("CurrentPage", 1);
    Session.Store("RowsCount", items.length);
    setFilterItems(items);
    setRefresh(!refresh);
    setRows(items.slice(0, PageSize));
  };

  if (doSearch) {
    setDoSearch(false);
    OnFilterItems();
  }

  const onSearchClicked = async () => {
    setDoSearch(true);
  };

  const OnClearSearch = (e) => {
    let _name = Helper.ToString(search.name);
    let _filter = Helper.ToString(search.filter);
    if (e === "filter") {
      setSearch({ name: _name, filter: 0 });
    } else if (e === "name") {
      setSearch({ name: null, filter: _filter });
    } else if (e === "ALL") {
      setSearch({ name: null, filter: 0 });
    }
    setDoSearch(true);
  };

  return (
    <>
      <Loader show={progress} />
      <div className="blk_container">
        <div className="head_blk">
          <div className="blkName">Entities</div>
          <Pagination
            refresh={refresh}
            pages={PageSize}
            onPageChanged={OnPageChanged}
          />
          <div className="head_right">
            <input
              type="text"
              placeholder="Search by Name"
              value={Helper.ToString(search.name)}
              onChange={(e) => OnSearchInputChanged("name", e.target.value)}
            />
            <input
              type="button"
              className="searchicon"
              onClick={() => onSearchClicked()}
            />
          </div>
          <div className="head_right">
            <select
              name="filterStatus"
              required
              value={Helper.ToString(search.filter)}
              onChange={(e) => OnSearchInputChanged("filter", e.target.value)}
            >
              {statustypes &&
                statustypes.map((x, index) => {
                  return (
                    <option value={x.id} key={index}>
                      {x.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        {showFilter && (
          <div className="filterBlock">
            {!Helper.IsNullValue(search.name) && (
              <div className="filter">
                <b>Entity / Display Name / ISP Link:</b> {search.name}
                <div className="closeicon">
                  <Link
                    to="#"
                    title="Close"
                    onClick={(e) => OnClearSearch("name")}
                  >
                    &#215;
                  </Link>
                </div>
              </div>
            )}
            {Helper.ToInteger(search.filter) > 0 && (
              <div className="filter">
                <b>Status:</b>
                {filtername}
                <div className="closeicon">
                  <Link
                    to="#"
                    title="Close"
                    onClick={(e) => OnClearSearch("filter")}
                  >
                    &#215;
                  </Link>
                </div>
              </div>
            )}
            {!Helper.IsNullValue(search.name) &&
              Helper.ToInteger(search.filter) > 0 && (
                <input
                  type="button"
                  className="button_clear"
                  style={{ marginLeft: 2 }}
                  value="Clear All"
                  onClick={(e) => OnClearSearch("ALL")}
                />
              )}
          </div>
        )}
        <div className="contentarea_table">
          <div className="entityTable_head">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Link to="#">Entity Name</Link>
                  </td>
                  <td>
                    <Link to="#">Display Name</Link>
                  </td>
                  <td>ISP Link</td>
                  <td>Status</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
          </div>
          {rows && rows.length > 0 ? (
            <>
              <div className="entityTable">
                <table>
                  <tbody>
                    {rows.map((x, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Link to="#">{x.entity_name}</Link>
                          </td>
                          <td>{x.display_name}</td>
                          <td>{x.isp_link_name}</td>
                          <td>{x.statusname}</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="noContent">You have not created any entity</div>
          )}
        </div>
      </div>
    </>
  );
};

export default RenderEntitiesListComponent;
