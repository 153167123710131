import { React, Link, Session, Loader, Helper, Header, Footer, Pagination } from "../../../cIncludes";
import { SearchLoan } from "../../../services/loanApi";

const SearchLoanComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [listItems, setListItems] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [search, setSearch] = React.useState([]);
  const [showFilter, setShowFilter] = React.useState(false);
  const [doSearch, setDoSearch] = React.useState(false);

  const PageSize = 10;

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  React.useEffect(() => {
    setDoSearch(false);
    const fn = async function () {
      setProgress(true);
      ResetCounters();
      search['lookup'] = true;
      let rslt = await SearchLoan(search);
      setProgress(false);
      if (rslt.status !== 100) {
        setRefresh(!refresh);
        return;
      }
      setListItems(rslt.data);
      Session.Store("RowsCount", rslt.data.length);
      setRows(rslt.data.slice(0, PageSize));
      setRefresh(!refresh);
      setShowFilter(true);
    };

    if (doSearch) fn();
  }, [doSearch, refresh, search]);

  if (initlized) {
    setInitlized(false);
    setRows([]);
  }

  const OnInputChanged = (name, val) => {
    setSearch((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const ResetCounters = () => {
    setShowFilter(false);
    Session.Store("CurrentPage", 1);
    Session.Store("RowsCount", 0);
    setListItems([]);
    setRows([]);
  };

  const OnPageChanged = (start, end) => {
    let tmp = listItems.slice(start, end);
    setRows(tmp);
  };

  const OnSearchCleared = (e) => {
    let _search = search;
    if (e === "all") {
      _search = [];
    } else {
      if (e === "loannumber") {
        delete _search["loannumber"];
        //OnInputChanged("loannumber", null);
      }
      if (e === "borrower") {
        delete _search["loannumber"];
        //OnInputChanged("borrower", null);
      }
      if (e === "lender") {
        delete _search["loannumber"];
        //OnInputChanged("lender", null);
      }
    }
    if (Helper.IsJSONEmpty(_search)) {
      showFilter(false);
    }
    setSearch(_search);
    setDoSearch(true);
  };

  const OnKeyPressEvent = (e) => {
    let charCode = e.charCode || e.which;
    if (charCode === 13) {
      setDoSearch(true);
    }
  };

  return (
    <>
      <Loader show={progress} />
      <Header hname="udc" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="breadcrumb">
                <li className="breadcrumb_home"></li>
                <li className="breadcrumb">Loans</li>
                <li className="breadcrumb">Search Loan</li>
              </ul>

              <div className="loanSummary">
                <ul className="summaryVblk">
                  <li className="searchLoan">
                    <div className="summaryHead">Loan Number:</div>
                    <div className="loanSearchTxtBox">
                      <input
                        type="text"
                        value={Helper.ToString(search.loannumber)}
                        onChange={(e) =>
                          OnInputChanged("loannumber", e.target.value)
                        }
                        onKeyPress={(e) => OnKeyPressEvent(e)}
                      />
                    </div>
                  </li>
                  <li className="searchLoan">
                    <div className="summaryHead">Borrower Name:</div>
                    <div className="loanSearchTxtBox">
                      <input
                        type="text"
                        value={Helper.ToString(search.borrower)}
                        onChange={(e) =>
                          OnInputChanged("borrower", e.target.value)
                        }
                        onKeyPress={(e) => OnKeyPressEvent(e)}
                      />
                    </div>
                  </li>
                  <li className="searchLoan">
                    <div className="summaryHead">Lender Name:</div>
                    <div className="loanSearchTxtBox">
                      <input
                        type="text"
                        value={Helper.ToString(search.lender)}
                        onChange={(e) =>
                          OnInputChanged("lender", e.target.value)
                        }
                        onKeyPress={(e) => OnKeyPressEvent(e)}
                      />
                    </div>
                  </li>
                  <li className="searchLoan">
                    <div className="loanSearchButBlk">
                      <input
                        type="button"
                        value="Search Loan"
                        className="loanSearchButton"
                        onClick={(e) => setDoSearch(true)}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col_1">
                <div className="blk_container">
                  <div className="head_blk">
                    <div className="blkName">Loans List</div>
                    {rows && rows.length > 0 && (
                      <Pagination
                        refresh={refresh}
                        pages={PageSize}
                        onPageChanged={OnPageChanged}
                      />
                    )}
                  </div>
                  {showFilter && (
                    <div className="filterBlock">
                      {search && !Helper.IsNullValue(search.loannumber) && (
                        <div className="filter">
                          <b>Loan Number:</b> {search.loannumber}
                          <div className="closeicon">
                            <Link
                              to="#"
                              title="Close"
                              onClick={(e) => OnSearchCleared("loannumber")}
                            >
                              &#215;
                            </Link>
                          </div>
                        </div>
                      )}
                      {search && !Helper.IsNullValue(search.borrower) && (
                        <div className="filter">
                          <b>Borrower Name:</b> {search.borrower}
                          <div className="closeicon">
                            <Link
                              to="#"
                              title="Close"
                              onClick={(e) => OnSearchCleared("borrower")}
                            >
                              &#215;
                            </Link>
                          </div>
                        </div>
                      )}
                      {search && !Helper.IsNullValue(search.lender) && (
                        <div className="filter">
                          <b>Lender Name:</b> {search.lender}
                          <div className="closeicon">
                            <Link
                              to="#"
                              title="Close"
                              onClick={(e) => OnSearchCleared("lender")}
                            >
                              &#215;
                            </Link>
                          </div>
                        </div>
                      )}
                      {search && (
                        <div className="filter">
                          <b>Clear All</b>
                          <div className="closeicon">
                            <Link
                              to="#"
                              title="Close"
                              onClick={(e) => OnSearchCleared("all")}
                            >
                              &#215;
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="contentarea_table">
                    {rows && rows.length > 0 ? (
                      <>
                        <div className="loanListTable_head">
                          <table>
                            <tbody>
                              <tr>
                                <td>Loan Number</td>
                                <td>Borrower Name</td>
                                <td>Lender Name</td>
                                <td>Loan Amount</td>
                                <td>Closing Date</td>
                                <td>Property Address</td>
                                <td>Owner</td>
                                <td>Actions</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="loanListTable">
                          <table>
                            <tbody>
                              {rows.map((x, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{x.loannumber}</td>
                                    <td>{x.borrower}</td>
                                    <td>{x.lendername}</td>
                                    <td>{x.loanamount}</td>
                                    <td>
                                      {Helper.ToDate(
                                        x.closingdate,
                                        "YYYY-MMM-DD"
                                      )}
                                    </td>
                                    <td>{x.propaddress}</td>
                                    <td>{x.owner}</td>
                                    <td>
                                      <Link
                                        to={`/uls/${x.loannumber}`}
                                        title="Stakeholders"
                                      >
                                        <div className="stakeholdericon"></div>
                                      </Link>
                                      <Link
                                        to={`/ucv/${x.loannumber}`}
                                        title="View Document"
                                      >
                                        <div className="viewicon"></div>
                                      </Link>
                                      <Link
                                        to={`/ucu/${x.loannumber}`}
                                        title="Upload Document"
                                      >
                                        <div className="uploadicon"></div>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <div className="noContent">No Loans available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SearchLoanComponent;
