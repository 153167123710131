import API from "./api";
import session from "../session";

const Add = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`users`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Get = async (type) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`users?type=${type}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetByEntity = async (type) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getbyentity/${type}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetProfile = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`userprofile`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Set = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`userprofile`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetProfileStatus = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`userstatus`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const ForgotPassword = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`forgotpws`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const ChangePassword = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`changepws`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetStakeHolders = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getstakeholders?l=${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetEntityUsers = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getentityusers`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export {
  Add,
  Get,
  GetProfile,
  Set,
  SetProfileStatus,
  ForgotPassword,
  ChangePassword,
  GetStakeHolders,
  GetEntityUsers,
  GetByEntity
};
