import { React, Header, Footer, Loader, Helper } from "../../../cIncludes";
import {
  GetType as GetDocTypes,
  SubmitDoc, ValidateDocTypes
} from "../../../services/documentsApi";
import RenderLoanSummary from "../common/RenderLoanSummary";
import RenderUploadDocument from "./childs/RenderUploadDocument";
import RenderDocumentList from "./childs/RenderDocumentList";
import RenderStakeHolders from "./childs/RenderStakeHolders";
import RolesClass from "../../../helper/roles";

const DocumentComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [loanId, setLoanId] = React.useState(0);
  const [loanNumber, setLoanNumber] = React.useState(null);
  const [typeId, setTypeId] = React.useState(0);
  const [docCount, setDocCount] = React.useState(0);
  const [doctypes, setDocTypes] = React.useState([]);
  const [doctypefields, setDocTypeFields] = React.useState([]);
  const [doctypename, setDocTypeName] = React.useState(null);
  const [reaload, setReaload] = React.useState(false);
  const [error, setError] = React.useState(null);
  /* const [noUpload, setNoUpload] = React.useState(false); */
  const [noParties, setNoParties] = React.useState(false);
  const [loadDetails, setLoadDetails] = React.useState(false);
  const [borrowers, setBorrowers] = React.useState([]);
  const [lenderId, setLenderId] = React.useState(null);
  const [checklist, setCheckList] = React.useState(false);
  const [doUpload, setDoUpload] = React.useState(false);
  const [requestApproval, setRequestApproval] = React.useState(false);


  let RolesObj = new RolesClass();

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);
    setError(null);
    //setBorrowers([]);
    //setLenderId(null);
    setNoParties(false);
    //setDoReload(false);
    let tmp;
    let rslt = await GetDocTypes();
    if (rslt.status === 100) {
      tmp = rslt.data;
      tmp.splice(0, 0, { id: "0", name: "Select" });
      setDocTypes(tmp);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    //setBorrowers([]);
    //setLenderId(null);
    setDocTypeFields([]);
    FetchResults();
  }

  React.useEffect(() => {
    const fn = async () => {
      setDoUpload(false);
      setNoParties(false);
      setDocTypeFields([]);
      setDocTypeName(null);
      setLoadDetails(false);
      if (parseInt(typeId) > 0) {
        setProgress(true);
        let rslt = await ValidateDocTypes(loanId, typeId);
        if (rslt.status === 400) {
          setNoParties(true);
          setProgress(false);
        } else {
          let tmp = doctypes.filter((x) => parseInt(x.id) === parseInt(typeId));
          if (tmp && tmp.length > 0 && tmp[0].fields) {
            setDocTypeFields(JSON.parse(tmp[0].fields));
          }
          let _docname = null;
          if (tmp && tmp.length > 0) {
            _docname = tmp[0].name;
          }
          setDocTypeName(_docname);
          setLoadDetails(true);
          setTimeout(() => {
            setDoUpload(true);
            setProgress(false);
          }, 500);
        }
      }
    };
    fn();
  }, [typeId, doctypes, loanId]);

  const OnLoadStakeHolders = async (_loanId, _loanNumber, _borrowers, _lenderid) => {
    OnResetClicked();
    setLoanId(_loanId);
    setLoanNumber(_loanNumber);
    setBorrowers(_borrowers);
    setLenderId(_lenderid);
  };

  const OnCheckListCompleted = async (bSuccess, statusText) => {
    console.log(`OnCheckListCompleted:${bSuccess} - ${statusText}`);
    setCheckList(false);
    setError(null);
    if (bSuccess) {
      setProgress(true);
      let _approval = requestApproval ? 1 : 0;
      await SubmitDoc(loanId, typeId, _approval).then((res) => {
        setProgress(false);
        console.log(res);
        setError(res.statusText);
        if (res.status === 100) {
          setTimeout(() => { OnResetClicked(true); }, 100);
        }
      });
    } else {
      setError(statusText);
    }
  }

  const OnSubmitClicked = async (e) => {
    e.preventDefault();
    setError(null);
    if (docCount === 0) {
      setError("No documents are uploded");
      return;
    }
    setError(null);
    setCheckList(true);
  };

  const OnResetClicked = (e) => {
    setDocCount(0);
    setRequestApproval(false);
    if (!e) {
      setBorrowers([]);
      setLenderId(null);
    }
    setTypeId(0);
  };

  const OnDocTypeChanged = (e) => {
    e.preventDefault();
    setError(null);
    setTypeId(parseInt(e.target.value));
  };

  const OnSetDocTypeFields = (id) => {
    let tmp = doctypes.filter((x) => parseInt(x.id) === parseInt(id));
    setDocTypeFields([]);
    if (tmp && tmp.length > 0 && tmp[0].fields) {
      setDocTypeFields(JSON.parse(tmp[0].fields));
    }
    let _docname = null;
    if (tmp && tmp.length > 0) {
      _docname = tmp[0].name;
    }
    setDocTypeName(_docname);
  };

  const OnDocCountChanged = (e) => {
    OnSetDocTypeFields(typeId);
    setDocCount(e);
    setError(null);
    /* if (e > 0) {
      setNoUpload(true);
    } else {
      setNoUpload(false);
    } */
  };

  const RendorStatusMessage = (e) => {
    if (noParties) {
      return <div className="doctypenoteserror">First and Second parties are not configured for this loan</div>;
    } else {
      return <div className="doctypenotes">
        Use reset to select some other document type
      </div>
    }
  };

  const OnDummyChange = () => { };

  return (
    <>
      <Loader show={progress} />
      <Header hname="uc" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="breadcrumb">
                <li className="breadcrumb_home"></li>
                <li className="breadcrumb">Documents</li>
                <li className="breadcrumb">Upload Documents</li>
              </ul>
              {RolesObj.IsUploadDocument ? (
                <>
                  <RenderLoanSummary
                    onLoadStakeHolders={OnLoadStakeHolders}
                    upload={true}
                  />
                  {Helper.ToInteger(loanId) > 0 && (
                    <div className="col_1">
                      <div className="blk_container">
                        <div className="head_blk">
                          <div className="blkName">
                            Upload Documents for Loan No:
                            {Helper.ToString(loanNumber)}
                          </div>
                        </div>
                        <div className="contentarea_bgwhite">
                          <ul className="formColumn">
                            <li className="formColumn_I">
                              <div className="head_blk_IIlevel">
                                <div
                                  className="IIlevel_ContentHead"
                                  style={{ paddingRight: 5 }}
                                >
                                  Document Type
                                </div>
                                <div className="IIlevel_ContentHead_inline">
                                  <div className="selectDocType">
                                    <select
                                      name="filterStatus"
                                      required
                                      value={Helper.ToString(typeId)}
                                      onChange={(e) => OnDocTypeChanged(e)}
                                      disabled={
                                        Helper.ToInteger(loanId) < 1 ||
                                          docCount > 0
                                          ? "disabled"
                                          : undefined
                                      }
                                    >
                                      {doctypes &&
                                        doctypes.map((x, index) => {
                                          return (
                                            <option value={x.id} key={index}>
                                              {x.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <RendorStatusMessage />
                              </div>
                              {doUpload && loadDetails && (
                                <>
                                  <div className="contentarea_stakeholder_doc">
                                    <RenderUploadDocument
                                      typeid={typeId}
                                      typename={doctypename}
                                      loanId={loanId}
                                      loanNumber={loanNumber}
                                      setReaload={setReaload}
                                      reaload={reaload}
                                      fields={doctypefields}
                                      disableUpload={false}
                                      borrowers={borrowers}
                                      lenderId={lenderId}
                                    />
                                    <RenderDocumentList
                                      typeid={typeId}
                                      loanId={loanId}
                                      reaload={reaload}
                                      onDocCountChanged={OnDocCountChanged}
                                    />
                                  </div>
                                  {(RolesObj.IsUploadDocAccessEdit ||
                                    RolesObj.IsUploadDocAccessView) && (
                                      <RenderStakeHolders
                                        typeid={typeId}
                                        loanId={loanId}
                                        onCheckListCompleted={OnCheckListCompleted}
                                        checklist={checklist}
                                      />
                                    )}
                                  <div className="form_btn_label_notop">
                                    <input
                                      type="button"
                                      value="Submit"
                                      className="form_btn"
                                      onClick={(e) => OnSubmitClicked(e)}
                                    />
                                    <input
                                      type="button"
                                      value="Reset"
                                      className="form_btn"
                                      onClick={(e) => OnResetClicked(e)}
                                    />
                                    <div className="chkbox">
                                      <input
                                        type="checkbox"
                                        id="reqapproval"
                                        name="reqapproval"
                                        checked={requestApproval}
                                        onChange={() => OnDummyChange()}
                                        onClick={(e) => setRequestApproval(e.target.checked)}
                                      />
                                      <label htmlFor="reqapproval">Request for Approval</label>
                                    </div>
                                  </div>
                                  {error && (
                                    <div className="errormessage">{error}</div>
                                  )}
                                </>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="noContent">
                  Oops! You do not have permission to upload files.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DocumentComponent;
