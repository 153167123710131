import { React, Link, Helper } from "../../../../cIncludes";
import RenderBorrowerSelect from "../../common/RenderBorrowerSelect";

const Component = (prop) => {
    const { index, item, count, setProgress, races, ethnicities, onUpdateBorrower, errors } = prop;
    const [showBorrower, setShowBorrower] = React.useState(false);

    const OnInputChanged = (name, val) => {
        if (onUpdateBorrower) onUpdateBorrower(index, name, val);
    };

    const OnDeleteItem = (e) => {
        e.preventDefault();
        OnInputChanged('statusid', 2);
    };

    const OnShowProgress = (e) => {
        if (setProgress) setProgress(e);
    };

    const OnBorrowerSelect = (rec) => {
        let _borName = Helper.GetStringJoin(rec, [
            "first_name",
            "middle_name",
            "last_name",
        ]);

        OnInputChanged("borrower", _borName);
        OnInputChanged("borrower_id", rec.ispid);
        setShowBorrower(false);
    };

    return (
        <>
            <ul className="formColumn">
                <li className="formColumn_II">
                    <div className="formContentHead">
                        Borrower Name
                    </div>
                    <div className="formMandatory">*</div>
                    <div className="formContentHead_right">
                        <div className="noteLink">
                            <Link
                                to="#"
                                onClick={(e) => setShowBorrower(true)}
                            >
                                Search Borrower
                            </Link>
                        </div>
                    </div>
                    <div className="userFormicon"></div>
                    <input
                        type="text"
                        value={Helper.ToString(item?.borrower)}
                        style={{
                            borderColor:
                                errors && errors.indexOf(`borrower_${index}`) > -1
                                    ? "#ff0000"
                                    : undefined,
                        }}
                        readOnly
                    />
                </li>
                <li className="formColumn_II">
                    <div className="formContentHead">FICO Score</div>
                    <div className="formMandatory">*</div>
                    <div className="numberFormicon"></div>
                    <input
                        type="text"
                        value={Helper.ToString(item?.ficoscore)}
                        style={{
                            borderColor:
                                errors && errors.indexOf(`ficoscore_${index}`) > -1
                                    ? "#ff0000"
                                    : undefined,
                        }}
                        onChange={(e) =>
                            OnInputChanged("ficoscore", e.target.value)
                        }
                        onKeyPress={(e) => {
                            return Helper.AcceptedChars(e, "FINANCE");
                        }}
                    />
                </li>
                <li className="formColumn_II">
                    <div className="formContentHead">Gender</div>
                    <div className="formMandatory">*</div>
                    <div className="genderFormicon"></div>
                    <select
                        name="filterType"
                        required
                        value={Helper.ToString(item?.gender)}
                        style={{
                            borderColor:
                                errors && errors.indexOf(`gender_${index}`) > -1
                                    ? "#ff0000"
                                    : undefined,
                        }}
                        onChange={(e) =>
                            OnInputChanged("gender", e.target.value)
                        }
                    >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Transgender">Transgender</option>
                    </select>
                </li>
                <li className="formColumn_II">
                    <div className="formContentHead">
                        DTI (Debt-to-Income in %)
                    </div>
                    <div className="formMandatory">*</div>
                    <div className="amountFormicon"></div>
                    <input
                        type="text"
                        value={Helper.ToString(item?.dti)}
                        style={{
                            borderColor:
                                errors && errors.indexOf(`dti_${index}`) > -1
                                    ? "#ff0000"
                                    : undefined,
                        }}
                        onChange={(e) =>
                            OnInputChanged("dti", e.target.value)
                        }
                        onKeyPress={(e) => {
                            return Helper.AcceptedChars(e, "FINANCE");
                        }}
                    />
                </li>
                <li className="formColumn_II">
                    <div className="formContentHead">Ethnicity</div>
                    <div className="formMandatory">*</div>
                    <div className="userFormicon"></div>
                    <select
                        name="filterType"
                        required
                        value={Helper.ToString(item?.ethnicity)}
                        style={{
                            borderColor:
                                errors && errors.indexOf(`ethnicity_${index}`) > -1
                                    ? "#ff0000"
                                    : undefined,
                        }}
                        onChange={(e) =>
                            OnInputChanged("ethnicity", e.target.value)
                        }
                    >
                        <option value="">Select</option>
                        {ethnicities &&
                            ethnicities.map((x, index) => {
                                return (
                                    <option value={x.name} key={index}>
                                        {x.name}
                                    </option>
                                );
                            })}
                    </select>
                </li>
                <li className="formColumn_II">
                    <div className="formContentHead">
                        Income (Monthly)
                    </div>
                    <div className="formMandatory">*</div>
                    <div className="amountFormicon"></div>
                    <input
                        type="text"
                        value={Helper.ToString(item?.income)}
                        style={{
                            borderColor:
                                errors && errors.indexOf(`income_${index}`) > -1
                                    ? "#ff0000"
                                    : undefined,
                        }}
                        onChange={(e) =>
                            OnInputChanged("income", e.target.value)
                        }
                        onKeyPress={(e) => {
                            return Helper.AcceptedChars(e, "FINANCE");
                        }}
                    />
                </li>
                <li className="formColumn_II">
                    <div className="formContentHead">Race</div>
                    <div className="formMandatory">*</div>
                    <div className="userFormicon"></div>
                    <select
                        name="filterType"
                        required
                        value={Helper.ToString(item?.race)}
                        style={{
                            borderColor:
                                errors && errors.indexOf(`race_${index}`) > -1
                                    ? "#ff0000"
                                    : undefined,
                        }}
                        onChange={(e) =>
                            OnInputChanged("race", e.target.value)
                        }
                    >
                        <option value="">Select</option>
                        {races &&
                            races.map((x, index) => {
                                return (
                                    <option value={x.name} key={index}>
                                        {x.name}
                                    </option>
                                );
                            })}
                    </select>
                </li>
                <li className="formColumn_II">
                    <div className="formContentHead">Is Primary Borower?</div>
                    <div className="genericBox">
                        <ul className="formColumn">
                            <li className="formColumn_II">
                                <div className="checkRadio_Lablel">
                                    <input
                                        type="radio"
                                        id={`${index}_isprimary_1`}
                                        name={`${index}_isprimary`}
                                        onChange={(e) => OnInputChanged("isprimary", true)}
                                        checked={Helper.ToChecked(item?.isprimary) === true}
                                    />
                                    <label htmlFor={`${index}_isprimary_1`}>Yes</label>
                                </div>
                            </li>
                            <li className="formColumn_II">
                                <div className="checkRadio_Lablel">
                                    <input
                                        type="radio"
                                        id={`${index}_isprimary_2`}
                                        name={`${index}_isprimary`}
                                        onChange={(e) => OnInputChanged("isprimary", false)}
                                        checked={Helper.ToChecked(item?.isprimary) === false}
                                    />
                                    <label htmlFor={`${index}_isprimary_2`}>No</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
            {count > 1 && (
                <div className="formPartition">
                    <div className="closePartition">
                        <Link to="#" onClick={(e) => OnDeleteItem(e)}>✕ &nbsp;Delete</Link>
                    </div>
                </div>
            )}
            <RenderBorrowerSelect
                onProgress={OnShowProgress}
                showborrower={showBorrower}
                onBorrowerSelect={OnBorrowerSelect}
            />
        </>
    );
};

export default Component;