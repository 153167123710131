import {
  React, Link, Pagination, ViewPdfDoc,
  Session, Helper, Loader, Header, Footer
} from "../../../cIncludes";

import {
  GetISPDocuments,
  GetDocUrl,
  SetISPDocStatus,
} from "../../../services/documentsApi";
import { Get as GetPersonas } from "../../../services/usersApi";

import RenderLoanSummary from "../common/RenderLoanSummary";

const DocumentViewComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [loanreload, setSetLoanReload] = React.useState(false);
  const [listItems, setListItems] = React.useState([]);
  const [filterItems, setFilterItems] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [personas, setPersonas] = React.useState([]);

  const [refresh, setRefresh] = React.useState(false);
  const [showFilter, setShowFilters] = React.useState(false);
  const [loanId, setLoanId] = React.useState(0);

  const [pdfStream, setPdfStream] = React.useState(null);
  const [viewPdf, setViewPdf] = React.useState(false);
  const [viewType, setViewType] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [filterByIdChanged, setFilterByIdChanged] = React.useState(false);
  const [filterById, setFilterById] = React.useState(0);
  const [filterByTextChanged, setFilterByTextChanged] = React.useState(false);
  const [filterByText, setFilterByText] = React.useState(null);
  const [filterByTextCleared, setFilterByTextCleared] = React.useState(false);

  const PageSize = 10;

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);
    let rslt = await GetPersonas();
    if (rslt.status === 100) {
      let tmp = rslt.data;
      tmp.splice(0, 0, { id: "0", first_name: "Uploaded By" });
      setPersonas(tmp);
    }

    setProgress(false);
  };

  if (initlized) {
    setSetLoanReload(false);
    setInitlized(false);
    setViewPdf(false);
    setShowFilters(false);
    setFilterByIdChanged(false);
    setFilterByTextChanged(false);
    FetchResults();
  }

  const LoadDocumentsList = async () => {
    setProgress(true);
    setError(null);
    setRows({ childs: [] });
    let rslt = await GetISPDocuments(loanId, -100);
    //console.log(rslt);
    setProgress(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    setListItems(rslt.data);
    onSearchClicked(rslt.data);
  };

  const OnLoadStakeHolders = async (loanId, loanNumber) => {
    setLoanId(loanId);
  };

  React.useEffect(() => {
    if (parseInt(loanId) > 0) {
      setSetLoanReload(true);
    }
  }, [loanId]);

  if (loanreload) {
    setSetLoanReload(false);
    LoadDocumentsList();
  }

  const OnPageChanged = (start, end) => {
    let tmp = filterItems.slice(start, end);
    setRows(tmp);
  };

  const SearchByName = (x, s) => {
    let _name = Helper.GetStringJoin(x, ["docname", "docinfo"]);
    return _name.toUpperCase().indexOf(s.toUpperCase()) > -1;
  }

  const onSearchClicked = async (source) => {
    let items = source || listItems;

    setShowFilters(false);
    setFilterByTextChanged(false);

    if (!Helper.IsNullValue(filterByText)) {
      items = items.filter((x) => SearchByName(x, filterByText));
      /* items = items.filter(
        (x) =>
          x.docname.toUpperCase().indexOf(filterByText.toUpperCase()) > -1 ||
          x.docinfo.toUpperCase().indexOf(filterByText.toUpperCase()) > -1
      ); */
      setShowFilters(true);
      setFilterByTextChanged(true);
    }

    if (parseInt(filterById) > 0) {
      items = items.filter((x) => parseInt(x.userid) === parseInt(filterById));
      setShowFilters(true);
    }

    Session.Store("CurrentPage", 1);
    Session.Store("RowsCount", items.length);
    setFilterItems(items);
    setRefresh(!refresh);
    setRows(items.slice(0, PageSize));
  };

  const GetFilterUser = () => {
    let tmp = personas.filter((x) => parseInt(x.id) === parseInt(filterById));
    if (tmp.length > 0) {
      return Helper.GetStringJoin(tmp[0], ["first_name", "last_name"]);
    }
    return null;
  };

  const OnLinkOpen = async (e, type) => {
    let data = {
      doc: e,
      type: type,
    };
    setViewType(type);
    setProgress(true);
    let rslt = await GetDocUrl(data);
    setProgress(false);
    if (rslt.status === 100) {
      setPdfStream(rslt.data);
      setViewPdf(true);
    } else {
      alert(rslt.statusText);
    }
  };

  const OnClearSearch = (e) => {
    setFilterById(0);
    setFilterByText(null);
    setShowFilters(false);
    onSearchClicked();
  };

  React.useEffect(() => {
    setFilterByIdChanged(true);
  }, [filterById]);

  React.useEffect(() => {
    if (filterByTextChanged) setFilterByTextCleared(true);
  }, [filterByTextChanged, filterByText]);

  if (filterByIdChanged) {
    setFilterByIdChanged(false);
    let tmp = {
      name: filterByText,
      filter: filterById,
    };
    onSearchClicked(listItems, tmp);
  }

  if (filterByTextCleared) {
    setFilterByTextCleared(false);
    onSearchClicked(listItems);
  }

  const IsResultsFound = () => {
    return Helper.ToInteger(loanId) > 0 && rows && rows.length > 0;
  };

  const IsLoanFound = () => {
    return Helper.ToInteger(loanId) > 0;
  };

  const SetDocActiveStatus = async (item, statusid) => {
    item.is_active = statusid === 1 ? true : false;
    setProgress(true);
    let rslt = await SetISPDocStatus(item);
    setProgress(false);
    if (rslt.status !== 100) {
      item.is_active = statusid === 1 ? false : true;
      return;
    }
  };

  const RenderDocActiveStatus = ({ item }) => {
    let isActive = item.is_active;
    if (isActive) {
      return (
        <Link to="#" title="Enable" onClick={() => SetDocActiveStatus(item, 0)}>
          <div className="enableicon"></div>
        </Link>
      );
    } else {
      return (
        <Link
          to="#"
          title="Disable"
          onClick={() => SetDocActiveStatus(item, 1)}
        >
          <div className="disableicon"></div>
        </Link>
      );
    }
  };

  return (
    <>
      <Loader show={progress} />
      <Header hname="uc" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="breadcrumb">
                <li className="breadcrumb_home"></li>
                <li className="breadcrumb">Documents</li>
                <li className="breadcrumb">View Documents</li>
              </ul>
              <RenderLoanSummary
                onLoadStakeHolders={OnLoadStakeHolders}
                viewonly={true}
              />
              {IsLoanFound() && !error && (
                <div className="col_1">
                  <div className="blk_container">
                    <div className="head_blk">
                      <div className="blkName">View Documents</div>
                      <Pagination
                        refresh={refresh}
                        pages={PageSize}
                        onPageChanged={OnPageChanged}
                      />
                      <div className="head_right">
                        <input
                          type="text"
                          placeholder="Search by Name"
                          value={Helper.ToString(filterByText)}
                          onChange={(e) => setFilterByText(e.target.value)}
                        />
                        <input
                          type="button"
                          className="searchicon"
                          onClick={() => onSearchClicked()}
                        />
                      </div>
                      <div className="head_right">
                        <select
                          name="filterStatus"
                          required
                          value={Helper.ToString(filterById)}
                          onChange={(e) => setFilterById(e.target.value)}
                        >
                          {personas &&
                            personas.map((x, index) => {
                              return (
                                <option value={x.id} key={index}>
                                  {Helper.GetStringJoin(x, [
                                    "first_name",
                                    "last_name",
                                  ])}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    {showFilter && (
                      <div className="filterBlock">
                        {!Helper.IsNullValue(filterByText) &&
                          filterByTextChanged && (
                            <div className="filter">
                              <b>Doc Name/Info:</b> {filterByText}
                              <div className="closeicon">
                                <Link
                                  to="#"
                                  title="Close"
                                  onClick={(e) => setFilterByText(null)}
                                >
                                  &#215;
                                </Link>
                              </div>
                            </div>
                          )}
                        {Helper.ToInteger(filterById) > 0 && (
                          <div className="filter">
                            <b>Uploaded By:</b> {GetFilterUser()}
                            <div className="closeicon">
                              <Link
                                to="#"
                                title="Close"
                                onClick={(e) => setFilterById(0)}
                              >
                                &#215;
                              </Link>
                            </div>
                          </div>
                        )}
                        {!Helper.IsNullValue(filterByText) &&
                          Helper.ToInteger(filterById) > 0 &&
                          filterByTextChanged && (
                            <input
                              type="button"
                              className="button_clear"
                              style={{ marginLeft: 2 }}
                              value="Clear All"
                              onClick={(e) => OnClearSearch()}
                            />
                          )}
                      </div>
                    )}
                    <div className="contentarea_table">
                      <div className="viewISPTable_head">
                        <table>
                          <tbody>
                            <tr>
                              <td style={{ width: "1px" }}>&nbsp;</td>
                              <td>Sl.</td>
                              <td>Document Type</td>
                              <td>Document Name</td>
                              <td>Document Info</td>
                              <td>Ver</td>
                              <td>Uploaded By</td>
                              <td>Date &amp; Time</td>
                              <td>ISP</td>
                              <td>Aura</td>
                              <td style={{ width: "135px", paddingRight: "25px" }}>Status</td>
                              <td style={{ width: "1px" }}>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {IsResultsFound() && (
                        <>
                          <div className="viewISPTable">
                            <table>
                              <tbody>
                                {rows &&
                                  rows.map((x, index) => {
                                    return (
                                      <tr key={index}>
                                        <td style={{ width: "1px" }}>&nbsp;</td>
                                        <td>{index + 1}</td>
                                        <td>{x.doctype}</td>
                                        <td>{x.docname}</td>
                                        <td>{x.docinfo}</td>
                                        <td>{x.version}</td>
                                        <td>
                                          {Helper.GetStringJoin(x, [
                                            "first_name",
                                            "last_name",
                                          ])}
                                        </td>
                                        <td>
                                          {Helper.ToDate(
                                            x.createdt,
                                            "YYYY-MM-DD HH:mm"
                                          )}
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            onClick={(e) =>
                                              OnLinkOpen(x.blobname, "ISP")
                                            }
                                            title="PDF"
                                          >
                                            <div className="pdficon"></div>
                                          </Link>
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            onClick={(e) =>
                                              OnLinkOpen(x.blobname, "AWS")
                                            }
                                            title="PDF"
                                          >
                                            <div className="pdficon"></div>
                                          </Link>
                                        </td>
                                        <td style={{ width: "134px", paddingRight: "25px" }}>
                                          <RenderDocActiveStatus item={x} />
                                        </td>
                                        <td style={{ width: "1px" }}>&nbsp;</td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                      {!IsResultsFound() && !error && (
                        <div className="noContent">No documents available</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!IsResultsFound() && error && (
                <div className="errormessage">{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ViewPdfDoc
        stream={pdfStream}
        setViewPdf={setViewPdf}
        viewPdf={viewPdf}
        viewType={viewType}
      />
    </>
  );
};

export default DocumentViewComponent;
