import {
  React, Link, useNavigate, useSearchParams,
  Storage, Session, Helper, Loader
} from "../../cIncludes";

import ForgotPasswordComponent from "./forgotpws";

import { SignIn } from "../../services/authApi";

const LoginComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [remember, setRemember] = React.useState(false);
  const [forgotpws, setForGotPws] = React.useState(false);
  const [searchParams] = useSearchParams();
  const NavigateTo = useNavigate();

  React.useEffect(() => {
    setRow({});
    setError(null);
    setRemember(false);
    setProgress(false);
    const fn = async () => {
      await OnLogoutClicked();
      let data = await Storage.RetrieveJSONAsync("auralogin");
      if (data["value"]) {
        setRemember(data["remember"]);
        OnInputChanged("email", data["value"]);
      }
    };
    fn();
  }, []);

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnLoginClicked = async (e) => {
    e.preventDefault();
    setError(null);
    setProgress(true);
    let rslt = await SignIn(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }

    await Session.StoreJSON("user", rslt.data);
    await Session.StoreJSON("status", rslt.data.status);
    await Session.StoreJSON("modules", rslt.modules);
    await Session.StoreJSON("options", rslt.options);
    await Session.StoreJSON("roles", rslt.roles);
    await Session.Store("userToken", rslt.token);
    if (remember) {
      let data = {
        remember: true,
        value: row.email,
      };
      await Storage.StoreJSON("auralogin", data);
    }

    let redirect = searchParams.get('q') || undefined;
    if (redirect) {
      NavigateTo(redirect);
    } else {
      NavigateTo(`/${rslt.defaroute}`);
    }
    /* if (parseInt(_data.role) === 1) {
      NavigateTo("/ap");
    } else {
      NavigateTo("/up");
    } */
  };

  const OnDummyChange = (e) => { };

  const OnForgotPasswordClicked = (e) => {
    setForGotPws(true);
  };

  const OnLogoutClicked = async () => {
    await Session.Remove("user");
    await Session.Remove("modules");
    await Session.Remove("roles");
    await Session.Remove("userToken");
    await Session.Remove("prev");
    await Session.Remove("CurrentPage");
    await Session.Remove("RowsCount");
    await Session.Remove("options");
  };

  return (
    <>
      <Loader show={progress} />
      <div id="login">
        <div className="loginbox">
          <div className="loginLogo">
            <div className="i3Logo_white logoSetting"></div>
          </div>
          <form onSubmit={(e) => OnLoginClicked(e)}>
            <div className="loginForm">
              <div className="bgBlock">
                <div className="emailicon"></div>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={Helper.ToString(row.email)}
                  onChange={(e) => OnInputChanged("email", e.target.value)}
                />
              </div>
              <div className="bgBlock">
                <div className="pswdicon"></div>
                <input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={Helper.ToString(row.password)}
                  onChange={(e) => OnInputChanged("password", e.target.value)}
                />
              </div>
              <div className="rememberme">
                <input
                  type="checkbox"
                  id="remember"
                  name="remember"
                  checked={remember}
                  onChange={(e) => OnDummyChange(e)}
                  onClick={(e) => setRemember(e.target.checked)}
                />{" "}
                <label htmlFor="remember">Remember Me</label>
              </div>
              <div className="fgwt_pswd">
                <Link to="#" onClick={(e) => OnForgotPasswordClicked(e)}>
                  Forgot Password
                </Link>
              </div>
              <div className="buttonLabel">
                <input
                  type="submit"
                  className="button"
                  value="LOGIN"
                /* onClick={(e) => OnLoginClicked(e)} */
                />
              </div>
              {error && <div className="submissionError">{error}</div>}
            </div>
          </form>
        </div>
        <ForgotPasswordComponent
          forgotpws={forgotpws}
          setForGotPws={setForGotPws}
        />
        <div className="poweredby">
          Powered by:
          <Link
            to="#"
            onClick={() => Helper.OpenExternalLink("https://www.uttarana.com")}
          >
            Uttarana Technologies
          </Link>
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
