import { React, Link, Header, Footer, Loader, Helper } from "../../../cIncludes";
import { GetDocTypes, GetDocParties, SetDocParties } from "../../../services/adminApi";

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const [entities, setEntities] = React.useState({ childs: [] });
    const [doctypes, setDocTypes] = React.useState([]);
    const [selectedDocTypeId, setSelectedDocTypeId] = React.useState(0);
    const [selectedAvlDocTypeId, setSelectedAvlDocTypeId] = React.useState(0);
    const [displayName, setDisplayName] = React.useState("");
    const [error, setError] = React.useState(null);


    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const FetchResults = async () => {
        setProgress(true);
        setError(null);
        setSelectedDocTypeId(0);
        setSelectedAvlDocTypeId(0);
        let rslt = await GetDocTypes();
        if (rslt.status === 100) {
            setDocTypes(rslt.data);
        }
        setProgress(false);
    };

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        if (selectedDocTypeId > 0) {
            let tmp = doctypes.filter(
                (x) => parseInt(x.id) === parseInt(selectedDocTypeId)
            );
            setDisplayName(tmp[0].name);
            const fn = async () => {
                setProgress(true);
                let rslt = await GetDocParties(selectedDocTypeId);
                setProgress(false);
                if (rslt.status === 100) {
                    let tmp = { childs: rslt.data };
                    setEntities(tmp);
                }
            };

            fn();
        }
    }, [selectedDocTypeId, doctypes]);

    const UpdateEntities = (name, value) => {
        setEntities((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    const MoveToParty = (partyId) => {
        let tmp = entities.childs;
        let index = tmp.findIndex((x) => parseInt(x.entityid) === parseInt(selectedAvlDocTypeId));
        if (index > -1) {
            tmp[index]['doctypeid'] = selectedDocTypeId;
            tmp[index]['party'] = partyId;
            tmp[index]['statusid'] = 1;
            UpdateEntities('childs', tmp);
        }
        setSelectedAvlDocTypeId(0);
    }

    const FilterRows = (x, type) => {
        return parseInt(x.party) === parseInt(type);;
    }

    const OnUpdateClicked = async (e) => {
        e.preventDefault();
        setError(null);
        setProgress(true);
        let rslt = await SetDocParties(entities.childs);
        setProgress(false);
        if (rslt.status !== 100) {
            setError(rslt.statusText);
        } else {
            setInitlized(true);
        }

    }

    const OnResetClicked = (e) => {
        e.preventDefault();
        setInitlized(true);
    }

    return (
        <>
            <Loader show={progress} />
            <Header hname="as" />
            <div id="container">
                <div id="content">
                    <div className="widthfix_10px">
                        <div id="pgstructure">
                            <ul className="col_15by85">
                                <li className="colLeft250">
                                    <div className="blk_container">
                                        <ul className="accLeftNavi">
                                            <li className="accLeftNavi">
                                                <Link to="/asm">
                                                    <div className="leftNavi_configIcon"></div>Module
                                                    Configuration
                                                </Link>
                                            </li>
                                            <li className="accLeftNavi">
                                                <Link to="#" className="current">
                                                    <div className="leftNavi_userIcon"></div>Document Mapping
                                                </Link>
                                            </li>
                                            <li className="accLeftNavi">
                                                <Link to="/ade">
                                                    <div className="leftNavi_userIcon"></div>Document Entity Mapping
                                                </Link>
                                            </li>
                                            <li className="accLeftNavi">
                                                <Link to="/asr">
                                                    <div className="leftNavi_userIcon"></div>Role Mapping
                                                </Link>
                                            </li>
                                            <li className="accLeftNavi">
                                                <Link to="/asa">
                                                    <div className="leftNavi_settingIcon"></div>Account
                                                    Settings
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className="col_100pc-250">
                                    <div className="blk_container">
                                        <div className="head_blk">
                                            <div className="blkName">Document Type and Persona Mapping</div>
                                        </div>
                                        <div className="contentarea">
                                            <div className="config">
                                                <div className="selectModuleBlk">
                                                    <ul className="formColumn">
                                                        <li className="formColumn_III">
                                                            <div className="entityFormicon"></div>
                                                            <select
                                                                name="filterType"
                                                                required
                                                                value={Helper.ToInteger(selectedDocTypeId, 0)}
                                                                onChange={(e) =>
                                                                    setSelectedDocTypeId(e.target.value)
                                                                }
                                                            >
                                                                {doctypes &&
                                                                    doctypes.map((x, index) => {
                                                                        return (
                                                                            <option value={x.id} key={index}>
                                                                                {x.name}
                                                                            </option>
                                                                        );
                                                                    })}
                                                            </select>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {selectedDocTypeId > 0 && (
                                                    <>
                                                        <div className="configHead">{displayName}</div>
                                                        <ul className="formColumn">
                                                            <li className="formColumn_III">
                                                                <div className="configHead">Available Personas</div>
                                                                <ul className="configStructure">
                                                                    <li className="formColumn_III" style={{ width: "100%" }}>
                                                                        <select name="filterType" className="selectlist" size={50}
                                                                            onChange={(e) =>
                                                                                setSelectedAvlDocTypeId(e.target.value)
                                                                            }>
                                                                            {entities && entities.childs &&
                                                                                entities.childs.filter((z) => FilterRows(z, 0)).map((x, index) => {
                                                                                    return (
                                                                                        <option className="selectoption" value={x.entityid} key={index}>
                                                                                            {x.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                        </select>
                                                                        <div className="form_btn_label" style={{ borderTop: 0 }}>
                                                                            <input
                                                                                type="button"
                                                                                value="To FP"
                                                                                className="form_btn"
                                                                                style={{ height: 22, fontSize: "0.75em" }}
                                                                                onClick={(e) => MoveToParty(1)}
                                                                            />
                                                                            <input
                                                                                type="button"
                                                                                value="To SP"
                                                                                className="form_btn"
                                                                                style={{ height: 22, fontSize: "0.75em" }}
                                                                                onClick={(e) => MoveToParty(2)}
                                                                            />
                                                                            <input
                                                                                type="button"
                                                                                value="To WT"
                                                                                className="form_btn"
                                                                                style={{ height: 22, fontSize: "0.75em" }}
                                                                                onClick={(e) => MoveToParty(3)}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li className="formColumn_III">
                                                                <div className="configHead">First Party</div>
                                                                <ul className="configStructure">
                                                                    <li className="formColumn_III" style={{ width: "100%" }}>
                                                                        <select name="filterType" className="selectlist" size={50}
                                                                            onChange={(e) =>
                                                                                setSelectedAvlDocTypeId(e.target.value)
                                                                            }>
                                                                            {entities && entities.childs &&
                                                                                entities.childs.filter((z) => FilterRows(z, 1)).map((x, index) => {
                                                                                    return (
                                                                                        <option className="selectoption" value={x.entityid} key={index}>
                                                                                            {x.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                        </select>
                                                                        <div className="form_btn_label" style={{ borderTop: 0 }}>
                                                                            <input
                                                                                type="button"
                                                                                value="Remove"
                                                                                style={{ height: 22, fontSize: "0.75em" }}
                                                                                className="form_btn"
                                                                                onClick={(e) => MoveToParty(0)}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li className="formColumn_III">
                                                                <div className="configHead">Second Party</div>
                                                                <ul className="configStructure">
                                                                    <li className="formColumn_III" style={{ width: "100%" }}>
                                                                        <select name="filterType" className="selectlist" size={50}
                                                                            onChange={(e) =>
                                                                                setSelectedAvlDocTypeId(e.target.value)
                                                                            }>
                                                                            {entities && entities.childs &&
                                                                                entities.childs.filter((z) => FilterRows(z, 2)).map((x, index) => {
                                                                                    return (
                                                                                        <option className="selectoption" value={x.entityid} key={index}>
                                                                                            {x.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                        </select>
                                                                        <div className="form_btn_label" style={{ borderTop: 0 }}>
                                                                            <input
                                                                                type="button"
                                                                                value="Remove"
                                                                                className="form_btn"
                                                                                style={{ height: 22, fontSize: "0.75em" }}
                                                                                onClick={(e) => MoveToParty(0)}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li className="formColumn_III">
                                                                <div className="configHead">Witnesses</div>
                                                                <ul className="configStructure">
                                                                    <li className="formColumn_III" style={{ width: "100%" }}>
                                                                        <select name="filterType" className="selectlist" size={50}
                                                                            onChange={(e) =>
                                                                                setSelectedAvlDocTypeId(e.target.value)
                                                                            }>
                                                                            {entities && entities.childs &&
                                                                                entities.childs.filter((z) => FilterRows(z, 3)).map((x, index) => {
                                                                                    return (
                                                                                        <option className="selectoption" value={x.entityid} key={index}>
                                                                                            {x.name}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                        </select>
                                                                        <div className="form_btn_label" style={{ borderTop: 0 }}>
                                                                            <input
                                                                                type="button"
                                                                                value="Remove"
                                                                                className="form_btn"
                                                                                style={{ height: 22, fontSize: "0.75em" }}
                                                                                onClick={(e) => MoveToParty(0)}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li className="formColumn_III">&nbsp;</li>
                                                        </ul>
                                                    </>
                                                )}
                                            </div>

                                            <div className="form_btn_label">
                                                <input
                                                    type="button"
                                                    value="Update"
                                                    className="form_btn"
                                                    onClick={(e) => OnUpdateClicked(e)}
                                                />
                                                <input
                                                    type="button"
                                                    value="Reset"
                                                    className="form_btn"
                                                    onClick={(e) => OnResetClicked(e)}
                                                />
                                            </div>
                                            {error && (
                                                <div className="errormessage">{error}</div>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Component;
