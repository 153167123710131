var functions = {};

functions.Store = async (key, value) => {
  return new Promise(async (resolve) => {
    localStorage.setItem(key, value);
    return resolve(true);
  });
};

functions.StoreJSON = async (key, value) => {
  return new Promise(async (resolve) => {
    localStorage.setItem(key, JSON.stringify(value));
    return resolve(true);
  });
};

functions.Retrieve = async (key) => {
  return new Promise(async (resolve) => {
    return resolve(localStorage.getItem(key));
  });
};

functions.RetrieveJSONAsync = async (key) => {
  return new Promise(async (resolve) => {
    let data = localStorage.getItem(key);
    if (data === undefined || data === null) return resolve({});
    return resolve(JSON.parse(data));
  });
};

functions.RetrieveJSON = (key) => {
  let data = localStorage.getItem(key);
  if (data === undefined || data === null) return {};
  return JSON.parse(data);
};

functions.Remove = async (key) => {
  return new Promise(async (resolve) => {
    return resolve(localStorage.removeItem(key));
  });
};

export default functions;
