import API from "./api";
import session from "../session";

const Add = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`entities`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Get = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`entities`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetShort = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`shortentities`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetShortInfoExclude = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`eshortentities`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetShortByLoan = async (loanid) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`shortentities/${loanid}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetISPLinks = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getispinks`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export { Add, Get, GetShort, GetISPLinks, GetShortByLoan, GetShortInfoExclude };
