import { React, Link, Session, Loader, Helper, Header, Footer, Pagination } from "../../../cIncludes";
import {
  Get as GetStakeHolders,
  Add as SetStakeHolders,
  Delete as DeleteStakeHolders,
} from "../../../services/stakeholdersApi";
import { GetShort as GetShortEntities } from "../../../services/entitiesApi";
import { GetByEntity } from "../../../services/usersApi";
import RenderLoanSummary from "../common/RenderLoanSummary";

const StakeHoldersComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [listItems, setListItems] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [entities, setEntities] = React.useState([]);
  const [personas, setPersonas] = React.useState([]);
  const [loanNumber, setLoanNumber] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  const [selectedloanid, setSelectedLoanId] = React.useState(0);
  const [loadStakeHolders, setLoadStakeHolders] = React.useState(false);
  const [entityId, setEntityId] = React.useState(0);
  const [doSearchPersonas, setDoSearchPersonas] = React.useState(false);

  const PageSize = 10;

  const FetchResults = async () => {
    setProgress(true);
    Session.Store("CurrentPage", 1);
    Session.Store("RowsCount", 0);
    let rslt = await GetShortEntities();
    if (rslt.status === 100) {
      setEntities(rslt.data);
    }

    /*rslt = await GetPersonas();
    if (rslt.status === 100) {
      console.log(rslt.data);
      setPersonasList(rslt.data);
    }*/

    setPersonas([{ id: "0", first_name: "Select" }]);

    setProgress(false);
  };

  const GetPersonas = async () => {
    setProgress(true);
    let rslt = await GetByEntity(entityId);
    if (rslt.status === 100) {
      let tmp = rslt.data;
      tmp.splice(0, 0, { id: "0", first_name: "Select" });
      setPersonas(tmp);
    }

    setProgress(false);
  };

  if (doSearchPersonas) {
    setDoSearchPersonas(false);
    GetPersonas();
  }

  if (initlized) {
    setInitlized(false);
    setDoSearchPersonas(false);
    FetchResults();
  }

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
    if (name === "entityid") {
      setEntityId(val);
    }
  };

  const OnLoadStakeHolders = async (loanId, loanNumber) => {
    setListItems([]);
    Session.Store("RowsCount", 0);
    setRows([]);
    OnInputChanged("loanid", loanId);
    setLoanNumber(loanNumber);
    setSelectedLoanId(loanId);
    /* let rslt = await GetStakeHolders(loanId);
    if (rslt.status === 100) {
      setListItems(rslt.data);
      Session.Store("RowsCount", rslt.data.length);
      setRows(rslt.data.slice(0, PageSize));
      setRefresh(!refresh);
    } */
  };

  const OnUpdateStakeHolders = async (e) => {
    e.preventDefault();
    setProgress(true);
    let rslt = await SetStakeHolders(row);
    setProgress(false);
    if (rslt.status !== 100) {
      console.log(rslt.statusText);
    } else {
      let loanId = row.loanid;
      setRow({});
      setPersonas([{ id: "0", first_name: "Select" }]);
      OnLoadStakeHolders(loanId);
      setLoadStakeHolders(true);
      setEntityId(0);
    }
  };

  const OnDeletetakeHolders = async (e) => {
    setProgress(true);
    let rslt = await DeleteStakeHolders(e);
    setProgress(false);
    if (rslt.status !== 100) {
      console.log(rslt.statusText);
    } else {
      ReadStakeHolders();
    }
  };

  const OnPageChanged = (start, end) => {
    let tmp = listItems.slice(start, end);
    setRows(tmp);
  };

  const ReadStakeHolders = async () => {
    setProgress(true);
    let rslt = await GetStakeHolders(selectedloanid);
    if (rslt.status === 100) {
      setListItems(rslt.data);
      Session.Store("RowsCount", rslt.data.length);
      setRows(rslt.data.slice(0, PageSize));
      setTimeout(() => {
        setProgress(false);
        setRefresh(!refresh);
      }, 500);
    }
  };

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  React.useEffect(() => {
    if (parseInt(selectedloanid) > 0) {
      setLoadStakeHolders(true);
    }
  }, [selectedloanid]);

  React.useEffect(() => {
    setDoSearchPersonas(true);
  }, [entityId]);

  if (loadStakeHolders) {
    setLoadStakeHolders(false);
    ReadStakeHolders();
  }

  return (
    <>
      <Loader show={progress} />
      <Header hname="ul" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="breadcrumb">
                <li className="breadcrumb_home"></li>
                <li className="breadcrumb">Loans</li>
                <li className="breadcrumb">Assign Stakeholders</li>
              </ul>
              <RenderLoanSummary
                onLoadStakeHolders={OnLoadStakeHolders}
                stakesonly={true}
              />
              {Helper.ToInteger(selectedloanid) > 0 && (
                <>
                  <div className="col_1">
                    <div className="blk_container">
                      <div className="head_blk">
                        <div className="blkName">
                          Add Stakeholders for Loan No: {loanNumber}
                        </div>
                      </div>
                      <div className="contentarea_bgwhite">
                        <ul className="formColumn">
                          <li className="formColumn_III">
                            <div className="formContentHead">Select Entity</div>
                            <div className="formMandatory">*</div>
                            <div className="entityFormicon"></div>
                            <select
                              name="filterStatus"
                              required
                              value={Helper.ToString(row.entityid)}
                              onChange={(e) =>
                                OnInputChanged("entityid", e.target.value)
                              }
                            >
                              {entities &&
                                entities.map((x, index) => {
                                  return (
                                    <option value={x.id} key={index}>
                                      {x.display_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </li>
                          <li className="formColumn_III">
                            <div className="formContentHead">
                              Select User
                            </div>
                            <div className="formMandatory">*</div>
                            <div className="personaFormicon"></div>
                            <select
                              name="filterStatus"
                              required
                              value={Helper.ToString(row.userid)}
                              onChange={(e) =>
                                OnInputChanged("userid", e.target.value)
                              }
                            >
                              {personas &&
                                personas.map((x, index) => {
                                  return (
                                    <option value={x.id} key={index}>
                                      {Helper.GetStringJoin(x, [
                                        "first_name",
                                        "last_name",
                                      ])}
                                    </option>
                                  );
                                })}
                            </select>
                          </li>
                          <li className="formColumn_III">
                            <div className="form_btn_label_notop_8">
                              <input
                                type="button"
                                value="Add Stakeholder"
                                className="form_btn"
                                disabled={
                                  Helper.IsNullValue(row.loanid)
                                    ? "disabled"
                                    : undefined
                                }
                                onClick={(e) => OnUpdateStakeHolders(e)}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col_1">
                    <div className="blk_container">
                      <div className="head_blk">
                        <div className="blkName">Stakeholders</div>

                        {rows && rows.length > 0 && (
                          <Pagination
                            refresh={refresh}
                            pages={PageSize}
                            onPageChanged={OnPageChanged}
                          />
                        )}
                      </div>
                      <div className="contentarea_table">
                        {rows && rows.length > 0 ? (
                          <>
                            <div className="stakeholderTable_head">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <Link to="#">Entity</Link>
                                    </td>
                                    <td>
                                      <Link to="#">User</Link>
                                    </td>
                                    <td>&nbsp;</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="stakeholderTable">
                              <table>
                                <tbody>
                                  {rows.map((x, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{x.display_name}</td>
                                        <td>
                                          {Helper.GetStringJoin(x, [
                                            "first_name",
                                            "last_name",
                                          ])}
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            title="Delete"
                                            onClick={(e) =>
                                              OnDeletetakeHolders(x.id)
                                            }
                                          >
                                            <div className="deleteStakeholdericon"></div>
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <div className="noContent">
                            No stakeholders available
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default StakeHoldersComponent;
