const FooterComponent = () => {
  return (
    <>
      <div id="footer">
        <div className="footer_blk">
          <div className="widthfix">
            <div className="copyright">
              Copyright &copy; {new Date().getFullYear()}&nbsp; i3 Auras
              &nbsp;&nbsp;&#124; &nbsp; Powered by Uttarana Technologies
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterComponent;
