import { Link } from "react-router-dom";

const ChildHeadersComponent = ({ mnu }) => {
  return (
    <>
      <li className="col_15by85">
        <div className="blk_container">
          <ul className="accLeftNavi">
            <li className="accLeftNavi">
              <Link
                to={mnu === "uss" ? "#" : "/uss"}
                className={mnu === "uss" ? "current" : undefined}
              >
                <div className="leftNavi_userIcon"></div>Manage Sub Users
              </Link>
            </li>
            <li className="accLeftNavi">
              <Link
                to={mnu === "uso" ? "#" : "/uso"}
                className={mnu === "uso" ? "current" : undefined}
              >
                <div className="leftNavi_mappingIcon"></div>Borrower Mapping
              </Link>
            </li>
            <li className="accLeftNavi">
              <Link
                to={mnu === "usm" ? "#" : "/usm"}
                className={mnu === "usm" ? "current" : undefined}
              >
                <div className="leftNavi_mappingIcon"></div>Loan Mapping
              </Link>
            </li>
            <li className="accLeftNavi">
              <Link
                to={mnu === "usa" ? "#" : "/usa"}
                className={mnu === "usa" ? "current" : undefined}
              >
                <div className="leftNavi_settingIcon"></div>Account Settings
              </Link>
            </li>
          </ul>
        </div>
      </li>
    </>
  );
};

export default ChildHeadersComponent;
