import { React, Loader, Helper } from "../../../cIncludes";
import { Set as SetProfile } from "../../../services/usersApi";

const RenderProfDetailsComponent = ({ actprofile, setActProfile }) => {
  const [progress, setProgress] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);

  const OnInputChanged = (name, val) => {
    setActProfile((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnProfileUpdate = async (e) => {
    setError(null);
    setErrors([]);
    setProgress(true);
    let rslt = await SetProfile(actprofile);
    setProgress(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
    } else {
      setError(rslt.statusText);
    }
  };

  const OnBlurChanged = (name, val) => {
    val = Helper.ToUSAPhone(val);
    if (val === null) return;
    OnInputChanged(name, val);
  };

  return (
    <>
      <Loader show={progress} />
      <div className="blk_container">
        <div className="head_blk">
          <div className="blkName">Profile Details</div>
        </div>
        <div className="contentarea">
          <ul className="formColumn">
            <li className="formColumn_III">
              <div className="formContentHead">First Name</div>
              <div className="formMandatory">*</div>
              <div className="userFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(actprofile.first_name)}
                style={{
                  borderColor:
                    errors && errors.indexOf("first_name") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("first_name", e.target.value)}
              />
            </li>
            <li className="formColumn_III">
              <div className="formContentHead">Last Name</div>
              <div className="formMandatory">*</div>
              <div className="userFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(actprofile.last_name)}
                style={{
                  borderColor:
                    errors && errors.indexOf("last_name") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("last_name", e.target.value)}
              />
            </li>
            <li className="formColumn_III">
              <div className="formContentHead">Work Phone</div>
              <div className="landphFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(actprofile.work_phone)}
                style={{
                  borderColor:
                    errors && errors.indexOf("work_phone") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("work_phone", e.target.value)}
                onKeyPress={(e) => {
                  return Helper.AcceptedChars(e, "PHONE");
                }}
                onBlur={(e) => OnBlurChanged("work_phone", e.target.value)}
              />
            </li>
            <li className="formColumn_III">
              <div className="formContentHead">Cell Phone</div>
              <div className="formMandatory">*</div>
              <div className="cellphFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(actprofile.mobile_number)}
                style={{
                  borderColor:
                    errors && errors.indexOf("mobile_number") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) =>
                  OnInputChanged("mobile_number", e.target.value)
                }
                onBlur={(e) => OnBlurChanged("mobile_number", e.target.value)}
                onKeyPress={(e) => {
                  return Helper.AcceptedChars(e, "PHONE");
                }}
              />
            </li>
            <li className="formColumn_III">
              <div className="formContentHead">Email</div>
              <div className="formMandatory">*</div>
              <div className="mailFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(actprofile.email)}
                readOnly
                style={{
                  borderColor:
                    errors && errors.indexOf("email") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("email", e.target.value)}
              />
            </li>
          </ul>
          <div className="form_btn_label">
            <input
              type="button"
              value="Submit"
              className="form_btn"
              onClick={(e) => OnProfileUpdate(e)}
            />
          </div>
          {error && <div className="errormessage">{error}</div>}
        </div>
      </div>
    </>
  );
};

export default RenderProfDetailsComponent;
