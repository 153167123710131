import { React, Loader, Helper } from "../../../cIncludes";
import { GetShortInfoExclude } from "../../../services/entitiesApi";
import { Add as AddUsers } from "../../../services/usersApi";

const RenderAddNewPersonaComponent = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [entities, setEntities] = React.useState([]);

  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);

    let rslt = await GetShortInfoExclude();
    if (rslt.status === 100) {
      setEntities(rslt.data);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnPersonaClicked = async (e) => {
    e.preventDefault();
    setError(null);
    setErrors([]);
    setProgress(true);
    let rslt = await AddUsers(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
    } else {
      setRow({});
      window.location.reload();
    }
  };

  const OnBlurChanged = (name, val) => {
    val = Helper.ToUSAPhone(val);
    if (val === null) return;
    OnInputChanged(name, val);
  };

  return (
    <>
      <Loader show={progress} />
      <div className="blk_container">
        <div className="head_blk">
          <div className="blkName">Create User</div>
        </div>
        <div className="contentarea">
          <ul className="formColumn">
            <li className="formColumn_I">
              <div className="formContentHead">Select Entity</div>
              <div className="formMandatory">*</div>
              <div className="entityFormicon"></div>
              <select
                name="filterType"
                required
                value={Helper.ToInteger(row.entityid, 0)}
                style={{
                  borderColor:
                    errors && errors.indexOf("entityid") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("entityid", e.target.value)}
              >
                {entities &&
                  entities.map((x, index) => {
                    return (
                      <option value={x.id} key={index}>
                        {x.display_name}
                      </option>
                    );
                  })}
              </select>
            </li>
            <li className="formColumn_I">
              <div className="formContentHead">First Name / Company Name</div>
              <div className="formMandatory">*</div>
              <div className="userFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(row.first_name)}
                style={{
                  borderColor:
                    errors && errors.indexOf("first_name") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("first_name", e.target.value)}
              />
            </li>
            <li className="formColumn_I">
              <div className="formContentHead">Last Name</div>
              <div className="formMandatory">*</div>
              <div className="userFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(row.last_name)}
                style={{
                  borderColor:
                    errors && errors.indexOf("last_name") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("last_name", e.target.value)}
              />
            </li>
            <li className="formColumn_I">
              <div className="formContentHead">Email Id</div>
              <div className="formMandatory">*</div>
              <div className="mailFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(row.email)}
                style={{
                  borderColor:
                    errors && errors.indexOf("email") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("email", e.target.value)}
              />
            </li>
            <li className="formColumn_I">
              <div className="formContentHead">Mobile Number</div>
              <div className="formMandatory">*</div>
              <div className="cellphFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(row.mobile_number)}
                style={{
                  borderColor:
                    errors && errors.indexOf("mobile_number") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) =>
                  OnInputChanged("mobile_number", e.target.value)
                }
                onKeyPress={(e) => {
                  return Helper.AcceptedChars(e, "PHONE");
                }}
                onBlur={(e) => OnBlurChanged("mobile_number", e.target.value)}
              />
            </li>
          </ul>
          <div className="form_btn_label_notop">
            <input
              type="button"
              value="Create Persona"
              className="form_btn"
              onClick={(e) => OnPersonaClicked(e)}
            />
          </div>
          {error && <div className="errormessage">{error}</div>}
        </div>
      </div>
    </>
  );
};

export default RenderAddNewPersonaComponent;
