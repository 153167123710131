import React from "react";
import { Link } from "react-router-dom";
import helper from "../helper";
import Session from "../session";

const PaginationComponent = (props) => {
  const { refresh, pages, onPageChanged } = props;

  const [rowsCount, setRowsCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);

  React.useEffect(() => {
    const fn = async () => {
      let totCount = await Session.Retrieve("RowsCount");
      let current = await Session.Retrieve("CurrentPage");

      // Total Number of Rows
      setRowsCount(totCount);

      // Set Page Size
      setPageSize(pages);

      // Calcuate Number of Pages
      setPageCount(Math.ceil(rowsCount / pageSize));

      // Current Page
      setCurrentPage(current);
    };
    fn();
  }, [refresh, pages, pageSize, rowsCount, pageCount, currentPage]);

  const OnInputChanged = (e) => {
    if (e > pageCount) e = pageCount;
    Session.Store("CurrentPage", e);
    setCurrentPage(e);
  };

  const OnBlurChanged = (e) => {
    let start = pageSize * e - pageSize;
    let end = pageSize * e;
    if (onPageChanged) onPageChanged(start, end);
  };

  const MoveNext = (e) => {
    e.preventDefault();
    let cnt = currentPage;
    if (currentPage < pageCount) {
      cnt++;
      Session.Store("CurrentPage", cnt);
      setCurrentPage(cnt);
      let start = pageSize * cnt - pageSize;
      let end = pageSize * cnt;
      if (onPageChanged) onPageChanged(start, end);
    }
  };

  const MovePrev = (e) => {
    e.preventDefault();
    let cnt = currentPage;
    if (currentPage > 1) {
      cnt--;
      Session.Store("CurrentPage", cnt);
      setCurrentPage(cnt);
      let start = pageSize * cnt - pageSize;
      let end = pageSize * cnt;
      if (onPageChanged) onPageChanged(start, end);
    }
  };

  return (
    <div className="pagination">
      <Link to="#" onClick={(e) => MovePrev(e)}>
        <div className="leftArrow"></div>
      </Link>
      Page{" "}
      <input
        type="text"
        onKeyPress={(e) => {
          return helper.AcceptedChars(e, "NFINANCE");
        }}
        value={helper.ToString(currentPage)}
        onChange={(e) => OnInputChanged(e.target.value)}
        onBlur={(e) => OnBlurChanged(e.target.value)}
      />
      of {pageCount}
      <Link to="#" onClick={(e) => MoveNext(e)}>
        <div className="rightArrow"></div>
      </Link>
    </div>
  );
};

export default PaginationComponent;
