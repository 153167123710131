var fn = {};
fn.Store = async (key, value) => {
  sessionStorage.setItem(key, value);
};

fn.StoreAsync = async (key, value) => {
  return new Promise(async (resolve) => {
    sessionStorage.setItem(key, value);
    return resolve(true);
  });
};

fn.StoreJSON = async (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

fn.StoreJSONAsync = async (key, value) => {
  return new Promise(async (resolve) => {
    sessionStorage.setItem(key, JSON.stringify(value));
    return resolve(true);
  });
};

fn.RetrieveJSON = async (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

fn.RetrieveJSON2 = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

fn.RetrieveJSONAsync = async (key) => {
  return new Promise(async (resolve) => {
    return resolve(JSON.parse(sessionStorage.getItem(key)));
  });
};

fn.Retrieve = async (key) => {
  return sessionStorage.getItem(key);
};

fn.Retrieve1 = (key) => {
  return sessionStorage.getItem(key);
};

fn.RetrieveAsync = async (key) => {
  return new Promise(async (resolve) => {
    return resolve(sessionStorage.getItem(key));
  });
};

fn.Remove = async (key) => {
  return sessionStorage.removeItem(key);
};

fn.RemoveAsync = async (key) => {
  return new Promise(async (resolve) => {
    return resolve(sessionStorage.removeItem(key));
  });
};

/* fn.IsLoggedIn = (key) => {
  const data = sessionStorage.getItem(key);
  return !(data === null || data === undefined || data.length === 0);
}; */

fn.IsLoggedIn = () => {
  const data = sessionStorage.getItem("userToken");
  return !(data === null || data === undefined || data.length === 0);
};

fn.IsMenuFound = (tmnu) => {
  if (tmnu === null || tmnu === undefined || tmnu === "") return false;
  const data = JSON.parse(sessionStorage.getItem("modules"));
  let mnu = tmnu.split("/")[1];
  let tmpArray = data;
  let tmp = data.map((x) => x.childs).filter((x) => x !== undefined);
  if (tmp.length > 0) {
    for (let i = 0; i < tmp.length; i++) {
      let _data = tmpArray;
      tmpArray = _data.concat(tmp[i]);
    }
  }

  let mnus = tmpArray.filter(
    (x) => x.link === mnu || (x.childs && x.childs.link === mnu)
  );
  if (mnus.length > 0) {
    fn.Store("prev", mnu);
  }

  return mnus.length > 0;
};

fn.GetHeader = async () => {
  const token = await fn.Retrieve("userToken");
  return {
    "Content-Type": "application/json",
    Authorization: `bearer ${token}`,
  };
};

fn.ToQueryString = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

const OnLogoutClicked = async () => {
  await fn.Remove("user");
  await fn.Remove("modules");
  await fn.Remove("roles");
  await fn.Remove("userToken");
  await fn.Remove("prev");
  await fn.Remove("CurrentPage");
  await fn.Remove("RowsCount");
  await fn.Remove("options");
};

fn.IsSessionExpired = async (input) => {
  if (input.status === 500 || input.status === 600 || input.status === 800) {
    await OnLogoutClicked();
    return window.location.reload();
  }
  return input;
};

export default fn;
