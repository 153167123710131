import { React, Loader, Helper } from "../../../../cIncludes";
import UploadHelper from "../../../../helper/upload";

const RenderUploadDocument = ({
  typeid,
  typename,
  loanId,
  loanNumber,
  setReaload,
  borrowers,
  lenderId,
  reaload,
  fields,
  disableUpload,
}) => {
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [fileurl, setFileUrl] = React.useState(null);
  const [progress, setProgress] = React.useState(false);
  const [fsupp, setFSupp] = React.useState(null);
  const [noUpload, setNoUpload] = React.useState(false);

  const maxsize = 106000000;
  const formats = ["PNG", "JPG", "JPEG", "PDF"];

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnUploadDocument = async (e) => {
    e.preventDefault();
    setError(null);
    if (Helper.IsNullValue(row.docname)) {
      setError("All fields are mandatory");
    } else if (Helper.IsNullValue(row.docinfo)) {
      setError("All fields are mandatory");
    } else if (Helper.IsNullValue(fileurl)) {
      setError("All fields are mandatory");
    } else if (fileurl.size > maxsize) {
      setError("File size allowed: 100mb");
    } else {
      row.type = "DOC";
      row.ofilename = fileurl.name;
      row.request['fileName'] = fileurl.name;
      setProgress(true);
      let rslt = await UploadHelper.UploadDocs(fileurl, row);
      setProgress(false);
      if (rslt.status !== 100) {
        setError(rslt.statusText);
        return;
      }

      setError(null);
      setRow({
        typeid: typeid,
        typename: typename,
        loanid: loanId,
        loanNumber: loanNumber,
        list: fields || [],
        request: {
          lender_id: lenderId,
          borrower_id: borrowers,
          loan_id: loanNumber,
          stage: typename
        }
      });
      setFileUrl(null);
      //setSError("Ok");
      Helper.NativeInputClick("loandocs");
      setReaload(!reaload);
    }
  };

  const OnFileInputChanged = (e) => {
    setFSupp(false);
    e.preventDefault();
    let _file = e.target.files[0];
    if (!Helper.IsNullValue(_file)) {
      let _ext = _file.name.split(".").pop();
      let _index = formats.findIndex((x) => x === _ext.toUpperCase());
      if (_index === -1) {
        setFSupp(true);
        Helper.NativeInputClick("loandocs");
      } else {
        setFileUrl(e.target.files[0]);
      }
    }
  };

  React.useEffect(() => {
    if (parseInt(typeid) > 0) {
      let tmp = {
        typeid: typeid,
        typename: typename,
        loanid: loanId,
        loanNumber: loanNumber,
        list: fields || [],
        request: {
          lender_id: lenderId,
          borrower_id: borrowers,
          loan_id: loanNumber,
          stage: typename
        }
      };
      setRow(tmp);
      setNoUpload(disableUpload);
    }
  }, [typeid, typename, loanId, loanNumber,
    fields, disableUpload, borrowers, lenderId]);

  return (
    <>
      <Loader show={progress} />
      <ul className="formColumn_doctype">
        <li className="formColumn_IV" style={{ marginBottom: 10 }}>
          <div className="formContentHead">Document Name</div>
          <div className="documentFormicon"></div>
          <input
            type="text"
            value={Helper.ToString(row.docname)}
            onChange={(e) => OnInputChanged("docname", e.target.value)}
          />
        </li>
        <li className="formColumn_IV" style={{ marginBottom: 10 }}>
          <div className="formContentHead">Document Information</div>
          <div className="shareholderTextarea">
            <textarea
              value={Helper.ToString(row.docinfo)}
              onChange={(e) => OnInputChanged("docinfo", e.target.value)}
            ></textarea>
          </div>
        </li>
        {row &&
          row.list &&
          row.list.map((x, index) => {
            return (
              <li
                className="formColumn_IV"
                key={index}
                style={{ marginBottom: 10 }}
              >
                <div className="formContentHead">{x.label}</div>
                <div className="documentFormicon"></div>
                <input
                  type="text"
                  value={Helper.ToString(row[x.name])}
                  onChange={(e) => OnInputChanged(x.name, e.target.value)}
                />
              </li>
            );
          })}

        <li className="formColumn_IV" style={{ marginBottom: 10 }}>
          <div className="formContentHead">Document Upload</div>
          <div className="formContentHead_right">
            {/* {serror && <div className="uploadSuccess">Upload Successful</div>} */}
            {fsupp && (
              <div className="fileFormat">
                Supported Format: PNG, JPG, JPEG and PDF
              </div>
            )}
          </div>
          <div className="fileUpload">
            <div className="uploadBox">
              <input
                id="loandocs"
                type="file"
                name="loandocs"
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={(e) => OnFileInputChanged(e)}
              />
            </div>
            {!noUpload && (
              <div className="buttonUploadLayout">
                <input
                  type="submit"
                  className="buttonUpload"
                  value="Upload"
                  onClick={(e) => OnUploadDocument(e)}
                />
              </div>
            )}
            {error && <div className="submitError">{error}</div>}
          </div>
        </li>
      </ul>
    </>
  );
};

export default RenderUploadDocument;
