import { React, Loader, Helper } from "../../../cIncludes";
import { GetProfile as GetProfiles } from "../../../services/usersApi";
import ChangePasswordComponent from "../../common/changepws";

const RenderAcctDetailsComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [changepws, setChangePws] = React.useState(false);

  const OnChangePasswordClicked = (e) => {
    setChangePws(true);
  };

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);

    let rslt = await GetProfiles();
    if (rslt.status === 100) {
      setRow(rslt.data);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  return (
    <>
      <Loader show={progress} />
      <li className="col_20by80">
        <div className="blk_container">
          <div className="head_blk">
            <div className="blkName">Account Details</div>
          </div>
          <div className="contentarea_bgwhite">
            <div className="accDetails">
              <div className="acc_name">
                {Helper.GetStringJoin(row, ["first_name", "last_name"])}
              </div>
              <div className="memberBlock">
                <div className="memberHead">Member Since:&nbsp;</div>
                <div className="memberTxt">
                  {Helper.ToDate(row.createdt, "MMM DD, YYYY")}
                </div>
              </div>
              <div className="memberBlock" style={{ display: "none" }}>
                <div className="memberHead">NMLS ID:</div>
                <div className="memberTxt">1234567890</div>
              </div>
              <div className="mailPhBlock">
                <div className="horBlk">
                  <div className="mailIcon"></div>
                  <div className="mailPh_txt">{row.email}</div>
                </div>
                <div className="horBlk">
                  <div className="phIcon"></div>
                  <div className="mailPh_txt">{row.mobile_number}</div>
                </div>
              </div>
              <div className="acc_btn_label">
                <input
                  type="button"
                  className="acc_btn"
                  value="Change Password"
                  onClick={(e) => OnChangePasswordClicked(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </li>
      <ChangePasswordComponent
        changepws={changepws}
        setChangePws={setChangePws}
      />
    </>
  );
};

export default RenderAcctDetailsComponent;
