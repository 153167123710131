import API from "./api";

const SignIn = async (input) => {
  try {
    const res = await API.post(`signin`, input);
    return res.data;
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const AuthUsers = async () => {
  try {
    const res = await API.get(`authusers`);
    return res.data;
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export { SignIn, AuthUsers };
