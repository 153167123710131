import { React, Link, Session, Loader, Helper, Pagination, Header, Footer } from "../../../cIncludes";
import { GetManagedLoans, Set as SetLoans, GetLoanById } from "../../../services/loanApi";
import {
  GetLenders,
  GetEthnicities,
  GetRaces,
  GetLoanTypes,
  GetLoanPurposeTypes
} from "../../../services/commonApi";
import RenderBorrowerSelect from "../common/RenderBorrowerSelect";
import RenderBorrower from "./childs/RendorBorrower";

const LoanComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [listItems, setListItems] = React.useState([]);
  const [filterlistItems, setFilterListItems] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [search, setSearch] = React.useState(null);
  const [showFilter, setShowFilter] = React.useState(false);
  const [entityname, setEntityName] = React.useState("");
  const [showBorrower, setShowBorrower] = React.useState(false);
  const [lenders, setLenders] = React.useState([]);
  const [races, setRaces] = React.useState([]);
  const [ethnicities, setEthnicities] = React.useState([]);
  const [loantypes, setLoanTypes] = React.useState([]);
  const [loanpurposes, setLoanPurposes] = React.useState([]);
  const [borrowers, setBorrowers] = React.useState({ childs: [{ id: 0, key: 1, statusid: 1, isprimary: false }] });

  const PageSize = 10;

  const FetchResults = async (nobrreset) => {
    setProgress(true);
    Session.Store("CurrentPage", 1);
    Session.Store("RowsCount", 0);
    let rslt = await GetManagedLoans();
    if (rslt.status === 100) {
      setSearch(null);
      setListItems(rslt.data);
      Session.Store("RowsCount", rslt.data.length);
      setFilterListItems(rslt.data);
      setRows(rslt.data.slice(0, PageSize));
      if (!nobrreset) {
        let newItems = [{ id: 0, key: 1, statusid: 1, isprimary: false }];
        SetBorrowerInfo('childs', newItems);
      }
      setRefresh(!refresh);
    }

    let data = await Session.RetrieveJSONAsync("user");
    setEntityName(data.issuer);
    setRow({ issuingauth: data.issuer });

    rslt = await GetLenders();
    if (rslt.status === 100) {
      setLenders(rslt.data);
    }

    rslt = await GetRaces();
    if (rslt.status === 100) {
      setRaces(rslt.data);
    }

    rslt = await GetEthnicities();
    if (rslt.status === 100) {
      setEthnicities(rslt.data);
    }

    rslt = await GetLoanTypes();
    if (rslt.status === 100) {
      setLoanTypes(rslt.data);
    }

    rslt = await GetLoanPurposeTypes();
    if (rslt.status === 100) {
      setLoanPurposes(rslt.data);
    }

    setProgress(false);
  };

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  /* React.useEffect(() => {
    const fn = async () => {
      let data = await Session.RetrieveJSONAsync("user");
      console.log(data.issuer);
      OnInputChanged('issuingauth', entityname);
      setEntityName(data.issuer);
    };
    //fn();
  }, []); */

  if (initlized) {
    setInitlized(false);
    //setRow({ issuingauth: entityname });
    setRows([]);
    FetchResults();
  }

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const ValidateBorrower = () => {
    setError(null);
    setErrors([]);
    let _loanPurpose = row?.purpose;
    if (_loanPurpose.toLowerCase() === "refinance" && Helper.IsNullValue(row.refinancedfrom)) {
      setError("Please provide Refinance From Loan ID");
      setErrors(["refinancedfrom"]);
      return false;
    }

    let tmp = row.borrowers || [];
    let list = tmp.filter((x) => parseInt(x.statusid) === 1);
    if (list.length === 0) {
      setError("Atleast one borrower should exit");
      return false;
    }

    if (list.filter((x) => x.isprimary).length > 1) {
      setError("Only one borrower should mark as primary");
      return false;
    }

    for (let i = 0; i < list.length; i++) {
      if (!tmp[i].borrower_id) {
        setError("Atleast one borrower should select");
        return false;
      }
      let cnt = list.filter((x) => x.borrower_id === tmp[i].borrower_id).length;
      if (cnt > 1) {
        setError("Duplicate borrowers are exist");
        return false;
      }
    }
    return true;
  }

  const RemoveDeletedBorrowers = () => {
    let tmp = row.borrowers || [];
    //let list = tmp.filter((x) => parseInt(x.statusid) !== 2 || parseInt(x.id) > 0);
    let list = tmp.filter((x) => parseInt(x.statusid) !== 2);
    OnInputChanged('borrowers', list);
    return list;
  }

  const OnAddLoanClicked = async (e) => {
    let list = RemoveDeletedBorrowers();
    row.borrowers = list;

    if (!ValidateBorrower()) return;
    setProgress(true);
    setError(null);
    setErrors([]);
    e.preventDefault();
    let rslt = await SetLoans(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      setErrors(rslt.errors);
    } else {
      setError(rslt.statusText);
      OnResetClicked();
      //setRow({ issuingauth: entityname });
      //OnInputChanged("lenderindex", 0);
      FetchResults(true);
    }
  };

  const OnResetClicked = async (e) => {
    setError(null);
    setErrors([]);
    setRow({ issuingauth: entityname, borrowers: [{ id: 0, statusid: 1, isprimary: false }] });
    SetBorrowerInfo('childs', [{ id: 0, key: 1, statusid: 1 }]);
  };

  const OnPageChanged = (start, end) => {
    let tmp = filterlistItems.slice(start, end);
    setRows(tmp);
  };

  const OnSearchClicked = (e) => {
    e.preventDefault();
    let _items = listItems;
    if (!Helper.IsNullValue(search)) {
      _items = listItems.filter((x) => x.loannumber === search);
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
    Session.Store("RowsCount", _items.length);
    setFilterListItems(_items);
    setRefresh(!refresh);
    setRows(_items.slice(0, PageSize));
  };

  const OnSearchCleared = (e) => {
    setShowFilter(false);
    setSearch(null);
    OnSearchClicked(e);
  };

  const OnShowProgress = (e) => {
    setProgress(e);
  };

  const OnBorrowerSelect = (rec) => {
    let _borName = Helper.GetStringJoin(rec, [
      "first_name",
      "middle_name",
      "last_name",
    ]);

    OnInputChanged("borrower", _borName);
    OnInputChanged("borrower_id", rec.ispid);
    setShowBorrower(false);
  };

  const OnLenderSelect = (index) => {
    //OnInputChanged("lenderindex", index);
    let _lender = lenders[index];
    if (_lender.id > 0) {
      OnInputChanged("lenderindexid", _lender.typeindex);
      OnInputChanged("lender_id", _lender.ispid);
    }
  };

  const OnAddBorrower = (e, name) => {
    e.preventDefault();
    let newItems = borrowers.childs;
    newItems.push({
      id: 0,
      statusid: 1,
      isprimary: false,
      key: newItems.length + 1
    });
    SetBorrowerInfo(name, newItems);
  }

  const OnUpdateBorrower = (key, name, value) => {
    let newItems = borrowers.childs;
    let index = newItems.findIndex((x) => parseInt(x.key) === parseInt(key));
    if (index > -1) {
      newItems[index][name] = value;
      SetBorrowerInfo('childs', newItems);
      OnInputChanged("borrowers", newItems);
    }
  }

  const SetBorrowerInfo = (name, values) => {
    setBorrowers((prev) => ({
      ...prev,
      [name]: values,
    }));
  }

  const OnSelectLoanForEdit = async (e) => {
    setProgress(true);
    setError(null);
    let rslt = await GetLoanById(e);
    if (rslt.status === 100) {
      let tmp = rslt.data;
      let newItems = tmp.borrowers || [{ id: 0, key: 1, statusid: 1, isprimary: false }];
      OnInputChanged('borrowers', newItems);
      //delete tmp['borrowers'];
      SetBorrowerInfo('childs', newItems);
      setRow(tmp);
    }
    setProgress(false);
  }

  return (
    <>
      <Loader show={progress} />
      <Header hname="ul" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <div className="col_1">
                <div className="blk_container">
                  <div className="head_blk">
                    <div className="blkName">Loans List</div>
                    {rows && rows.length > 0 && (
                      <Pagination
                        refresh={refresh}
                        pages={PageSize}
                        onPageChanged={OnPageChanged}
                      />
                    )}
                    <div className="head_right">
                      <div className="textSearch">
                        <input
                          type="text"
                          value={Helper.ToString(search)}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search by Loan Number"
                        />
                      </div>
                      <input
                        type="button"
                        className="searchicon"
                        onClick={(e) => OnSearchClicked(e)}
                      />
                    </div>
                  </div>
                  {showFilter && (
                    <div className="filterBlock">
                      <div className="filter">
                        <b>Loan Number:</b> {search}
                        <div className="closeicon">
                          <Link
                            to="#"
                            title="Close"
                            onClick={(e) => OnSearchCleared()}
                          >
                            &#215;
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="contentarea_table">
                    {rows && rows.length > 0 ? (
                      <>
                        <div className="loanTable_head">
                          <table>
                            <tbody>
                              <tr>
                                <td>Loan Number</td>
                                <td>Issuing Authority</td>
                                <td>Borrower Name</td>
                                <td>Loan Amount</td>
                                <td>Closing Date</td>
                                <td>Address</td>
                                <td>&nbsp;</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="loanTable">
                          <table>
                            <tbody>
                              {rows.map((x, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <Link to="#" onClick={(e) => OnSelectLoanForEdit(x.id)}>{x.loannumber}</Link>
                                    </td>
                                    <td>{x.issuingauth}</td>
                                    <td>{x.borrower}</td>
                                    <td>{x.loanamount}</td>
                                    <td>
                                      {Helper.ToDate(
                                        x.closingdate,
                                        "YYYY-MMM-DD"
                                      )}
                                    </td>
                                    <td>{x.propaddress}</td>
                                    <td>&nbsp;</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <div className="noContent">No Loans available</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col_1">
                <div className="blk_container">
                  <div className="head_blk">
                    <div className="blkName">Add New Loan</div>
                  </div>
                  <div className="contentarea_bgwhite">
                    <ul className="formColumn">
                      <li className="formColumn_II">
                        <div className="head_blk_IIlevel">
                          <div className="IIlevel_ContentHead">
                            General Details
                          </div>
                        </div>
                        <div className="contentarea_stakeholder_doc">
                          <ul className="formColumn">
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                Loan Purpose
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="userFormicon"></div>
                              <select
                                name="filterType"
                                required
                                value={Helper.ToString(row.purpose)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("purpose") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("purpose", e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                {loanpurposes &&
                                  loanpurposes.map((x, index) => {
                                    return (
                                      <option value={x.name} key={index}>
                                        {x.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">Loan Type</div>
                              <div className="formMandatory">*</div>
                              <div className="typeFormicon"></div>
                              <select
                                name="filterType"
                                required
                                value={Helper.ToString(row.loantype)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("loantype") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("loantype", e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                {loantypes &&
                                  loantypes.map((x, index) => {
                                    return (
                                      <option value={x.name} key={index}>
                                        {x.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">Loan Number</div>
                              <div className="formMandatory">*</div>
                              <div className="numberFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.loannumber)}
                                maxLength="10"
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("loannumber") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("loannumber", e.target.value)
                                }
                                onKeyPress={(e) => {
                                  return Helper.AcceptedChars(e, "NUMBER");
                                }}
                              />
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                Lender/Investor
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="userFormicon"></div>
                              <select
                                name="filterType"
                                required
                                value={Helper.ToString(row?.lender_id)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("lender_id") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnLenderSelect(e.target.selectedIndex)
                                }
                              >
                                {lenders &&
                                  lenders.map((x, index) => {
                                    return (
                                      <option value={x.ispid} key={index}>
                                        {x.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">Loan Amount</div>
                              <div className="formMandatory">*</div>
                              <div className="amountFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.loanamount)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("loanamount") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("loanamount", e.target.value)
                                }
                                onKeyPress={(e) => {
                                  return Helper.AcceptedChars(e, "FINANCE");
                                }}
                              />
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                Interest Rate
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="numberFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.interestrate)}
                                style={{
                                  borderColor:
                                    errors &&
                                      errors.indexOf("interestrate") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("interestrate", e.target.value)
                                }
                                onKeyPress={(e) => {
                                  return Helper.AcceptedChars(e, "FINANCE");
                                }}
                              />
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                Issuing Authority
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="typeFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.issuingauth)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("issuingauth") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("issuingauth", e.target.value)
                                }
                              />
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                Closing Date
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="dateFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.closingdate)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("closingdate") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("closingdate", e.target.value)
                                }
                                onKeyPress={(e) => {
                                  return Helper.AcceptedChars(e, "DATE");
                                }}
                              />
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                Property Address
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="locationFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.propaddress)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("propaddress") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("propaddress", e.target.value)
                                }
                              />
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                LTV (Loan-to-Value in %)
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="amountFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.ltv)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("ltv") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("ltv", e.target.value)
                                }
                                onKeyPress={(e) => {
                                  return Helper.AcceptedChars(e, "FINANCE");
                                }}
                              />
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                Tenure (In Months)
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="dateFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.tenure)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("tenure") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("tenure", e.target.value)
                                }
                                onKeyPress={(e) => {
                                  return Helper.AcceptedChars(e, "NUMBER");
                                }}
                              />
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">Parcel Id</div>
                              <div className="typeFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.parcelid)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("parcelid") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("parcelid", e.target.value)
                                }
                              />
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                Loan Channel Type
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="typeFormicon"></div>
                              <select
                                name="channeltype"
                                required
                                value={Helper.ToString(row.channeltype)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("channeltype") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("channeltype", e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                <option value="Correspondent Bank">Correspondent Bank</option>
                                <option value="Broker">Broker</option>
                                <option value="Retail">Retail</option>
                                <option value="TPO">TPO</option>
                              </select>
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">
                                Loan Delegation Type
                              </div>
                              <div className="formMandatory">*</div>
                              <div className="typeFormicon"></div>
                              <select
                                name="delegationtype"
                                required
                                value={Helper.ToString(row.delegationtype)}
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("delegationtype") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("delegationtype", e.target.value)
                                }
                              >
                                <option value="">Select</option>
                                <option value="Delegated">Delegated</option>
                                <option value="Non-Delegated">Non-Delegated</option>
                                <option value="Not Applicable">Not Applicable</option>
                              </select>
                            </li>
                            <li className="formColumn_II">
                              <div className="formContentHead">Refinance From</div>
                              {!Helper.IsNullValue(row?.purpose) && row.purpose.toLowerCase() === "refinance" && <div className="formMandatory">*</div>}
                              <div className="numberFormicon"></div>
                              <input
                                type="text"
                                value={Helper.ToString(row.refinancedfrom)}
                                maxLength="10"
                                style={{
                                  borderColor:
                                    errors && errors.indexOf("refinancedfrom") > -1
                                      ? "#ff0000"
                                      : undefined,
                                }}
                                onChange={(e) =>
                                  OnInputChanged("refinancedfrom", e.target.value)
                                }
                                onKeyPress={(e) => {
                                  return Helper.AcceptedChars(e, "NUMBER");
                                }}
                              />
                            </li>
                            {row && row.id > 0 && (
                              <li className="formColumn_II">
                                <div className="formContentHead">Funding Complete</div>
                                <div className="formMandatory">*</div>
                                <div className="genericBox">
                                  <ul className="formColumn">
                                    <li className="formColumn_II">
                                      <div className="checkRadio_Lablel">
                                        <input
                                          type="radio"
                                          id={`fundingcomplete_1`}
                                          name={`fundingcomplete`}
                                          onChange={(e) => OnInputChanged("fundingcomplete", 1)}
                                          checked={Helper.ToString(row.fundingcomplete) === "1"}
                                        />
                                        <label htmlFor={`fundingcomplete_1`}>Yes</label>
                                      </div>
                                    </li>
                                    <li className="formColumn_II">
                                      <div className="checkRadio_Lablel">
                                        <input
                                          type="radio"
                                          id={`fundingcomplete_2`}
                                          name={`fundingcomplete`}
                                          onChange={(e) => OnInputChanged("fundingcomplete", 0)}
                                          checked={Helper.ToString(row.fundingcomplete) === "0"}
                                        />
                                        <label htmlFor={`fundingcomplete_2`}>No</label>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </li>
                      <li className="formColumn_II">
                        <div className="head_blk_IIlevel">
                          <div className="IIlevel_ContentHead">
                            Borrower Details
                          </div>
                          {Helper.IsNullValue(row.formid) && (
                            <div className="IIlevel_rightContent">
                              <Link to="#" onClick={(e) => OnAddBorrower(e, 'childs')}>Add Borrower</Link>
                            </div>
                          )}
                        </div>
                        <div className="contentarea_stakeholder_doc">
                          {borrowers && borrowers.childs &&
                            borrowers.childs.filter((z) => parseInt(z.statusid) === 1)
                              .map((x, index) => {
                                return (
                                  <RenderBorrower
                                    errors={errors}
                                    count={borrowers.childs.filter((z) => parseInt(z.statusid) === 1).length}
                                    setProgress={setProgress}
                                    races={races}
                                    ethnicities={ethnicities}
                                    onUpdateBorrower={OnUpdateBorrower}
                                    item={x} index={x.key} key={index} />
                                );
                              })
                          }
                        </div>
                      </li>
                    </ul>
                    <div className="form_btn_label">
                      <input
                        type="button"
                        value="Submit"
                        className="form_btn"
                        onClick={(e) => OnAddLoanClicked(e)}
                      />
                      <input
                        type="button"
                        value="Reset"
                        className="form_btn"
                        onClick={(e) => OnResetClicked(e)}
                      />
                    </div>
                    {error && <div className="errormessage">{error}</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RenderBorrowerSelect
        onProgress={OnShowProgress}
        showborrower={showBorrower}
        onBorrowerSelect={OnBorrowerSelect}
      />
      <Footer />
    </>
  );
};

export default LoanComponent;
