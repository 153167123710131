const moment = require("moment");

let fn = {};

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

let locName = window.location.hostname;

if (locName === "localhost") {
  fn.APIUrl = "http://localhost:9000/";
  // fn.APIUrl = "https://api.staging.i3auras.com/";
} else if (locName === "www.i3auras.com" || locName === "i3auras.com") {
  fn.APIUrl = "https://api.i3auras.com/";
} else if (locName === "www.staging.i3auras.com" || locName === "staging.i3auras.com") {
  fn.APIUrl = "https://api.staging.i3auras.com/";
}

fn.IsNull = (e) => {
  if (e === undefined || e === null) return true;
  return false;
};

fn.IsNullValue = (e) => {
  if (e === undefined || e === null || e === "") return true;
  return false;
};

fn.ToString = (e, defa) => {
  if (fn.IsNull(e)) return defa ? defa : "";
  return e.toString();
};

fn.ToInteger = (e, defa) => {
  const re = /^[0-9\b]+$/;
  if (!fn.IsNullValue(e) && re.test(e)) {
    return parseInt(e);
  }
  return !fn.IsNullValue(defa) ? defa : -1;
};

fn.ExtractNumbers = (e) => {
  var re = /\d+/g;
  if (!fn.IsNullValue(e)) {
    let result = e.match(re);
    return result;
  }
  return e;
};

fn.ToTable = (e) => {
  if (!fn.IsNullValue(e)) {
    return e.toLowerCase().replace(/ /g, "_");
  }
  return e;
};

fn.ToBoolean = (e, check) => {
  if (fn.IsNullValue(e)) return false;
  if (typeof e === "string") {
    return e.toString() === check.toString();
  } else if (typeof e === "number") {
    return parseInt(e) === parseInt(check);
  } else if (typeof e === "boolean") {
    return e === check;
  }

  return parseInt(e) === parseInt(check);
};

fn.ToBool = (e) => {
  if (fn.IsNullValue(e)) return false;
  if (e.toString().toLowerCase() === "false") return false;
  if (e.toString().toLowerCase() === "true") return true;
  return false;
};

fn.ToChecked = (e) => {
  if (fn.IsNullValue(e)) return false;
  return e.toString().toUpperCase() === "TRUE";
};

fn.FromIntToBoolean = (e) => {
  if (fn.IsNullValue(e)) return false;
  return parseInt(e) === 1;
};

fn.IsRequired = (e) => {
  if (fn.IsNullValue(e)) return false;
  return e.toString().toUpperCase() === "TRUE";
};

fn.AcceptedChars = (e, type) => {
  let evt = e ? e : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;

  var defaCodes = [8, 9, 13];
  var digitCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

  if (type === "TEXT") {
    if (digitCodes.indexOf(charCode) > -1) {
      e.preventDefault();
      return false;
    }
    return true;
  } else {
    Array.prototype.push.apply(defaCodes, digitCodes);
    if (type === "DATE") {
      Array.prototype.push.apply(defaCodes, [32, 40, 41, 45, 47]);
    } else if (type === "PHONE") {
      Array.prototype.push.apply(defaCodes, [32, 40, 41, 45]);
    } else if (type === "FINANCE") {
      defaCodes.push(46);
    } else if (type === "NFINANCE") {
      defaCodes.push(45);
      defaCodes.push(46);
    }
  }

  if (defaCodes.indexOf(charCode) === -1) {
    e.preventDefault();
    return false;
  }

  if (type === "FINANCE") {
    var text = e.target.value;
    if (
      text.indexOf(".") !== -1 &&
      text.substring(text.indexOf(".")).length > 2 &&
      charCode !== 0 &&
      charCode !== 8 &&
      e.target.selectionStart >= text.length - 2
    ) {
      e.preventDefault();
      return false;
    }
  }

  return true;
};

fn.IsJSONEmpty = (e) => {
  if (fn.IsNull(e)) return true;
  for (var key in e) {
    if (Object.prototype.hasOwnProperty.call(e, key)) {
      return false;
    }
  }
  return true;
};

fn.GetStringJoin = (row, names) => {
  if (fn.IsJSONEmpty(row)) return null;
  let items = [];
  for (let i = 0; i < names.length; i++) {
    let fld = names[i];
    if (!fn.IsNullValue(row[fld])) items.push(row[fld]);
  }

  let _name = items.join(" ").trim();
  return _name;
};

fn.ToDate = (e, format) => {
  if (fn.IsNullValue(e)) return null;
  let tformat = format || "MM/DD/YYYY";
  return moment(new Date(e.toString())).format(tformat);
};

fn.ToUSAPhone = (e) => {
  if (fn.IsNullValue(e)) return null;
  if (phoneRegex.test(e)) {
    return e.replace(phoneRegex, "($1) $2-$3");
  }
  return null;
};

fn.NativeInputClick = (keyid) => {
  const input = document.getElementById(keyid);
  input.dispatchEvent(new Event("click", { bubbles: true }));
};

fn.OpenExternalLink = (e) => {
  window.open(e, "_blank");
};

fn.IsFound = (list, key, val) => {
  if (fn.IsJSONEmpty(list)) return false;
  let tmp = list.filter((x) => x[key] === val);
  return tmp.length > 0;
};

fn.GetSettingsLink = (list, key, val) => {
  if (fn.IsJSONEmpty(list)) return "#";
  let tmp = list.filter((x) => x[key] === val);
  return tmp.length > 0 ? `/${tmp[0].link}` : "#";
};

fn.IsArrayNull = (e) => {
  if (fn.IsNull(e)) return true;
  if (e.length > 0) {
    return false;
  }
  return true;
};

export default fn;
