import { React, Link, Header, Footer, Loader, Helper } from "../../../cIncludes";
import {
  Add as AddUsers,
  SetProfileStatus,
  GetEntityUsers,
} from "../../../services/usersApi";

import { GetUserTypes, GetModuleFilters } from "../../../services/commonApi";
import ChildHeadersComponent from "./cheader";

const SubUserComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [userTypes, setUserTypes] = React.useState([]);
  const [moduleTypes, setModuleTypes] = React.useState([]);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);
    let rslt = await GetEntityUsers();
    if (rslt.status === 100) {
      setRows(rslt.data);
    }

    rslt = await GetModuleFilters();
    if (rslt.status === 100) {
      setModuleTypes(rslt.data);
    }

    rslt = await GetUserTypes();
    if (rslt.status === 100) {
      setUserTypes(rslt.data);
    }

    setRow({ typeindex: 0 });

    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const OnBlurChanged = (name, val) => {
    val = Helper.ToUSAPhone(val);
    if (val === null) return;
    OnInputChanged(name, val);
  };

  const OnDisableClicked = async (id, statusid) => {
    let data = {
      id: id,
      statusid: statusid === 1 || statusid === 3 ? 2 : 1,
    };

    setProgress(true);
    let rslt = await SetProfileStatus(data);
    setProgress(false);
    if (rslt.status === 100) {
      await FetchResults();
    }
  };

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const RenderButtonControl = (x) => {
    if (x.statusid === 1) {
      return (
        <Link
          to="#"
          title="Disable"
          onClick={(e) => OnDisableClicked(x.id, x.statusid)}
        >
          <div className="disableicon"></div>
        </Link>
      );
    } else if (x.statusid === 2) {
      return (
        <Link
          to="#"
          title="Enable"
          onClick={(e) => OnDisableClicked(x.id, x.statusid)}
        >
          <div className="enableicon"></div>
        </Link>
      );
    } else {
      return (
        <Link
          to="#"
          title="Disable"
          onClick={(e) => OnDisableClicked(x.id, x.statusid)}
        >
          <div className="disableicon"></div>
        </Link>
      );
    }
  };

  const OnUpdateClicked = async (e) => {
    e.preventDefault();
    setError(null);
    setErrors([]);
    setProgress(true);
    row.child = true;
    if (parseInt(row.typeindex) === 2) {
      row.defaroute = 'ula';
    }
    let rslt = await AddUsers(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
      return;
    }
    await FetchResults();
  };

  const DisplayTypeName = (e) => {
    let tmp = userTypes.filter((x) => parseInt(x.value) === parseInt(e));
    if (tmp && tmp.length > 0) {
      return tmp[0].label;
    }
    return "Un-Known";
  };

  return (
    <>
      <Loader show={progress} />
      <Header hname="mu" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_15by85">
                <ChildHeadersComponent mnu="uss" />
                <li className="col_30percent">
                  <div className="blk_container">
                    <div className="head_blk">
                      <div className="blkName">Add New Sub User</div>
                    </div>
                    <div className="contentarea">
                      <ul className="formColumn">
                        <li className="formColumn_I">
                          <div className="formContentHead">Type</div>
                          <div className="formMandatory">*</div>
                          <div className="typeFormicon"></div>
                          <select
                            name="filterType"
                            required
                            value={Helper.ToString(row.typeindex)}
                            style={{
                              borderColor:
                                errors && errors.indexOf("typeindex") > -1
                                  ? "#ff0000"
                                  : undefined,
                            }}
                            onChange={(e) =>
                              OnInputChanged(
                                "typeindex",
                                e.target.selectedIndex
                              )
                            }
                          >
                            {userTypes &&
                              userTypes.map((x, index) => (
                                <option value={x.value} key={index}>
                                  {x.label}
                                </option>
                              ))}
                          </select>
                        </li>
                        <li className="formColumn_I">
                          <div className="formContentHead">First Name</div>
                          <div className="formMandatory">*</div>
                          <div className="userFormicon"></div>
                          <input
                            type="text"
                            value={Helper.ToString(row.first_name)}
                            style={{
                              borderColor:
                                errors && errors.indexOf("first_name") > -1
                                  ? "#ff0000"
                                  : undefined,
                            }}
                            onChange={(e) =>
                              OnInputChanged("first_name", e.target.value)
                            }
                          />
                        </li>
                        <li className="formColumn_I">
                          <div className="formContentHead">Last Name</div>
                          <div className="formMandatory">*</div>
                          <div className="userFormicon"></div>
                          <input
                            type="text"
                            value={Helper.ToString(row.last_name)}
                            style={{
                              borderColor:
                                errors && errors.indexOf("last_name") > -1
                                  ? "#ff0000"
                                  : undefined,
                            }}
                            onChange={(e) =>
                              OnInputChanged("last_name", e.target.value)
                            }
                          />
                        </li>
                        <li className="formColumn_I">
                          <div className="formContentHead">Work Phone</div>
                          <div className="landphFormicon"></div>
                          <input
                            type="text"
                            value={Helper.ToString(row.work_phone)}
                            style={{
                              borderColor:
                                errors && errors.indexOf("work_phone") > -1
                                  ? "#ff0000"
                                  : undefined,
                            }}
                            onChange={(e) =>
                              OnInputChanged("work_phone", e.target.value)
                            }
                            onKeyPress={(e) => {
                              return Helper.AcceptedChars(e, "PHONE");
                            }}
                            onBlur={(e) =>
                              OnBlurChanged("work_phone", e.target.value)
                            }
                          />
                        </li>
                        <li className="formColumn_I">
                          <div className="formContentHead">Cell Phone</div>
                          <div className="formMandatory">*</div>
                          <div className="cellphFormicon"></div>
                          <input
                            type="text"
                            value={Helper.ToString(row.mobile_number)}
                            style={{
                              borderColor:
                                errors && errors.indexOf("mobile_number") > -1
                                  ? "#ff0000"
                                  : undefined,
                            }}
                            onChange={(e) =>
                              OnInputChanged("mobile_number", e.target.value)
                            }
                            onKeyPress={(e) => {
                              return Helper.AcceptedChars(e, "PHONE");
                            }}
                            onBlur={(e) =>
                              OnBlurChanged("mobile_number", e.target.value)
                            }
                          />
                        </li>
                        <li className="formColumn_I">
                          <div className="formContentHead">Email</div>
                          <div className="formMandatory">*</div>
                          <div className="mailFormicon"></div>
                          <input
                            type="text"
                            value={Helper.ToString(row.email)}
                            style={{
                              borderColor:
                                errors && errors.indexOf("email") > -1
                                  ? "#ff0000"
                                  : undefined,
                            }}
                            onChange={(e) =>
                              OnInputChanged("email", e.target.value)
                            }
                          />
                        </li>
                        <li className="formColumn_I" style={{ display: "none" }}>
                          <div className="formContentHead">Default Route</div>
                          <div className="formMandatory">*</div>
                          <div className="typeFormicon"></div>
                          <select
                            name="filterType"
                            required
                            value={Helper.ToString(row.defaroute)}
                            style={{
                              borderColor:
                                errors && errors.indexOf("defaroute") > -1
                                  ? "#ff0000"
                                  : undefined,
                            }}
                            onChange={(e) =>
                              OnInputChanged(
                                "defaroute",
                                e.target.value
                              )
                            }
                          >
                            {moduleTypes &&
                              moduleTypes.map((x, index) => (
                                <option value={x.value} key={index}>
                                  {x.label}
                                </option>
                              ))}
                          </select>
                        </li>
                      </ul>
                      <div className="form_btn_label_notop_8">
                        <input
                          type="button"
                          value="Submit"
                          className="form_btn"
                          onClick={(e) => OnUpdateClicked(e)}
                        />
                        <input
                          type="button"
                          value="Reset"
                          className="form_btn"
                          onClick={(e) => setRow({})}
                        />
                      </div>
                      {error && <div className="errormessage">{error}</div>}
                    </div>
                  </div>
                </li>
                <li className="col_70percent">
                  <div className="blk_container">
                    <div className="head_blk">
                      <div className="blkName">Sub User List</div>
                    </div>
                    <div className="contentarea_table_border">
                      <div className="userListTable_head">
                        <table>
                          <thead>
                            <tr>
                              <td>Type</td>
                              <td>Name</td>
                              <td>Work Phone</td>
                              <td>Cell Phone</td>
                              <td>Email</td>
                              <td>Status</td>
                              <td>&nbsp;</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      {rows && rows.length > 0 ? (
                        <div className="userListTable">
                          <table>
                            <tbody>
                              {rows.map((x, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{DisplayTypeName(x.typeindex)}</td>
                                    <td>
                                      {Helper.GetStringJoin(x, [
                                        "first_name",
                                        "last_name",
                                      ])}
                                    </td>
                                    <td>{x.work_phone}</td>
                                    <td>{x.mobile_number}</td>
                                    <td>{x.email}</td>
                                    <td>{x.statusname}</td>
                                    <td>{RenderButtonControl(x)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="noContent">No users available</div>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SubUserComponent;
