import API from "./api";
import session from "../session";

const AddPersonaUser = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`personauser`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Get = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`profile?p=${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Set = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`profile`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const DeleteDocument = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.delete(`profile`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export { AddPersonaUser, Get, Set, DeleteDocument };
