import ChildHeadersComponent from "./cheader";
import { React, Header, Footer } from "../../../cIncludes";

const AccountsComponent = () => {
  return (
    <>
      <Header hname="mu" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_15by85">
                <ChildHeadersComponent mnu="usa" />
                <li className="col_100percent">
                  <div className="blk_container">
                    <div className="contentarea">Content</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccountsComponent;
