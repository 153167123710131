import API from "./api";
import session from "../session";
import axios from "axios";

const UploadFile = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post("uploads", input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    console.log(err);
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetSignedUrl = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`getsignedurl`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    console.log(err);
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const UpdateFileInfo = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post("updatefileinfo", input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    console.log(err);
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const MoveFile = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post("movefile", input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    console.log(err);
    return { statusText: "Could not connect to server", status: 900 };
  }
};

/* const UploadToISP1 = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post("uploadtoisp", input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    console.log(err);
    return { statusText: "Could not connect to server", status: 900 };
  }
}; */

const UploadToBucket = async (url, data, type) => {
  const headers = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": type,
    },
  };
  try {
    const res = await axios.put(url, data, headers);
    return res;
  } catch (err) {
    console.log(err);
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const UploadToISP = async (url, file, filename) => {
  return new Promise(async (resolve) => {
    var data = new FormData();
    data.append(filename, file);

    var config = {
      method: 'put',
      url: url,
      headers: {
        "Access-Control-Allow-Origin": "*",
        'x-ms-blob-type': 'BlockBlob'
      },
      data: data
    };

    try {
      axios(config)
        .then(function (res) {
          if (res.status === 201) return resolve({ statusText: res.data, status: 100 });
          return resolve({ statusText: res.data, status: 900 });
        })
        .catch(function (error) {
          console.log(error);
          return resolve({ statusText: error.message, status: 900 });
        });
    } catch (err) {
      console.log(err);
      return resolve({ statusText: "Could not connect to server", status: 900 });
    }
  });
};

export {
  UploadFile,
  GetSignedUrl,
  UploadToBucket,
  UpdateFileInfo,
  MoveFile,
  UploadToISP,
};
