import API from "./api";
import session from "../session";

const Add = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`documents`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Get = async (loanid, typeid) => {
  let token = await session.GetHeader();
  try {
    let qry = `?l=${loanid}&t=${typeid}`;
    const res = await API.get(`documents${qry}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetISPDocuments = async (loanid, typeid) => {
  let token = await session.GetHeader();
  try {
    let qry = `?l=${loanid}&t=${typeid}`;
    const res = await API.get(`ispdocuments${qry}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetISPDocStatus = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`setispdocstatus`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetType = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`doctypes`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SubmitDoc = async (loanid, typeid, approval) => {
  let token = await session.GetHeader();
  try {
    let qry = `?l=${loanid}&t=${typeid}&a=${approval}`;
    const res = await API.get(`submitdocs${qry}`, {
      headers: token,
    });
    return res.data;
    //return session.IsSessionExpired(res.data);
  } catch (err) {
    console.log(err);
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Delete = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.delete(`documents/${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetDocUrl = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`getdocurl`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetDocUrlPath = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`getdocurlpath`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const ValidateDocTypes = async (loanId, typeId) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`validdoctypes?l=${loanId}&t=${typeId}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetDocForApproval = async (docId) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getdocforapprove/${docId}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetISPDocConsent = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`setispdocconsent`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetISPApprovalStatus = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`setapprovalstatus/${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export {
  Add,
  Get,
  GetType,
  Delete,
  SubmitDoc,
  GetDocUrl,
  GetISPDocuments,
  GetDocUrlPath,
  SetISPDocStatus,
  ValidateDocTypes,
  GetDocForApproval,
  SetISPDocConsent,
  SetISPApprovalStatus
};
