import {
  UpdateFileInfo,
  GetSignedUrl,
  UploadToBucket,
  MoveFile,
  UploadToISP,
} from "../services/uploadApi";

var fn = {};

const GetContentType = (type) => {
  let _type = "application/pdf";
  switch (type) {
    case "JPG":
    case "JPEG":
      _type = "image/jpeg";
      break;
    case "PNG":
      _type = "image/png";
      break;
    default:
      _type = "application/pdf";
      break;
  }
  return _type;
};
//

fn.UploadDocs = async (file, jsonObj) => {
  return new Promise(async (resolve) => {
    let rslt;
    rslt = await GetSignedUrl(jsonObj.request);
    if (rslt.status !== 100) return resolve(rslt);
    let urlInfo = rslt.data;
    rslt = await UploadToISP(urlInfo.ISPUrl, file, file.name);
    if (rslt.status !== 100) return resolve(rslt);
    rslt = await UploadToBucket(urlInfo.AWSUrl, file, urlInfo.ContentType);
    if (rslt.status !== 200) return resolve(rslt);
    let filename = `uploads/${jsonObj.loanNumber}/${urlInfo.blobName}`;
    let urlData = {
      url: rslt.request.responseURL,
      blobname: filename
    };
    rslt = await MoveFile(urlData);
    if (rslt.status !== 100) return resolve(rslt);

    let data = jsonObj;
    data.blobname = urlInfo.blobName;
    data.filename = filename;

    rslt = await UpdateFileInfo(data);
    if (rslt.status !== 100) return resolve(rslt);
    return resolve({
      status: 100,
      statusText: "File uploaded successful",
    });
  });
};

fn.UploadProfileDocs = async (file) => {
  return new Promise(async (resolve) => {
    let rslt;
    let req = {
      fileName: file.name,
      type: "PROFILE"
    };
    rslt = await GetSignedUrl(req);
    if (rslt.status !== 100) return resolve(rslt);
    let urlInfo = rslt.data;
    rslt = await UploadToISP(urlInfo.ISPUrl, file, file.name);
    if (rslt.status !== 100) return resolve(rslt);
    rslt = await UploadToBucket(urlInfo.AWSUrl, file, urlInfo.ContentType);
    if (rslt.status !== 200) return resolve(rslt);
    let filename = `uploads/${urlInfo.blobName}`;
    let urlData = {
      url: rslt.request.responseURL,
      blobname: filename
    };
    rslt = await MoveFile(urlData);
    if (rslt.status !== 100) return resolve(rslt);
    let data = {
      blobname: urlInfo.blobName,
      filename: `uploads/${urlInfo.blobName}`
    };
    return resolve({
      status: 100,
      statusText: "File uploaded successful",
      data: data
    });
  });
};

fn.Upload = async (file, obj, jsonObj, ctype) => {
  return new Promise(async (resolve) => {
    let rslt, data;

    data = {
      fileName: "sample-3kb.pdf",
      lender_id: "5e8ece6a15e71c002b50ac40",
      borrower_id: ["5e8eee3404ee60002cd7466f", "5e8eee3404ee60002cd7466e", "5e8eee3404ee60002cd7466a"],
      loan_id: "Fvb163",
      stage: "Disclosure : DISCLOSURE"
    }

    rslt = await GetSignedUrl(data);
    if (rslt.status !== 100) return resolve(rslt);
    let signedUrls = rslt.data;
    console.log(signedUrls);
    return resolve({ status: 900 });


    /* let folder = jsonObj ? obj.folder : obj;
    let type = jsonObj ? obj.type : undefined;
    let ofilename = jsonObj ? obj.ofilename : file.name;
    let contentType = GetContentType(ctype);
    rslt = await GetSignedUrl(ofilename);
    if (rslt.status !== 100) return resolve(rslt);
    let signedUrls = rslt.data;
    rslt = await UploadToISP(signedUrls.ISPUrl, file, ofilename);
    if (rslt.status !== 200) return resolve(rslt);
    rslt = await UploadToBucket(signedUrls.AWSUrl, file, contentType);
    if (rslt.status !== 200) return resolve(rslt);
    let url = JSON.stringify(rslt.request.responseURL);
    rslt = await MoveFile({ url: url, folder: folder });
    if (rslt.status !== 100) return resolve(rslt); */

    /* 
    let fullname = rslt.data;
    let pathArray = fullname.split("/");
    let data = {
      file: rslt.data,
      filename: pathArray[pathArray.length - 1],
      folder: folder,
      ofilename: ofilename,
    };
    rslt = await UploadToISP(data);
    if (rslt.status !== 100) return resolve(rslt);
    data = {
      blobname: rslt.data,
      filename: fullname,
    };
    if (type === "DOC") {
      obj.blobname = rslt.data;
      obj.filename = fullname;
      rslt = await UpdateFi; leInfo(obj);
      if (rslt.status !== 100) return resolve(rslt);
    } */

    /* return resolve({
      status: 100,
      data: data,
      statusText: "File uploaded successful",
    }); */
  });
};

fn.Upload1 = async (file, obj, jsonObj, ctype) => {
  return new Promise(async (resolve) => {
    let rslt;
    let folder = jsonObj ? obj.folder : obj;
    let type = jsonObj ? obj.type : undefined;
    let ofilename = jsonObj ? obj.ofilename : file.name;
    let contentType = GetContentType(ctype);
    rslt = await GetSignedUrl(contentType);
    if (rslt.status !== 100) return resolve(rslt);
    let signedUrl = JSON.parse(rslt.data);

    rslt = await UploadToBucket(signedUrl, file, contentType);
    if (rslt.status !== 200) return resolve(rslt);
    let url = JSON.stringify(rslt.request.responseURL);
    rslt = await MoveFile({ url: url, folder: folder });
    if (rslt.status !== 100) return resolve(rslt);

    let fullname = rslt.data;
    let pathArray = fullname.split("/");
    let data = {
      file: rslt.data,
      filename: pathArray[pathArray.length - 1],
      folder: folder,
      ofilename: ofilename,
    };
    rslt = await UploadToISP(data);
    if (rslt.status !== 100) return resolve(rslt);
    data = {
      blobname: rslt.data,
      filename: fullname,
    };
    if (type === "DOC") {
      obj.blobname = rslt.data;
      obj.filename = fullname;
      rslt = await UpdateFileInfo(obj);
      if (rslt.status !== 100) return resolve(rslt);
    }

    return resolve({
      status: 100,
      data: data,
      statusText: "File uploaded successful",
    });
  });
};

export default fn;
