import { React, Header, Footer } from "../../../cIncludes";

const AdminDashboardComponent = () => {
  return (
    <>
      <Header hname="ad" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">Admin Dash Board</div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminDashboardComponent;
