import {
  React,
  Link,
  ViewPdfDoc,
  Helper,
  Loader,
  Header,
  Footer,
  Session,
} from "../../../cIncludes";

import {
  GetISPDocuments,
  GetDocUrl,
  SetISPDocConsent,
  SetISPApprovalStatus,
} from "../../../services/documentsApi";
import RenderLoanSummary from "../common/RenderLoanSummary";

const DocumentApprovaComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [loanreload, setSetLoanReload] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [loanId, setLoanId] = React.useState(0);

  const [pdfStream, setPdfStream] = React.useState(null);
  const [viewPdf, setViewPdf] = React.useState(false);
  const [viewType, setViewType] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [user, setUser] = React.useState({});
  const [approvalSelected, setApprovalSelected] = React.useState(false);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  if (initlized) {
    setSetLoanReload(false);
    setInitlized(false);
    setSelectAll(false);
    setViewPdf(false);
  }

  const LoadDocumentsList = async () => {
    setProgress(true);
    setError(null);
    setRows({ childs: [] });
    let _user = await Session.RetrieveJSONAsync("user");
    setUser(_user);
    if (parseInt(_user.approved) === 1) {
      setApprovalSelected(true);
    }

    let rslt = await GetISPDocuments(loanId, -100);
    setProgress(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    //console.log(rslt.data);
    if (!Helper.IsArrayNull(rslt.data)) {
      //let tmp = rslt.data.filter((x) => !Helper.ToBool(x.document_consent));
      //tmp = rslt.data.filter((x) => x.document_consent !== 'accepted' && x.document_consent !== 'denied');
      //console.log(rslt.data);
      setRows({ childs: rslt.data });
    }
  };

  const OnLoadStakeHolders = async (loanId, loanNumber) => {
    setLoanId(loanId);
  };

  React.useEffect(() => {
    if (parseInt(loanId) > 0) {
      setSetLoanReload(true);
    }
  }, [loanId]);

  if (loanreload) {
    setSetLoanReload(false);
    LoadDocumentsList();
  }

  const OnLinkOpen = async (e, type) => {
    let data = {
      doc: e,
      type: type,
    };
    setViewType(type);
    setProgress(true);
    let rslt = await GetDocUrl(data);
    setProgress(false);
    if (rslt.status === 100) {
      setPdfStream(rslt.data);
      setViewPdf(true);
    } else {
      alert(rslt.statusText);
    }
  };

  const IsResultsFound = () => {
    return (
      Helper.ToInteger(loanId) > 0 &&
      rows &&
      rows.childs &&
      rows.childs.length > 0
    );
  };

  const IsLoanFound = () => {
    return Helper.ToInteger(loanId) > 0;
  };

  const IsSelectedCount = () => {
    return rows.childs.filter((x) => Helper.ToBool(x.selectedItem)).length > 0;
  };

  const RenderCheckBox = ({ item }) => {
    let _status = GetApprovalStatus(item);
    if (_status !== "Awaiting") {
      /* return <td style={{ width: "1px" }}>&nbsp;</td>; */
      return <td>&nbsp;</td>;
    } else {
      if (user.typeindex === 3) {
        return (
          <td>
            <input
              id={`rowcheck${item.keyIndex}`}
              type="checkbox"
              checked={item.selectedItem}
              onChange={() => OnDummyChange()}
              onClick={(e) => OnValueSelected(e.target.checked, item.keyIndex)}
            />
            <label htmlFor={`rowcheck${item.keyIndex}`}></label>
          </td>
        );
      } else {
        return <td style={{ width: "1px" }}>&nbsp;</td>;
      }
    }
  };

  const GetApprovalStatus = (x) => {
    let _status = x.document_consent.toString().toLowerCase();
    let _statusTag = "Awaiting";
    if (_status === "accepted") {
      _statusTag = "Approved";
    } else if (_status === "denied") {
      _statusTag = "Denied";
    }
    return _statusTag;
  };

  const OnDummyChange = () => {};

  const OnValueSelected = (e, indx) => {
    let _rows = rows.childs;
    let index = _rows.findIndex((x) => parseInt(x.keyIndex) === parseInt(indx));
    _rows[index]["selectedItem"] = e;
    setRows({ childs: _rows });
  };

  const OnSelectedAll = (e) => {
    setSelectAll(e);
    if (rows && rows.childs) {
      let _rows = rows.childs;
      _rows.forEach((row) => {
        row.selectedItem = e;
      });
      setRows({ childs: _rows });
    }
  };

  const OnSubmitClicked = async (e, val) => {
    e.preventDefault();
    let _rows = rows.childs.filter(
      (x) =>
        Helper.ToBool(x.selectedItem) && GetApprovalStatus(x) === "Awaiting"
    );
    let data = {};
    if (!Helper.IsArrayNull(_rows)) {
      let firstRow = _rows[0];
      data = {
        lender_id: firstRow["lender_id"],
        borrower_id: firstRow["borrower_id"],
        loan_id: firstRow["loan_id"],
        stage: firstRow["doctype"],
        consent_array: [],
      };

      _rows.forEach((row) => {
        data.consent_array.push({
          document_name: row.docname,
          version_identifier: row.version_identifier,
          consent: val,
        });
      });
      setProgress(true);
      let rslt = await SetISPDocConsent(data);
      setProgress(false);
      if (rslt.status !== 100) {
        setError(rslt.statusText);
        return;
      }
      _rows = rows.childs;
      rslt.data.forEach((elm) => {
        let _index = _rows.findIndex((x) => x.version_identifier === elm);
        if (_index > -1) {
          _rows[_index]["document_consent"] = val;
        }
      });
      setRows({ childs: _rows });
      setSelectAll(false);
    }
  };

  const OnApprovalClicked = async (e) => {
    e.preventDefault();
    setProgress(true);
    let rslt = await SetISPApprovalStatus(loanId);
    setProgress(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    user.approved = 1;
    await Session.StoreJSON("user", user);
    let _user = await Session.RetrieveJSONAsync("user");
    setUser(_user);
    setApprovalSelected(true);
  };

  return (
    <>
      <Loader show={progress} />
      <Header hname="uc" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="breadcrumb">
                <li className="breadcrumb_home"></li>
                <li className="breadcrumb">Documents</li>
                <li className="breadcrumb">Approve Documents</li>
              </ul>
              <RenderLoanSummary
                onLoadStakeHolders={OnLoadStakeHolders}
                viewonly={true}
              />
              {IsLoanFound() && !error && (
                <div className="col_1">
                  <div className="blk_container">
                    <div className="head_blk">
                      <div className="blkName">Documents List</div>
                    </div>
                    <div className="contentarea_table">
                      <div className="approvalTable_head">
                        <table>
                          <tbody>
                            <tr>
                              {user.typeindex === 3 ? (
                                <td>
                                  <input
                                    id="rowcheck"
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={() => OnDummyChange()}
                                    onClick={(e) =>
                                      OnSelectedAll(e.target.checked)
                                    }
                                  />
                                  <label htmlFor="rowcheck"></label>
                                </td>
                              ) : (
                                <td style={{ width: "1px" }}>&nbsp;</td>
                              )}
                              <td>Sl.</td>
                              <td>Document Type</td>
                              <td>Document Name</td>
                              <td>Document Info</td>
                              <td>Ver</td>
                              <td>Uploaded By</td>
                              <td>Date &amp; Time</td>
                              <td>ISP</td>
                              <td>Aura</td>
                              <td>Status</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {IsResultsFound() && (
                        <>
                          <div className="approvalTable">
                            <table>
                              <tbody>
                                {rows &&
                                  rows.childs &&
                                  rows.childs.map((x) => {
                                    return (
                                      <tr key={x.keyIndex}>
                                        <RenderCheckBox item={x} />
                                        <td>{x.keyIndex}</td>
                                        <td>{x.child ? null : x.doctype}</td>
                                        <td>{x.child ? null : x.docname}</td>
                                        <td>{x.child ? null : x.docinfo}</td>
                                        <td>{x.version}</td>
                                        <td>
                                          {Helper.GetStringJoin(x, [
                                            "first_name",
                                            "last_name",
                                          ])}
                                        </td>
                                        <td>
                                          {Helper.ToDate(
                                            x.createdt,
                                            "YYYY-MM-DD HH:mm"
                                          )}
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            onClick={(e) =>
                                              OnLinkOpen(x.blobname, "ISP")
                                            }
                                            title="PDF"
                                          >
                                            <div className="pdficon"></div>
                                          </Link>
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            onClick={(e) =>
                                              OnLinkOpen(x.blobname, "AWS")
                                            }
                                            title="PDF"
                                          >
                                            <div className="pdficon"></div>
                                          </Link>
                                        </td>
                                        <td>
                                          <input
                                            type="button"
                                            value={GetApprovalStatus(x)}
                                            disabled
                                            className="approveDenyButton"
                                          />
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                          {user.typeindex === 3 && IsSelectedCount() && (
                            <div className="approvalButLabel">
                              <input
                                type="button"
                                value="Approve"
                                className="appDenyButton"
                                onClick={(e) => OnSubmitClicked(e, "accepted")}
                              />
                              <input
                                type="button"
                                value="Deny"
                                className="appDenyButton"
                                onClick={(e) => OnSubmitClicked(e, "denied")}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {!IsResultsFound() && !error && (
                        <div className="noContent">No documents available</div>
                      )}
                    </div>
                  </div>
                  {user.typeindex === 3 && (
                    <div className="blk_container_approval">
                      <div className="contentarea_acknowledge">
                        <div className="checkRadio_Lable_approval">
                          <input
                            id="chkConsent"
                            type="checkbox"
                            checked={approvalSelected}
                            onChange={() => OnDummyChange()}
                            onClick={(e) =>
                              setApprovalSelected(e.target.checked)
                            }
                            disabled={
                              parseInt(user.approved) > 0
                                ? "disabled"
                                : undefined
                            }
                          />
                          <label htmlFor="chkConsent">
                            I also consent to any version of the above document
                            and/or any new document provided by me related to
                            this loan I also consent to any version of the above
                            document and/or any new document provided by me
                            related to this loan
                          </label>
                        </div>
                        {parseInt(user.approved) === 0 && approvalSelected && (
                          <div className="ack_but_label">
                            <input
                              type="button"
                              value="Give Consent"
                              className="ackButton"
                              onClick={(e) => OnApprovalClicked(e)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {!IsResultsFound() && error && (
                <div className="errormessage">{error}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ViewPdfDoc
        stream={pdfStream}
        setViewPdf={setViewPdf}
        viewPdf={viewPdf}
        viewType={viewType}
      />
    </>
  );
};

export default DocumentApprovaComponent;
