import { React, Link, Header, Footer, Loader, Helper } from "../../../cIncludes";
import { GetShort } from "../../../services/entitiesApi";
import { GetEntityRoles, SetEntityRoles } from "../../../services/adminApi";

const AdminRoleConfigComponent = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [entities, setEntities] = React.useState([]);
  const [selectedEntityId, setSelectedEntityId] = React.useState(0);
  const [displayName, setDisplayName] = React.useState("");

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);
    let rslt = await GetShort();
    if (rslt.status === 100) {
      rslt.data[0].display_name = "Select Entity";
      setEntities(rslt.data);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  React.useEffect(() => {
    if (selectedEntityId > 0) {
      let tmp = entities.filter(
        (x) => parseInt(x.id) === parseInt(selectedEntityId)
      );
      setDisplayName(tmp[0].display_name);
      const fn = async () => {
        setProgress(true);
        let rslt = await GetEntityRoles(selectedEntityId);
        if (rslt.status === 100) {
          setRows(rslt.data);
        }
        setProgress(false);
      };

      fn();
    }
  }, [selectedEntityId, entities]);

  const OnCheckedUpdate = async (name, val) => {
    setRows((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnDummyChanged = async (e, keyid) => {
    let tmpRows = rows.childs;
    let index = tmpRows.findIndex((x) => parseInt(x.id) === parseInt(keyid));
    if (index > -1) {
      tmpRows[index].statusid = e.target.checked ? 1 : 0;
      OnCheckedUpdate("childs", tmpRows);
    }
  };

  const OnUpdateClicked = async (e) => {
    setProgress(true);
    await SetEntityRoles(rows.childs);
    setProgress(false);
  };

  return (
    <>
      <Loader show={progress} />
      <Header hname="as" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_15by85">
                <li className="colLeft250">
                  <div className="blk_container">
                    <ul className="accLeftNavi">
                      <li className="accLeftNavi">
                        <Link to="/asm">
                          <div className="leftNavi_configIcon"></div>Module
                          Configuration
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/asd">
                          <div className="leftNavi_userIcon"></div>Document Mapping
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/ade">
                          <div className="leftNavi_userIcon"></div>Document Entity Mapping
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="#" className="current">
                          <div className="leftNavi_userIcon"></div>Role Mapping
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/asa">
                          <div className="leftNavi_settingIcon"></div>Account
                          Settings
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="col_100pc-250">
                  <div className="blk_container">
                    <div className="head_blk">
                      <div className="blkName">Roles Mapping</div>
                    </div>
                    <div className="contentarea">
                      <div className="noContent" style={{ display: "none" }}>
                        No data available
                      </div>
                      <div className="config">
                        <div className="selectModuleBlk">
                          <ul className="formColumn">
                            <li className="formColumn_III">
                              <div className="entityFormicon"></div>
                              <select
                                name="filterType"
                                required
                                value={Helper.ToInteger(selectedEntityId, 0)}
                                onChange={(e) =>
                                  setSelectedEntityId(e.target.value)
                                }
                              >
                                {entities &&
                                  entities.map((x, index) => {
                                    return (
                                      <option value={x.id} key={index}>
                                        {x.display_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </li>
                          </ul>
                        </div>
                        {selectedEntityId > 0 && (
                          <>
                            <div className="configHead">
                              Roles for {displayName}
                            </div>
                            <ul className="formColumn">
                              <li className="formColumn_III">
                                <ul className="configStructure">
                                  {rows &&
                                    rows.childs &&
                                    rows.childs.map((x) => (
                                      <span key={x.id}>
                                        <li className="configStructure_parent">
                                          <input
                                            type="checkbox"
                                            id={`chk${x.id}`}
                                            checked={Helper.FromIntToBoolean(
                                              x.statusid
                                            )}
                                            onChange={(e) =>
                                              OnDummyChanged(e, x.id)
                                            }
                                          />
                                          <label htmlFor={`chk${x.id}`}>
                                            {x.name}
                                          </label>
                                        </li>
                                      </span>
                                    ))}
                                </ul>
                              </li>
                              <li className="formColumn_III">&nbsp;</li>
                              <li className="formColumn_III">&nbsp;</li>
                            </ul>
                          </>
                        )}
                      </div>
                      {selectedEntityId > 0 && (
                        <>
                          <div className="form_btn_label">
                            <input
                              type="button"
                              value="Update"
                              className="form_btn"
                              onClick={(e) => OnUpdateClicked(e)}
                            />
                            <input
                              type="button"
                              value="Reset"
                              className="form_btn"
                            />
                          </div>
                          <div
                            className="errormessage"
                            style={{ display: "none" }}
                          >
                            All fields are mandatory
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AdminRoleConfigComponent;
