import { React, Loader } from "../../../../cIncludes";
import {
  Get as GetDocs,
  Delete as DeleteDoc,
} from "../../../../services/documentsApi";

const RenderDocumentList = ({ onDocCountChanged, reaload, typeid, loanId }) => {
  const [initlized, setInitlized] = React.useState(false);
  const [docList, setDocList] = React.useState([]);
  const [progress, setProgress] = React.useState(false);

  React.useEffect(() => {
    console.log(`Doctype Changed:${typeid}`);
    setInitlized(true);
  }, [typeid, loanId, reaload]);

  const LoadDocList = async () => {
    setProgress(true);
    let rslt = await GetDocs(loanId, typeid);
    setProgress(false);
    let cnt = 0;
    if (rslt.status === 100) {
      setDocList(rslt.data);
      if (rslt.data && rslt.data.length > 0) cnt = rslt.data.length;
    }
    if (onDocCountChanged) onDocCountChanged(cnt);
  };

  if (initlized) {
    setInitlized(false);
    LoadDocList();
  }

  const OnDeleteDocument = async (e) => {
    setProgress(true);
    await DeleteDoc(e);
    setProgress(false);
    LoadDocList();
  };

  return (
    <>
      <Loader show={progress} />
      <div className="head_blk_sub_IIlevel">Uploaded Documents</div>
      <div className="contentarea_table_border">
        {docList && docList.length > 0 ? (
          <>
            <div className="viewDocTable_head">
              <table>
                <tbody>
                  <tr>
                    <td>Document Name</td>
                    <td>Document Information</td>
                    <td>Status</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="viewDocTable">
              <table>
                <tbody>
                  {docList &&
                    docList.map((x, index) => {
                      return (
                        <tr key={index}>
                          <td>{x.docname}</td>
                          <td>{x.docinfo}</td>
                          <td>Upload Successful</td>
                          <td>
                            <input
                              type="button"
                              value="Delete"
                              className="viewDoc_btn"
                              onClick={(e) => OnDeleteDocument(x.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="noContent">No documents available</div>
        )}
      </div>
    </>
  );
};

export default RenderDocumentList;
