import { React, Link, Header, Footer, Loader, Helper } from "../../../cIncludes";
import ChildHeadersComponent from "./cheader";
import {
  GetAllBorrowers,
  GetAllLoanOfficers,
} from "../../../services/commonApi";

import {
  Get as GetBorLOMap,
  Set as SetBorLOMap,
  SetStatus as SetBorLOMapStatus,
} from "../../../services/borlomap";

const BorrowerLOComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [borrowers, setBorrowers] = React.useState([]);
  const [loanofficers, setLoanOfficers] = React.useState([]);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);
    setRow({});
    let rslt = await GetBorLOMap();
    if (rslt.status === 100) {
      setRows(rslt.data);
    }

    rslt = await GetAllBorrowers(1);
    if (rslt.status === 100) {
      setBorrowers(rslt.data);
    }

    rslt = await GetAllLoanOfficers();
    if (rslt.status === 100) {
      setLoanOfficers(rslt.data);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnUpdateClicked = async (e) => {
    e.preventDefault();
    setError(null);
    setErrors([]);
    setProgress(true);
    let rslt = await SetBorLOMap(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
      return;
    }

    await FetchResults();
  };

  const OnDisableClicked = async (id, statusid) => {
    let data = {
      id: id,
      statusid: statusid,
    };

    setProgress(true);
    let rslt = await SetBorLOMapStatus(data);
    setProgress(false);
    if (rslt.status === 100) {
      await FetchResults();
    }
  };

  const RenderButtonControl = (x) => {
    if (x.statusid === 2) {
      return (
        <Link to="#" title="Disable" onClick={(e) => OnDisableClicked(x.id, 1)}>
          <div className="disableicon"></div>
        </Link>
      );
    } else if (x.statusid === 1) {
      return (
        <Link to="#" title="Enable" onClick={(e) => OnDisableClicked(x.id, 2)}>
          <div className="enableicon"></div>
        </Link>
      );
    }
  };

  return (
    <>
      <Loader show={progress} />
      <Header hname="mu" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_15by85">
                <ChildHeadersComponent mnu="uso" />
                <li className="col_30percent">
                  <div className="blk_container">
                    <div className="head_blk">
                      <div className="blkName">Map Borrower & Loan Officer</div>
                    </div>
                    <div className="contentarea">
                      <ul className="formColumn">
                        <li className="formColumn_I">
                          <div className="formContentHead">Loan Officers</div>
                          <div className="formMandatory">*</div>
                          <div className="userFormicon"></div>
                          <select
                            name="filterType"
                            required
                            value={Helper.ToString(row.loanofficerid)}
                            style={{
                              borderColor:
                                errors && errors.indexOf("loanofficerid") > -1
                                  ? "#ff0000"
                                  : undefined,
                            }}
                            onChange={(e) =>
                              OnInputChanged("loanofficerid", e.target.value)
                            }
                          >
                            <option value={0}>Select Loan Officer</option>
                            {loanofficers &&
                              loanofficers.map((x, index) => (
                                <option value={x.id} key={index}>
                                  {x.name}
                                </option>
                              ))}
                          </select>
                        </li>
                        <li className="formColumn_I">
                          <div className="formContentHead">Borrowers</div>
                          <div className="formMandatory">*</div>
                          <div className="userFormicon"></div>
                          <select
                            name="filterType"
                            required
                            value={Helper.ToString(row.borrowerid)}
                            style={{
                              borderColor:
                                errors && errors.indexOf("borrowerid") > -1
                                  ? "#ff0000"
                                  : undefined,
                            }}
                            onChange={(e) =>
                              OnInputChanged("borrowerid", e.target.value)
                            }
                          >
                            <option value={0}>Select Borrower</option>
                            {borrowers &&
                              borrowers.map((x, index) => (
                                <option value={x.id} key={index}>
                                  {Helper.GetStringJoin(x, [
                                    "first_name",
                                    "last_name",
                                  ])}
                                </option>
                              ))}
                          </select>
                        </li>
                      </ul>
                      <div className="form_btn_label_notop_8">
                        <input
                          type="button"
                          value="Submit"
                          className="form_btn"
                          onClick={(e) => OnUpdateClicked(e)}
                        />
                        <input
                          type="button"
                          value="Reset"
                          className="form_btn"
                          onClick={(e) => setRow({})}
                        />
                      </div>
                      {error && <div className="errormessage">{error}</div>}
                    </div>
                  </div>
                </li>
                <li className="col300_colLeft_adjust">
                  <div className="blk_container">
                    <div className="head_blk">
                      <div className="blkName">
                        Borrower & Loan Officers List
                      </div>
                    </div>
                    <div className="contentarea_table_border">
                      <div className="mappedListTable_head">
                        <table>
                          <thead>
                            <tr>
                              <td>Borrower</td>
                              <td>Loan Officer</td>
                              <td>Status</td>
                              <td>Actions</td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      {rows && rows.length > 0 ? (
                        <div className="mappedListTable">
                          <table>
                            <tbody>
                              {rows.map((x, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{x.borrower}</td>
                                    <td>{x.loanofficer}</td>
                                    <td>{x.statusname}</td>
                                    <td>{RenderButtonControl(x)}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="noContent">No data available</div>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BorrowerLOComponent;
