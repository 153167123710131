import { React, Loader, Helper } from "../../cIncludes";
import { ChangePassword } from "../../services/usersApi";

const ChangePasswordComponent = (props) => {
  const { changepws, setChangePws } = props;
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [progress, setProgress] = React.useState(false);

  React.useEffect(() => {
    setRow({});
    setError(null);
    setProgress(false);
    setErrors([]);
  }, [changepws]);

  const OnCloseClicked = (e) => {
    setChangePws(false);
  };

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
    setErrors([]);
    setError(null);
  };

  const OnSubmitClicked = async (e) => {
    setError(null);
    setProgress(true);
    setErrors([]);
    let rslt = await ChangePassword(row);
    setProgress(false);
    if (rslt.status !== 100) {
      console.log(rslt.errors);
      setError(rslt.statusText);
      setErrors(rslt.errors);
      return;
    }
    setError(rslt.statusText);
  };

  return (
    <>
      <Loader show={progress} />
      {changepws ? (
        <div
          id="popChangePswd"
          className="pop_disable_bg"
          style={{ display: "block" }}
        >
          <div className="changepswd">
            <div className="pop_head">Change Password</div>
            <div className="pop_contentArea">
              <ul className="pop_formCol">
                <li className="pop_1col">
                  <div className="pop_titleHead">Old Password</div>
                  <div className="pop_mandatory">*</div>
                  <input
                    type="password"
                    value={Helper.ToString(row.opws)}
                    onChange={(e) => OnInputChanged("opws", e.target.value)}
                    style={{
                      borderColor:
                        errors && errors.indexOf("opws") > -1
                          ? "#ff0000"
                          : undefined,
                    }}
                  />
                </li>
                <li className="pop_1col">
                  <div className="pop_titleHead">New Password</div>
                  <div className="pop_mandatory">*</div>
                  <input
                    type="password"
                    value={Helper.ToString(row.npws)}
                    onChange={(e) => OnInputChanged("npws", e.target.value)}
                    style={{
                      borderColor:
                        errors && errors.indexOf("npws") > -1
                          ? "#ff0000"
                          : undefined,
                    }}
                  />
                </li>
                <li className="pop_1col">
                  <div className="pop_titleHead">Confirm New Password</div>
                  <div className="pop_mandatory">*</div>
                  <input
                    type="password"
                    value={Helper.ToString(row.cpws)}
                    onChange={(e) => OnInputChanged("cpws", e.target.value)}
                    style={{
                      borderColor:
                        errors && errors.indexOf("cpws") > -1
                          ? "#ff0000"
                          : undefined,
                    }}
                  />
                </li>
              </ul>
              <div className="pop_buttonLabel_noTop">
                <input
                  type="button"
                  value="Update"
                  className="pop_button"
                  onClick={(e) => OnSubmitClicked(e)}
                />
                <input
                  type="button"
                  value="Close"
                  className="pop_button"
                  onClick={(e) => OnCloseClicked(e)}
                />
              </div>
              {error && <div className="errorMsg">{error}</div>}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChangePasswordComponent;
