import API from "./api";
import session from "../session";

const Get = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get("borlomap", {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Set = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post("borlomap", input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetStatus = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`borlomapstate`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetLoanLolist = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get("loanlomap", {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetLoanLo = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post("loanlomap", input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetLoanLoStatus = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`loanlomapstate`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export { Get, Set, SetStatus, GetLoanLolist, SetLoanLo, SetLoanLoStatus };
