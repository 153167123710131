import { ForgotPassword } from "../../services/usersApi";

import { React, Helper, Loader } from "../../cIncludes";

const ForgotPasswordComponent = (props) => {
  const { forgotpws, setForGotPws } = props;
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [row, setRow] = React.useState({});
  const [progress, setProgress] = React.useState(false);

  React.useEffect(() => {
    setRow({});
    setError(null);
    setSuccess(null);
    setProgress(false);
  }, [forgotpws]);

  const OnCloseClicked = (e) => {
    setForGotPws(false);
  };

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnSubmitClicked = async (e) => {
    setError(null);
    setProgress(true);
    setSuccess(null);
    let rslt = await ForgotPassword(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      return;
    }
    setSuccess(rslt.statusText);
  };

  return (
    <>
      <Loader show={progress} />
      {forgotpws ? (
        <div
          id="popFgtPswd"
          className="pop_disable_bg"
          style={{ display: "block" }}
        >
          <div className="forgotpswd">
            <div className="pop_head">Forgot Password</div>
            <div className="pop_contentArea">
              <div className="pop_textCenter">
                Please provide your email id to receive a new password
                <div className="chgpswd">
                  <input
                    type="text"
                    value={Helper.ToString(row.email)}
                    onChange={(e) => OnInputChanged("email", e.target.value)}
                  />
                </div>
              </div>
              <div className="pop_buttonLabel_noTop">
                <input
                  type="button"
                  value="Submit"
                  className="pop_button"
                  onClick={(e) => OnSubmitClicked(e)}
                />
                <input
                  type="button"
                  value="Close"
                  className="pop_button"
                  onClick={(e) => OnCloseClicked(e)}
                />
              </div>
              {error && <div className="errormessage_center">{error}</div>}
              {success && (
                <div className="successmessage_center">
                  You will receive an email with your new password
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ForgotPasswordComponent;
