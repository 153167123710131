import { React, Header, Footer } from "../../../cIncludes";
import RenderAcctDetailsComponent from "./RenderAcctDetailsComponent";
import RenderProfDetailsComponent from "./RenderProfDetailsComponent";

const ProfileComponent = () => {
  return (
    <>
      <Header hname="up" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_20by80">
                <RenderAcctDetailsComponent />
                <RenderProfDetailsComponent />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfileComponent;
