import { React, Header, Footer } from "../../../cIncludes";
const DashBoardComponent = () => {
  return (
    <>
      <Header hname="ld" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <div className="loanSummary">
                <ul className="dashboard_summaryblk">
                  <li className="dashboard_summaryblk">
                    <div className="txtHead">400,000</div>
                    <div className="txtNormal">My Pipeline</div>
                  </li>
                  <li className="dashboard_summaryblk">
                    <div className="txtHead">2000</div>
                    <div className="txtNormal">My Earnings</div>
                  </li>
                </ul>
              </div>
              <ul className="dashboard_contentblk">
                <li className="dashboard_contentblk_lo_share">
                  <div className="txtHead">Access &amp; Share Documents</div>
                  <div className="txtDesc">
                    100% online, 24/7 availability and secured by Blockchain.
                  </div>
                </li>
                <li className="dashboard_contentblk_lo_mortgage">
                  <div className="txtHead">Mortgage Tools</div>
                  <div className="txtDesc">Take control of your payments.</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DashBoardComponent;
