import { React, Loader, Helper } from "../../../cIncludes";

import {
  GetLoanById as GetLoan,
  Set as SetLoans,
} from "../../../services/loanApi";

import {
  GetLenders,
  GetEthnicities,
  GetRaces,
  GetLoanTypes,
} from "../../../services/commonApi";

const RenderUpdateLoanComponent = ({ loanid, showmore, onLoanUpdated }) => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [lenders, setLenders] = React.useState([]);
  const [races, setRaces] = React.useState([]);
  const [ethnicities, setEthnicities] = React.useState([]);
  const [loantypes, setLoanTypes] = React.useState([]);

  const FetchResults = async () => {
    setProgress(true);
    let rslt = await GetLoan(loanid);
    if (rslt.status === 100) {
      //console.log(rslt.data);
      setRow(rslt.data);
    }
    rslt = await GetLenders();
    if (rslt.status === 100) {
      //console.log(rslt.data);
      setLenders(rslt.data);
    }

    rslt = await GetRaces();
    if (rslt.status === 100) {
      setRaces(rslt.data);
    }

    rslt = await GetEthnicities();
    if (rslt.status === 100) {
      setEthnicities(rslt.data);
    }

    rslt = await GetLoanTypes();
    if (rslt.status === 100) {
      setLoanTypes(rslt.data);
    }
    setProgress(false);
  };

  React.useEffect(() => {
    if (loanid > 0 && showmore) {
      setInitlized(true);
    }
  }, [loanid, showmore]);

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnUpdateLoanClicked = async (e) => {
    setProgress(true);
    setError(null);
    setErrors([]);
    setSuccess(null);
    e.preventDefault();
    let rslt = await SetLoans(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
      setErrors(rslt.errors);
    } else {
      setSuccess("Loan updated successful.");
    }
  };

  const OnCloseClicked = async (e) => {
    setError(null);
    setErrors([]);
    setSuccess(null);
    if (onLoanUpdated) onLoanUpdated(row);
  };

  const OnLenderSelect = (index) => {
    OnInputChanged("lenderindex", index);
    let _lender = lenders[index];
    if (_lender.id > 0) {
      OnInputChanged("lenderindexid", _lender.typeindex);
      OnInputChanged("lender_id", _lender.ispid);
    }
  };

  const GetLenderIndex = (item) => {
    let _lender = 0;
    if (item) {
      _lender = lenders.findIndex(
        (x) =>
          x.ispid === item.lender_id &&
          parseInt(x.typeindex) === parseInt(item.lenderindexid)
      );
    }
    return _lender;
  };

  if (!showmore) return null;

  return (
    <>
      <Loader show={progress} />
      <div
        id="popUpdateLoan"
        className="pop_disable_bg"
        style={{ display: "block" }}
      >
        <div className="updateLoan">
          <div className="pop_head">Update Loan Details</div>
          <div className="pop_contentArea" style={{ maxHeight: "fit-content" }}>
            <ul className="pop_formCol_height250">
              <li className="pop_2col">
                <div className="pop_titleHead">Loan Purpose</div>
                <div className="pop_mandatory">*</div>
                <select
                  name="filterType"
                  required
                  value={Helper.ToString(row.purpose)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("purpose") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("purpose", e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="Purchase">Purchase</option>
                  <option value="Refinance">Refinance</option>
                </select>
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Loan Type</div>
                <div className="pop_mandatory">*</div>
                <select
                  name="filterType"
                  required
                  value={Helper.ToString(row.loantype)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("loantype") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("loantype", e.target.value)}
                >
                  <option value="">Select</option>
                  {loantypes &&
                    loantypes.map((x, index) => {
                      return (
                        <option value={x.name} key={index}>
                          {x.name}
                        </option>
                      );
                    })}
                </select>
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Borrower</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.borrower)}
                  readOnly={true}
                  style={{
                    borderColor:
                      errors && errors.indexOf("borrower") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("borrower", e.target.value)}
                  onKeyPress={(e) => {
                    return Helper.AcceptedChars(e, "TEXT");
                  }}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Lender / Investor</div>
                <div className="pop_mandatory">*</div>
                <select
                  name="filterType"
                  required
                  disabled
                  value={GetLenderIndex(row)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("lenderindex") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnLenderSelect(e.target.selectedIndex)}
                >
                  {lenders &&
                    lenders.map((x, index) => {
                      return (
                        <option value={index} key={index}>
                          {x.name}
                        </option>
                      );
                    })}
                </select>
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Loan Amount</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.loanamount)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("loanamount") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("loanamount", e.target.value)}
                  onKeyPress={(e) => {
                    return Helper.AcceptedChars(e, "FINANCE");
                  }}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Interest Rate</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.interestrate)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("interestrate") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) =>
                    OnInputChanged("interestrate", e.target.value)
                  }
                  onKeyPress={(e) => {
                    return Helper.AcceptedChars(e, "FINANCE");
                  }}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Issuing Authority</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.issuingauth)}
                  readOnly
                  style={{
                    borderColor:
                      errors && errors.indexOf("issuingauth") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) =>
                    OnInputChanged("issuingauth", e.target.value)
                  }
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Closing Date</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.closingdate)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("closingdate") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) =>
                    OnInputChanged("closingdate", e.target.value)
                  }
                  onKeyPress={(e) => {
                    return Helper.AcceptedChars(e, "DATE");
                  }}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">FICO Score</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.ficoscore)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("ficoscore") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("ficoscore", e.target.value)}
                  onKeyPress={(e) => {
                    return Helper.AcceptedChars(e, "FINANCE");
                  }}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">LTV (Loan-to-Value in %)</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.ltv)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("ltv") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("ltv", e.target.value)}
                  onKeyPress={(e) => {
                    return Helper.AcceptedChars(e, "FINANCE");
                  }}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">DTI (Debt-to-Income in %)</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.dti)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("dti") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("dti", e.target.value)}
                  onKeyPress={(e) => {
                    return Helper.AcceptedChars(e, "FINANCE");
                  }}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Tenure (In Months)</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.tenure)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("tenure") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("tenure", e.target.value)}
                  onKeyPress={(e) => {
                    return Helper.AcceptedChars(e, "NUMBER");
                  }}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Ethnicity</div>
                <div className="pop_mandatory">*</div>
                <select
                  name="filterType"
                  required
                  value={Helper.ToString(row.ethnicity)}
                  disabled
                  style={{
                    borderColor:
                      errors && errors.indexOf("ethnicity") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("ethnicity", e.target.value)}
                >
                  <option value="">Select</option>
                  {ethnicities &&
                    ethnicities.map((x, index) => {
                      return (
                        <option value={x.name} key={index}>
                          {x.name}
                        </option>
                      );
                    })}
                </select>
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Gender</div>
                <div className="pop_mandatory">*</div>
                <select
                  name="filterType"
                  required
                  disabled
                  value={Helper.ToString(row.gender)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("gender") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("gender", e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Income (Monthly)</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  value={Helper.ToString(row.income)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("income") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("income", e.target.value)}
                  onKeyPress={(e) => {
                    return Helper.AcceptedChars(e, "FINANCE");
                  }}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Race</div>
                <div className="pop_mandatory">*</div>
                <select
                  name="filterType"
                  required
                  disabled
                  value={Helper.ToString(row.race)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("race") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("race", e.target.value)}
                >
                  <option value="">Select</option>
                  {races &&
                    races.map((x, index) => {
                      return (
                        <option value={x.name} key={index}>
                          {x.name}
                        </option>
                      );
                    })}
                </select>
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Parcel Id</div>
                <input
                  type="text"
                  value={Helper.ToString(row.parcelid)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("parcelid") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) => OnInputChanged("parcelid", e.target.value)}
                />
              </li>
              <li className="pop_2col">
                <div className="pop_titleHead">Funding Complete</div>
                <div className="pop_mandatory">*</div>
                <div className="pop_genericBox">
                  <ul className="pop_formCol">
                    <li className="pop_col_inline">
                      <div className="checkRadio_Lable">
                        <input
                          type="radio"
                          id={`${row.fundingcomplete}_1`}
                          name={`${row.fundingcomplete}`}
                          onChange={(e) => OnInputChanged("fundingcomplete", 1)}
                          checked={Helper.ToString(row.fundingcomplete) === "1"}
                        />
                        <label htmlFor={`${row.fundingcomplete}_1`}>Yes</label>
                      </div>
                    </li>
                    <li className="pop_col_inline">
                      <div className="checkRadio_Lable">
                        <input
                          type="radio"
                          id={`${row.fundingcomplete}_2`}
                          name={`${row.fundingcomplete}`}
                          onChange={(e) => OnInputChanged("fundingcomplete", 0)}
                          checked={Helper.ToString(row.fundingcomplete) === "0"}
                        />
                        <label htmlFor={`${row.fundingcomplete}_2`}>No</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="pop_1col">
                <div className="pop_titleHead">Property Address</div>
                <div className="pop_mandatory">*</div>
                <input
                  type="text"
                  readOnly
                  value={Helper.ToString(row.propaddress)}
                  style={{
                    borderColor:
                      errors && errors.indexOf("propaddress") > -1
                        ? "#ff0000"
                        : undefined,
                  }}
                  onChange={(e) =>
                    OnInputChanged("propaddress", e.target.value)
                  }
                />
              </li>
            </ul>
            <div className="pop_buttonLabel_noTop">
              <input
                type="button"
                value="Update Loan"
                className="pop_button"
                onClick={(e) => OnUpdateLoanClicked(e)}
              />
              <input
                type="button"
                value="Close"
                className="pop_button"
                onClick={(e) => OnCloseClicked(e)}
              />
            </div>
            {error && <div className="errormessage">{error}</div>}
            {success && <div className="successmessage">{success}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderUpdateLoanComponent;
