import { Link } from "react-router-dom";

const MsgAlertComponent = () => {
  return (
    <>
      <div className="alertLayout">
        <Link
          to="#"
          className="alert_Close"
          onClick={() => window.OnAlertShow(1)}
        ></Link>
        <div className="alertContentArea">
          <div className="alertHead">Notifications</div>
        </div>
      </div>
    </>
  );
};

export default MsgAlertComponent;
