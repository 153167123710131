import { React, Link, Loader, Helper } from "../../../../cIncludes";
import { GetStakeHolders } from "../../../../services/usersApi";
import { GetShortByLoan } from "../../../../services/entitiesApi";
import {
  Get as GetDocAccessList,
  Add as AddAccessList,
  Delete as DeleteAccessList,
} from "../../../../services/docaccessApi";
import RolesClass from "../../../../helper/roles";

const RenderStakeHolders = ({ typeid, loanId, docCount, checklist, onCheckListCompleted }) => {
  const [initlized, setInitlized] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [checkAccessList, setCheckAccessList] = React.useState(false);
  const [row, setRow] = React.useState({ loanid: loanId, typeid: typeid });
  const [personaslist, setPersonasList] = React.useState([]);
  const [stakeholders, setStakeHolders] = React.useState([]);
  const [entities, setEntities] = React.useState([]);
  const [accessList, setAccessList] = React.useState({ childs: [] });
  const [error, setError] = React.useState(null);
  let RolesObj = new RolesClass();

  React.useEffect(() => {
    setInitlized(true);
  }, [typeid]);

  React.useEffect(() => {
    console.log(`Check List:${checklist}`);
    if (checklist && !checkAccessList) setCheckAccessList(true);
  }, [checklist, checkAccessList]);

  React.useEffect(() => {
    if (checkAccessList) {
      setCheckAccessList(false);
      const fn = async () => {
        setError(null);
        let items = accessList.childs || [];
        /* items.forEach(elm => {
          elm['typeid'] = typeid;
          elm['loanid'] = loanId;
        }); */
        let statusText = null;
        setProgress(true);
        let rslt = await AddAccessList(items);
        setProgress(false);
        if (rslt.status !== 100) {
          statusText = rslt.statusText;
        } else {
          items = { childs: rslt.data };
          setAccessList(items);
        }
        if (onCheckListCompleted) {
          setTimeout(() => { onCheckListCompleted(rslt.status === 100, statusText); }, 100);
        }
      };
      fn();

    }
  }, [checkAccessList, accessList, onCheckListCompleted]);

  const FetchResults = async () => {
    setProgress(true);
    setError(null);
    setPersonasList([{ personaid: "0", first_name: "Select" }]);
    let rslt = await GetStakeHolders(loanId);
    if (rslt.status === 100) {
      setStakeHolders(rslt.data);
    }
    rslt = await GetShortByLoan(loanId);
    if (rslt.status === 100) {
      setEntities(rslt.data);
    }
    setProgress(false);
    await LoadAccessList();
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  /* const fnCall = async (item) => {
    setProgress(true);
    let rslt = await AddAccessList(item);
    setProgress(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
    }
  };

  const FirstAccessUpdate = async (reload) => {
    let i = 1;
    let list = (accessList && accessList.childs) || [];
    list.forEach(async (item) => {
      let tmp = { skip: true };
      for (var key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          if (key === "read" || key === "write") {
            tmp[key] = parseInt(item[key]) === 1 ? true : false;
          } else {
            tmp[key] = item[key];
          }
        }
      }
      if (list.length > 0) {
        await fnCall(tmp);
        if (list.length === i && reload) {
          await LoadAccessList();
        }
      }
      i++;
    });
  }

  if (firstaccess) {
    setFirstAccess(false);
    if (accessList && accessList.childs && accessList.childs.length > 0) FirstAccessUpdate(true);
  } */

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const LoadAccessList = async () => {
    setProgress(true);
    let rslt = await GetDocAccessList(loanId, typeid);
    if (rslt.status === 100) {
      setAccessList({ childs: rslt.data });
    }
    setProgress(false);
  };

  const OnUpdateAccessList = (e) => {
    setError(null);
    if (!row['partytype'] || parseInt(row['partytype']) === 0) {
      setError("Select Party Type");
      return;
    }
    if (parseInt(row['partytype']) === 1) {
      if (!(row.read && row.write)) {
        setError("Select Read and Write for FP");
        return;
      }
    } else if (parseInt(row['partytype']) === 2 || parseInt(row['partytype']) === 3) {
      if (!row.read && !row.write) {
        setError("Select Read or Write for SP/WP");
        return;
      }
    }

    let list = accessList.childs || [];
    let index = list.findIndex((x) => parseInt(x.entityid) === parseInt(row.entityid) &&
      parseInt(x.userid) === parseInt(row.userid));
    if (index > -1) {
      setError("Already exists");
      return;
    }
    row['id'] = 0;

    if (Helper.ToInteger(row?.entityid, 0) < 1) {
      setError("Select Entity");
      return;
    }

    if (Helper.ToInteger(row?.userid, 0) < 1) {
      setError("Select Persona");
      return;
    }


    let _name = entities.filter((x) => parseInt(x.id) === parseInt(row.entityid));
    row['display_name'] = _name[0].display_name;

    _name = personaslist.filter((x) => parseInt(x.personaid) === parseInt(row.userid));
    row['first_name'] = _name[0].first_name;
    row['last_name'] = _name[0].last_name;
    row.write = row.write ? 1 : 0;
    row.read = row.read ? 1 : 0;
    list.push(row);
    setAccessList({ childs: list });
    setRow({ loanid: loanId, typeid: typeid });
    /* setError(null);
    setProgress(true);
    let rslt = await AddAccessList(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setError(rslt.statusText);
    } else {
      await LoadAccessList();
      setRow([{ loanid: loanId, typeid: typeid }]);
    } */
  };

  const OnDummyChanged = (e) => { };

  const OnDeleteClicked = async (e, index) => {
    setError(null);
    if (parseInt(e) === 0) {
      let _list = accessList.childs;
      _list.splice(index, 1);
      setAccessList({ childs: _list });
    } else {
      setProgress(true);
      let rslt = await DeleteAccessList(e);
      setProgress(false);
      if (rslt.status !== 100) {
        setError(rslt.statusText);
      } else {
        let _list = accessList.childs;
        _list.splice(index, 1);
        setAccessList({ childs: _list });
      }
    }
  };

  const DisplayPartyName = (e) => {
    if (parseInt(e) === 1) return "FP";
    if (parseInt(e) === 2) return "SP";
    if (parseInt(e) === 3) return "W";
    return "";
  };

  const OnEntitySelected = (e) => {
    OnInputChanged('entityid', e);
    OnInputChanged('userid', 0);
    let tmp = stakeholders.filter((x) => parseInt(x.entityid) === parseInt(e)) || [];
    tmp.splice(0, 0, { personaid: "0", first_name: "Select" });
    setPersonasList(tmp);
  }

  const OnPersonaSelected = (e) => {
    OnInputChanged('userid', e);
    OnInputChanged("read", false);
    OnInputChanged("write", false);
    OnInputChanged("id", 0);
    OnInputChanged("typeid", typeid);
    OnInputChanged("loanid", loanId);
  }

  return (
    <>
      <Loader show={progress} />
      <div className="permission_stakeholder">
        <ul className="formColumn">
          {RolesObj.IsUploadDocAccessEdit && (
            <li className="formColumn_II">
              <div className="head_blk_IIlevel">
                <div className="IIlevel_ContentHead">
                  Add Access List for the above Documents
                </div>
              </div>
              <div className="contentarea_stakeholder_doc">
                <ul className="formColumn">
                  <li className="formColumn_II">
                    <div className="formContentHead">Select User</div>
                    <div className="formMandatory">*</div>
                    <div className="entityFormicon"></div>
                    <select
                      name="filterStatus"
                      required
                      value={Helper.ToString(row.entityid)}
                      onChange={(e) => OnEntitySelected(e.target.value)}
                      disabled={
                        Helper.ToInteger(loanId) < 1 ? "disabled" : undefined
                      }
                    >
                      {entities &&
                        entities.map((x, index) => {
                          return (
                            <option value={x.id} key={index}>
                              {x.display_name}
                            </option>
                          );
                        })}
                    </select>
                  </li>
                  <li className="formColumn_II">
                    <div className="formContentHead">Select Entity</div>
                    <div className="formMandatory">*</div>
                    <div className="personaFormicon"></div>
                    <select
                      name="filterStatus"
                      required
                      value={Helper.ToString(row.userid)}
                      onChange={(e) => OnPersonaSelected(e.target.value)}
                      disabled={
                        Helper.ToInteger(loanId) < 1 ? "disabled" : undefined
                      }
                    >
                      {personaslist &&
                        personaslist.map((x, index) => {
                          return (
                            <option value={x.personaid} key={index}>
                              {Helper.GetStringJoin(x, [
                                "first_name",
                                "last_name",
                              ])}
                            </option>
                          );
                        })}
                    </select>
                  </li>
                  <li className="formColumn_II">
                    <div className="formContentHead">Select Type</div>
                    <div className="formMandatory">*</div>
                    <div className="typeFormicon"></div>
                    <select
                      name="filterType"
                      required
                      value={Helper.ToString(row.partytype)}
                      onChange={(e) =>
                        OnInputChanged("partytype", e.target.value)
                      }
                    >
                      <option value="0">Select</option>
                      <option value="1">FP - First Party</option>
                      <option value="2">SP - Second Party</option>
                      <option value="3">W - Witness</option>
                    </select>
                  </li>
                  <li className="formColumn_II">
                    <div className="formContentHead">Permission</div>
                    <div className="formMandatory">*</div>
                    <ul className="formColumn">
                      <li className="formColumn_inline">
                        <div className="checkRadio_Lable">
                          <input
                            type="checkbox"
                            id="rp1"
                            checked={row.read || false}
                            onChange={(e) => OnDummyChanged()}
                            onClick={(e) =>
                              OnInputChanged("read", e.target.checked)
                            }
                          />
                          <label htmlFor="rp1">Read</label>
                        </div>
                      </li>
                      <li className="formColumn_inline">
                        <div className="checkRadio_Lable">
                          <input
                            type="checkbox"
                            id="wp1"
                            checked={row.write || false}
                            onChange={(e) => OnDummyChanged()}
                            onClick={(e) =>
                              OnInputChanged("write", e.target.checked)
                            }
                          />
                          <label htmlFor="wp1">Write</label>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div className="form_btn_label">
                  <input
                    type="button"
                    value="Permit"
                    className="form_btn"
                    onClick={(e) => OnUpdateAccessList(e)}
                  />
                </div>
                {error && <div className="errormessage">{error}</div>}
              </div>
            </li>
          )}
          <li
            className={
              RolesObj.IsUploadDocAccessEdit ? "formColumn_II" : "formColumn_I"
            }
          >
            <div className="head_blk_IIlevel">
              <div className="IIlevel_ContentHead">Access List</div>
            </div>
            <div className="contentarea_table_border">
              {accessList && accessList.childs && accessList.childs.length > 0 ? (
                <>
                  <div className="permissionTable_head">
                    <table>
                      <tbody>
                        <tr>
                          <td>Entity</td>
                          <td>User</td>
                          <td>Type</td>
                          <td>Read</td>
                          <td>Write</td>
                          <td>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="permissionTable">
                    <table>
                      <tbody>
                        {accessList && accessList.childs &&
                          accessList.childs.map((x, index) => {
                            return (
                              <tr key={index}>
                                <td>{x.display_name}</td>
                                <td>
                                  {Helper.GetStringJoin(x, [
                                    "first_name",
                                    "last_name",
                                  ])}
                                </td>
                                <td>{DisplayPartyName(x.partytype)}</td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="rw1"
                                    readOnly
                                    checked={x.read === 1}
                                    onChange={(e) => OnDummyChanged()}
                                  />
                                  <label htmlFor="rw1"></label>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="rw2"
                                    readOnly
                                    checked={x.write === 1}
                                    onChange={(e) => OnDummyChanged()}
                                  />
                                  <label htmlFor="rw2"></label>
                                </td>
                                {RolesObj.IsUploadDocAccessEdit ? (
                                  <td>
                                    <Link
                                      to="#"
                                      title="Delete"
                                      onClick={() => OnDeleteClicked(x.id, index)}
                                    >
                                      <div className="deleteStakeholdericon"></div>
                                    </Link>
                                  </td>
                                ) : (
                                  <td>&nbsp;</td>
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className="noContent">No stakeholders available</div>
              )}
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default RenderStakeHolders;
