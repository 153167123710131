import { React, Link, Header, Footer, Loader, Helper } from "../../../cIncludes";
import ChildHeadersComponent from "./cheader";
import { GetAllLoans, GetAllLoanOfficers } from "../../../services/commonApi";
import { SetLoanLo, SetLoanLoStatus, GetLoanLolist } from "../../../services/borlomap";

const MapLotoLoan = () => {
    const [progress, setProgress] = React.useState(false);
    const [initlized, setInitlized] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [row, setRow] = React.useState({});
    const [error, setError] = React.useState(null);
    const [selectedId, setSelectedId] = React.useState(null);
    const [errors, setErrors] = React.useState([]);
    const [loans, setLoans] = React.useState([]);
    const [loanofficers, setLoanOfficers] = React.useState([]);

    const [borrower, setBorrower] = React.useState({});

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const FetchResults = async () => {
        setProgress(true);
        setRow({});
        setLoans([]);
        let rslt = await GetLoanLolist();
        if (rslt.status === 100) {
            setRows(rslt.data);
        }

        rslt = await GetAllLoans();
        if (rslt.status === 100) {
            let tmp = rslt.data;
            /* 
            // Sorting results by loan number
            let sortedList = tmp.map((x) => x.loannumber).sort();
            let tmp2 = [];
            sortedList.map((x) => {
                tmp2.push(tmp.filter((m) => m.loannumber === x)[0]);
            }); */
            setLoans(tmp);
        }

        rslt = await GetAllLoanOfficers();
        if (rslt.status === 100) {
            setLoanOfficers(rslt.data);
        }
        setProgress(false);
    };

    if (initlized) {
        setInitlized(false);
        setBorrower({});
        setLoanOfficers([]);
        setRows([]);
        setSelectedId(null);
        FetchResults();
    }

    const UpdateValues = (name, val) => {
        setRow((prev) => ({
            ...prev,
            [name]: val,
        }));
    }

    const OnInputChanged = (name, val) => {
        if (name === 'loanid') {
            setBorrower({});
            let _borrower = loans.filter((x) => x?._id === val);
            if (_borrower && _borrower.length > 0) {
                let tmp = _borrower[0]?.borrower;
                setBorrower(tmp);
                UpdateValues('loanid', null);
                UpdateValues('loannumber', _borrower[0].loannumber);
                UpdateValues('formid', val);
            } else {
                _borrower = loans.filter((x) => parseInt(x.id) === parseInt(val));
                if (_borrower && _borrower.length > 0) {
                    let tmp = _borrower[0]?.borrower;
                    if (tmp) tmp.propaddress = _borrower[0].propaddress;
                    setBorrower(tmp);
                    UpdateValues('formid', null);
                    UpdateValues('loanid', val);
                    UpdateValues('loannumber', _borrower[0].loannumber);
                }
            }
            setSelectedId(val);
        } else {
            UpdateValues(name, val);
        }
    };

    const OnUpdateClicked = async (e) => {
        e.preventDefault();
        setError(null);
        setErrors([]);
        setProgress(true);
        let rslt = await SetLoanLo(row);
        setProgress(false);
        if (rslt.status !== 100) {
            setErrors(rslt.errors);
            setError(rslt.statusText);
            return;
        }

        setInitlized(true);
    };

    const OnDisableClicked = async (id, statusid) => {
        let data = {
            id: id,
            statusid: statusid,
        };

        setProgress(true);
        let rslt = await SetLoanLoStatus(data);
        setProgress(false);
        if (rslt.status === 100) {
            await FetchResults();
        }
    };

    const RenderButtonControl = (x) => {
        if (x.statusid === 2) {
            return (
                <Link to="#" title="Disable" onClick={(e) => OnDisableClicked(x.id, 1)}>
                    <div className="disableicon"></div>
                </Link>
            );
        } else if (x.statusid === 1) {
            return (
                <Link to="#" title="Enable" onClick={(e) => OnDisableClicked(x.id, 2)}>
                    <div className="enableicon"></div>
                </Link>
            );
        }
    };

    const LoanDisplayFormat = (type, number) => {
        if (type) return `P - ${number}`;
        return `L - ${number}`;
    }

    return (
        <>
            <Loader show={progress} />
            <Header hname="mu" />
            <div id="container">
                <div id="content">
                    <div className="widthfix_10px">
                        <div id="pgstructure">
                            <ul className="col_15by85">
                                <ChildHeadersComponent mnu="usm" />
                                <li className="col_30percent">
                                    <div className="blk_container">
                                        <div className="head_blk">
                                            <div className="blkName">Map Loan Officer & Loan</div>
                                        </div>
                                        <div className="contentarea">
                                            <ul className="formColumn">
                                                <li className="formColumn_I">
                                                    <div className="formContentHead">Loan Numbers</div>
                                                    <div className="formMandatory">*</div>
                                                    <div className="userFormicon"></div>
                                                    <select
                                                        name="filterType"
                                                        required
                                                        value={Helper.ToString(selectedId)}
                                                        style={{
                                                            borderColor:
                                                                errors && errors.indexOf("loanid") > -1
                                                                    ? "#ff0000"
                                                                    : undefined,
                                                        }}
                                                        onChange={(e) =>
                                                            OnInputChanged("loanid", e.target.value)
                                                        }
                                                    >
                                                        <option value={0}>Select Loan</option>
                                                        {loans &&
                                                            loans.map((x, index) => {
                                                                return (
                                                                    x.id ? <option value={x.id} key={index}>
                                                                        {LoanDisplayFormat(x.type, x.loannumber)}
                                                                    </option> : <option value={x._id} key={index}>
                                                                        {LoanDisplayFormat(x.type, x.loannumber)}
                                                                    </option>
                                                                )
                                                            })}
                                                    </select>
                                                </li>
                                                {!Helper.IsJSONEmpty(borrower) && (
                                                    <li className="formColumn_I">
                                                        <div className="genericTextBox">
                                                            <div className="boxContentHead">Name:</div>
                                                            <div className="boxContent">{Helper.GetStringJoin(borrower, ['first_name', 'last_name'])}</div>
                                                            <div className="boxContentHead">Email:</div>
                                                            <div className="boxContent">{borrower?.email}</div>
                                                            <div className="boxContentHead">Property Address:</div>
                                                            <div className="boxContent">{borrower?.propaddress}</div>
                                                        </div>
                                                    </li>
                                                )}
                                                <li className="formColumn_I">
                                                    <div className="formContentHead">Loan Officers</div>
                                                    <div className="formMandatory">*</div>
                                                    <div className="userFormicon"></div>
                                                    <select
                                                        name="filterType"
                                                        required
                                                        value={Helper.ToString(row.userid)}
                                                        style={{
                                                            borderColor:
                                                                errors && errors.indexOf("userid") > -1
                                                                    ? "#ff0000"
                                                                    : undefined,
                                                        }}
                                                        onChange={(e) =>
                                                            OnInputChanged("userid", e.target.value)
                                                        }
                                                    >
                                                        <option value={0}>Select Loan Officer</option>
                                                        {loanofficers &&
                                                            loanofficers.map((x, index) => (
                                                                <option value={x.userid} key={index}>
                                                                    {Helper.GetStringJoin(x, ['name', 'state'])}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </li>
                                            </ul>
                                            <div className="form_btn_label_notop_8">
                                                <input
                                                    type="button"
                                                    value="Submit"
                                                    className="form_btn"
                                                    onClick={(e) => OnUpdateClicked(e)}
                                                />
                                                <input
                                                    type="button"
                                                    value="Reset"
                                                    className="form_btn"
                                                    onClick={(e) => setRow({})}
                                                />
                                            </div>
                                            {error && <div className="errormessage">{error}</div>}
                                        </div>
                                    </div>
                                </li>
                                <li className="col300_colLeft_adjust">
                                    <div className="blk_container">
                                        <div className="head_blk">
                                            <div className="blkName">
                                                Loan & Loan Officers List
                                            </div>
                                        </div>
                                        <div className="contentarea_table_border">
                                            <div className="mappedListTable_head">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td>Loan Number</td>
                                                            <td>Loan Officer</td>
                                                            <td>Status</td>
                                                            <td>Actions</td>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            {rows && rows.length > 0 ? (
                                                <div className="mappedListTable">
                                                    <table>
                                                        <tbody>
                                                            {rows.map((x, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{LoanDisplayFormat(x.formid, x.loannumber)}</td>
                                                                        <td>{x.loanofficer}</td>
                                                                        <td>{x.statusname}</td>
                                                                        <td>{RenderButtonControl(x)}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : (
                                                <div className="noContent">No data available</div>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default MapLotoLoan;
