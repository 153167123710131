import Session from "../session";

const ROLES = {
  PROFILESEDIT: 1,
  COMPANYPROFILESEDIT: 2,
  UPLOADDOCUMENT: 3,
  UPLOADDOCACCESSVIEW: 4,
  UPLOADDOCACCESSEDIT: 5,
};

const OPTIONS = {
  LOANSELECTIONTYPE: 1,
  LOANDEFAULTSELECTION: 2,
};

function Roles() {
  const GetRoleStatus = (key) => {
    let roles = Session.RetrieveJSON2("roles");
    if (roles === undefined || roles === null || roles.length === 0)
      return false;
    let rows = roles.filter((x) => parseInt(x.id) === parseInt(key));
    if (rows && rows.length > 0) {
      return parseInt(rows[0].statusid) === 1;
    }
    return false;
  };

  const GetOptionStatus = (key, defa, bbool) => {
    let roles = Session.RetrieveJSON2("options");
    if (roles === undefined || roles === null || roles.length === 0)
      return defa;
    let rows = roles.filter((x) => parseInt(x.id) === parseInt(key));
    if (rows && rows.length > 0) {
      if (bbool) return parseInt(rows[0].statusid) > 0;
      return parseInt(rows[0].statusid);
    }
    return defa;
  };

  var fn = {};

  fn.IsProfileEdit = GetRoleStatus(ROLES.PROFILESEDIT);
  fn.IsCompanyProfileEdit = GetRoleStatus(ROLES.COMPANYPROFILESEDIT);
  fn.IsUploadDocument = GetRoleStatus(ROLES.UPLOADDOCUMENT);
  fn.IsUploadDocAccessView = GetRoleStatus(ROLES.UPLOADDOCACCESSVIEW);
  fn.IsUploadDocAccessEdit = GetRoleStatus(ROLES.UPLOADDOCACCESSEDIT);

  fn.LoanSelectionType = GetOptionStatus(OPTIONS.LOANSELECTIONTYPE, 1);
  fn.LoanDefaultSelection = GetOptionStatus(
    OPTIONS.LOANDEFAULTSELECTION,
    false,
    true
  );

  return fn;
}

export default Roles;
