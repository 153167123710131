import { React, Header, Footer } from "../../../cIncludes";

const ProfileComponent = () => {
  return (
    <>
      <Header hname="ud" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">User Dash Board</div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfileComponent;
