import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import LoginComponent from "../screens/login";

/* Admin Modules */
import AdminDashboardComponent from "../screens/admin/dashboard";
import AdminProfileComponent from "../screens/admin/profiles";
import AdminEntityComponent from "../screens/admin/entities";
import AdminPersonaComponent from "../screens/admin/personas";
import AdminModuleConfigComponent from "../screens/admin/settings/modules";
import AdminRoleConfigComponent from "../screens/admin/settings/roles";
import AdminSettingsConfigComponent from "../screens/admin/settings/actsettings";
import AdminDocumentMappingComponent from "../screens/admin/settings/doctypemap";
import AdminDocEntityMappingComponent from "../screens/admin/settings/docentitymap";

/* User Modules */
import DashboardComponent from "../screens/user/dashboard";
import ProfileComponent from "../screens/user/profiles";

/* Sub Users */
import SubUserComponent from "../screens/user/settings/users";
import BorrowerLOComponent from "../screens/user/settings/mapborrowerlo";
import MapLotoLoan from "../screens/user/settings/maplotoloan";

/* User Accounts */
import AccountsComponent from "../screens/user/settings/accounts";

/* User Loan Modules */
import LoanComponent from "../screens/user/loans/newloan";
import StakeHolderComponent from "../screens/user/loans/stakeholders";
import SearchLoanComponent from "../screens/user/loans/searchloan";

/* User Document Modules */
import DocumentsComponent from "../screens/user/documents/documents";
import DocumentsViewComponent from "../screens/user/documents/docview";
import DocumentApprovaComponent from "../screens/user/documents/docapproval";

/* Borrower Modules */
import BDashBoardComponent from "../screens/borrower/dashboard";

/* Loan Officer Modules */
import LDashBoardComponent from "../screens/loanofficer/dashboard";

import Session from "../session";

const IsPathMatch = (path) => {
  let items = ["/asr", "/asm", "/asa", "/uss",
    "/usa", "/asd", "/ade", "/uso", "/usm"];
  let idx = items.findIndex((x) => path.startsWith(x));
  return idx > -1;
};

function PrivateRoute({ children }) {
  let loggedin = Session.IsLoggedIn();
  let mnuFound = false;
  let prevMnu = null;
  const loc = useLocation();
  if (loggedin) {
    if (IsPathMatch(loc.pathname)) {
      mnuFound = true;
    } else {
      mnuFound = Session.IsMenuFound(loc.pathname);
    }
    let _prev = Session.Retrieve1("prev") || "ud";
    prevMnu = `/${_prev}`;
  }
  return loggedin ? (
    mnuFound ? (
      children
    ) : (
      <Navigate to={prevMnu} />
    )
  ) : (
    <Navigate to="/" />
  );
}

const OnDocumentView = () => {
  const loc = useLocation();
  let tmp = "";
  if (loc) {
    tmp = loc.pathname.split('/');
    let origin = window.location.origin;
    let path = `/${tmp[1]}`;
    let param = tmp[2];
    param = `?q=/ucv/${param}`;
    if (path === '/ocv') {
      window.location.href = `${origin}${param}`;
    }
  }
  return null;
}

const RendorRoutes = () => {
  return (
    <Routes>
      <Route
        path="/ad"
        element={
          <PrivateRoute>
            <AdminDashboardComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ap"
        element={
          <PrivateRoute>
            <AdminProfileComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ae"
        element={
          <PrivateRoute>
            <AdminEntityComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/au"
        element={
          <PrivateRoute>
            <AdminPersonaComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/asm"
        element={
          <PrivateRoute>
            <AdminModuleConfigComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/asr"
        element={
          <PrivateRoute>
            <AdminRoleConfigComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/asa"
        element={
          <PrivateRoute>
            <AdminSettingsConfigComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ade"
        element={
          <PrivateRoute>
            <AdminDocEntityMappingComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/asd"
        element={
          <PrivateRoute>
            <AdminDocumentMappingComponent />
          </PrivateRoute>
        }
      />

      <Route
        path="/ud"
        element={
          <PrivateRoute>
            <DashboardComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/up"
        element={
          <PrivateRoute>
            <ProfileComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ula"
        element={
          <PrivateRoute>
            <LoanComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/uls"
        element={
          <PrivateRoute>
            <StakeHolderComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/uls/:id"
        element={
          <PrivateRoute>
            <StakeHolderComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ucs"
        element={
          <PrivateRoute>
            <SearchLoanComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ucu"
        element={
          <PrivateRoute>
            <DocumentsComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ucu/:id"
        element={
          <PrivateRoute>
            <DocumentsComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ucv"
        element={
          <PrivateRoute>
            <DocumentsViewComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ucv/:id"
        element={
          <PrivateRoute>
            <DocumentsViewComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/uss"
        element={
          <PrivateRoute>
            <SubUserComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/uso"
        element={
          <PrivateRoute>
            <BorrowerLOComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/usa"
        element={
          <PrivateRoute>
            <AccountsComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/usm"
        element={
          <PrivateRoute>
            <MapLotoLoan />
          </PrivateRoute>
        }
      />
      <Route
        path="/bd"
        element={
          <PrivateRoute>
            <BDashBoardComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/ld"
        element={
          <PrivateRoute>
            <LDashBoardComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/udp"
        element={
          <PrivateRoute>
            <DocumentApprovaComponent />
          </PrivateRoute>
        }
      />
      <Route
        path="/udp/:id"
        element={
          <PrivateRoute>
            <DocumentApprovaComponent />
          </PrivateRoute>
        }
      />

      <Route path="/ocv" element={OnDocumentView()} />
      <Route path="/" exact element={<LoginComponent />} />
    </Routes>
  );
};

export default RendorRoutes;
