import API from "./api";
import session from "../session";

const Add = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`docaccess`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Get = async (loanid, typeid) => {
  let token = await session.GetHeader();
  try {
    let qry = `?l=${loanid}&t=${typeid}`;
    const res = await API.get(`docaccess${qry}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Delete = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.delete(`docaccess/${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export { Add, Get, Delete };
