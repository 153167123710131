import API from "./api";
import session from "../session";

const GetBorrower = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getborrowers?name=${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};
const GetLoanOfficers = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getloanofficers?name=${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetAllBorrowers = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getallborrowers?type=${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};
const GetAllLoanOfficers = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getallloanofficers`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetLenders = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getlenders`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetUserTypes = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getusertypes`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetEthnicities = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getethnicities`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetRaces = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getraces`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetLoanTypes = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getloantypes`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetModuleFilters = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getmodulefilters`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetLoanPurposeTypes = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getloanpurpose`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetAllLoans = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getallloans`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export {
  GetBorrower,
  GetLoanOfficers,
  GetLenders,
  GetUserTypes,
  GetEthnicities,
  GetRaces,
  GetLoanTypes,
  GetAllBorrowers,
  GetAllLoanOfficers,
  GetModuleFilters,
  GetLoanPurposeTypes,
  GetAllLoans
};
