import API from "./api";
import session from "../session";

const GetUsers = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`adminusers`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetEntityModules = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`getentitymodules`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetEntityModules = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`setentitymodules`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetEntityRoles = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getentityroles/${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetEntityRoles = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`setentityroles`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetDocTypes = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`admgetdoctypes`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetDocParties = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`admgetdocparties/${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SetDocParties = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`admgetdocparties`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};


export {
  GetUsers,
  GetEntityModules,
  SetEntityModules,
  GetEntityRoles,
  SetEntityRoles,
  GetDocTypes,
  GetDocParties,
  SetDocParties
};
