import { React, Header, Footer } from "../../../cIncludes";
import RenderAddNewPersonaComponent from "./RenderAddNewPersonaComponent";
import RenderPersonaListComponent from "./RenderPersonaListComponent";

const AdminPersonaComponent = () => {
  return (
    <>
      <Header hname="au" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_20by80">
                <li className="col_20by80">
                  <RenderAddNewPersonaComponent />
                </li>
                <li className="col_20by80">
                  <RenderPersonaListComponent />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminPersonaComponent;
