import { React, Loader, Header, Footer } from "../../../cIncludes";
import RenderAcctDetailsComponent from "./RenderAcctDetailsComponent";
import RenderProfDetailsComponent from "./RenderProfDetailsComponent";
import { GetProfile as GetProfiles } from "../../../services/usersApi";

const AdminProfileComponent = () => {
  const [progress, setProgress] = React.useState(false);
  const [initlized, setInitlized] = React.useState(false);
  const [actprofile, setActProfile] = React.useState(false);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);
    let rslt = await GetProfiles();
    if (rslt.status === 100) {
      setActProfile(rslt.data);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  return (
    <>
      <Loader show={progress} />
      <Header hname="ap" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_20by80">
                <li className="col_20by80">
                  <RenderAcctDetailsComponent actprofile={actprofile} />
                </li>
                <li className="col_20by80">
                  <RenderProfDetailsComponent
                    actprofile={actprofile}
                    setActProfile={setActProfile}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AdminProfileComponent;
