import { React, Session, Loader, Helper, ViewPdfDoc } from "../../../cIncludes";

import RolesClass from "../../../helper/roles";
import UploadHelper from "../../../helper/upload";

import {
  Get as GetProfile,
  Set as SetProfile,
  DeleteDocument,
} from "../../../services/profilesApi";

import { GetDocUrl } from "../../../services/documentsApi";

const RenderInputType = (props) => {
  const { value, item, OnInputChanged, errors, readonly } = props;

  let type = item.type || "";

  const OnBlurPhoneChanged = (name, val) => {
    val = Helper.ToUSAPhone(val);
    if (val === null) return;
    OnInputChanged(name, val);
  };

  if (type.toUpperCase() === "PHONE") {
    return (
      <input
        type="text"
        value={Helper.ToString(value)}
        onChange={(e) => OnInputChanged(item.name, e.target.value)}
        readOnly={readonly}
        style={{
          borderColor:
            errors && errors.indexOf(item.name) > -1 ? "#ff0000" : undefined,
        }}
        onKeyPress={(e) => {
          return Helper.AcceptedChars(e, "PHONE");
        }}
        onBlur={(e) => OnBlurPhoneChanged(item.name, e.target.value)}
      />
    );
  } else if (item.type.toUpperCase() === "INT") {
    return (
      <input
        type="text"
        value={Helper.ToString(value)}
        onChange={(e) => OnInputChanged(item.name, e.target.value)}
        readOnly={readonly}
        style={{
          borderColor:
            errors && errors.indexOf(item.name) > -1 ? "#ff0000" : undefined,
        }}
        onKeyPress={(e) => {
          return Helper.AcceptedChars(e, "NFINANCE");
        }}
      />
    );
  } else if (item.type.toUpperCase() === "FLOAT") {
    return (
      <input
        type="text"
        value={Helper.ToString(value)}
        onChange={(e) => OnInputChanged(item.name, e.target.value)}
        readOnly={readonly}
        style={{
          borderColor:
            errors && errors.indexOf(item.name) > -1 ? "#ff0000" : undefined,
        }}
        onKeyPress={(e) => {
          return Helper.AcceptedChars(e, "FINANCE");
        }}
      />
    );
  } else if (item.type.toUpperCase() === "YESNO") {
    return (
      <div className="genericBox">
        <ul className="formColumn">
          <li className="formColumn_inline">
            <div className="checkRadio_Lable">
              <input
                type="radio"
                id={`${item.name}_1`}
                name={`${item.name}`}
                onChange={(e) => OnInputChanged(item.name, 1)}
                checked={Helper.ToString(value) === "1"}
              />
              <label htmlFor={`${item.name}_1`}>Yes</label>
            </div>
          </li>
          <li className="formColumn_inline">
            <div className="checkRadio_Lable">
              <input
                type="radio"
                id={`${item.name}_2`}
                name={`${item.name}`}
                checked={Helper.ToString(value) === "0"}
                onChange={(e) => OnInputChanged(item.name, 0)}
              />
              <label htmlFor={`${item.name}_2`}>No</label>
            </div>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <input
        type="text"
        value={Helper.ToString(value)}
        onChange={(e) => OnInputChanged(item.name, e.target.value)}
        readOnly={readonly}
        style={{
          borderColor:
            errors && errors.indexOf(item.name) > -1 ? "#ff0000" : undefined,
        }}
      />
    );
  }
};

const RenderProfDetailsComponent = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [updated, setUpdated] = React.useState(null);
  const [fsupp, setFSupp] = React.useState(null);
  const [ferror, setFError] = React.useState(null);
  const [serror, setSError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [fileurl, setFileUrl] = React.useState(null);
  //const [locname, setLocName] = React.useState(null);
  const [pdfStream, setPdfStream] = React.useState(null);
  const [viewPdf, setViewPdf] = React.useState(false);
  const [parentid, setParentId] = React.useState(0);

  let RolesObj = new RolesClass();
  const maxsize = 106000000;
  const formats = ["PNG", "JPG", "JPEG", "PDF"];

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);
    let tmp = await Session.RetrieveJSONAsync("user");
    let _parentId = tmp.parentid;
    setParentId(_parentId);
    let rslt = await GetProfile(_parentId);
    if (rslt.status === 100) {
      //setLocName(rslt.data.folder);
      setRows(rslt.data.schema);
      setRow(rslt.data.values);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    setSError(null);
    setFError(null);
    setFSupp(null);
    setErrors([]);
    setViewPdf(false);
    FetchResults();
  }

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnUpdateProfile = async (e) => {
    e.preventDefault();
    setError(null);
    setErrors([]);
    setProgress(true);
    setUpdated(null);
    let rslt = await SetProfile(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
      return;
    }
    setUpdated(rslt.statusText);
    await Session.StoreJSON("status", 1);
    await FetchResults();
  };

  const OnUploadDocument = async (e) => {
    e.preventDefault();
    setFError(null);
    setSError(null);
    if (Helper.IsNullValue(fileurl)) {
      setFError("All fields are mandatory");
    } else if (fileurl.size > maxsize) {
      setFError("File size allowed: 100mb");
    } else {
      let rslt;
      setProgress(true);
      rslt = await UploadHelper.UploadProfileDocs(fileurl);
      setProgress(false);
      if (rslt.status !== 100) {
        setFError(rslt.statusText);
        return;
      }
      let tmp = rows.filter((x) => x.type === "doc");
      if (tmp && tmp.length > 0) {
        tmp = tmp[0].name;
        OnInputChanged(tmp, rslt.data.filename);
        OnInputChanged("blobname", rslt.data.blobname);
      }
      setFSupp(null);
      setFError(null);
      setSError("Ok");
      setFileUrl(null);
      Helper.NativeInputClick("proiddoc");
    }
  };

  const OnFileInputChanged = (e) => {
    setFSupp(false);
    e.preventDefault();
    let _file = e.target.files[0];
    if (!Helper.IsNullValue(_file)) {
      let _ext = _file.name.split(".").pop();
      let _index = formats.findIndex((x) => x === _ext.toUpperCase());
      if (_index === -1) {
        setFSupp(true);
        Helper.NativeInputClick("proiddoc");
      } else {
        setFileUrl(e.target.files[0]);
      }
    }
  };

  const OnViewDocument = async (e, t) => {
    console.log(`File:${e} - Type:${t}`);
    let data = {
      doc: e,
      type: t,
      entity: "PROFILE",
    };
    setProgress(true);
    let rslt = await GetDocUrl(data);
    setProgress(false);
    if (rslt.status === 100) {
      setPdfStream(rslt.data);
      setViewPdf(true);
    } else {
      alert(rslt.statusText);
    }
  };

  const OnDeleteDocument = async (e) => {
    setProgress(true);
    let rslt = await DeleteDocument();
    if (rslt.status === 100) {
      OnInputChanged(rslt.data, null);
    }
    setProgress(false);
  };

  const IsReadOnly = () => {
    if (RolesObj.IsProfileEdit && RolesObj.IsCompanyProfileEdit) {
      return false;
    } else if (RolesObj.IsCompanyProfileEdit && parentid > 0) {
      return false;
    } else if (RolesObj.IsProfileEdit) {
      return false;
    }
  };

  return (
    <>
      <Loader show={progress} />
      <li className="col_20by80">
        <div className="blk_container">
          <div className="head_blk">
            <div className="blkName">
              {parentid > 0 ? "Company Profile Details" : "Profile Details"}
            </div>
          </div>
          <div className="contentarea">
            <ul className="formColumn">
              {rows &&
                rows
                  .filter((z) => z.type !== "doc" && z.type !== "comment")
                  .map((x, index) => {
                    return (
                      <li key={index} className="formColumn_III">
                        <div className="formContentHead">{x.title}</div>
                        {Helper.IsRequired(x.required) && (
                          <div className="formMandatory">*</div>
                        )}
                        {!Helper.IsNullValue(x.icon) && (
                          <div className={x.icon}></div>
                        )}
                        <RenderInputType
                          value={row[x.name]}
                          item={x}
                          OnInputChanged={OnInputChanged}
                          errors={errors}
                          readonly={IsReadOnly()}
                        />
                      </li>
                    );
                  })}

              {rows &&
                rows
                  .filter((z) => z.type === "doc")
                  .map((x, index) => {
                    return (
                      <li key={index} className="formColumn_I">
                        <div className="formContentHead">
                          Upload Documents for - {x.title}
                        </div>
                        {x.required && <div className="formMandatory">*</div>}
                        <div className="formContentHead_right">
                          {fsupp && (
                            <div
                              className="fileFormat"
                              style={{
                                float: "left",
                                marginRight: 2,
                                background: "#CC9900",
                                color: "#FFFFFF",
                              }}
                            >
                              Supported Format: PNG, JPG, JPEG and PDF
                            </div>
                          )}
                          {serror && (
                            <div
                              className="uploadSuccess"
                              style={{ float: "left", marginRight: 2 }}
                            >
                              Uploaded Successfully
                            </div>
                          )}
                          {!Helper.IsNullValue(row[x.name]) && (
                            <>
                              <input
                                type="button"
                                className="button_ViewDelete"
                                style={{
                                  marginRight: 2,
                                }}
                                value="View ISP File"
                                onClick={(e) =>
                                  OnViewDocument(row["blobname"], "ISP")
                                }
                              />
                              <input
                                type="button"
                                className="button_ViewDelete"
                                style={{
                                  marginRight: 2,
                                }}
                                value="View File"
                                onClick={(e) =>
                                  OnViewDocument(row[x.name], "AWS")
                                }
                              />
                              <input
                                type="button"
                                className="button_ViewDelete"
                                value="Delete"
                                onClick={(e) => OnDeleteDocument(row["id"])}
                              />
                            </>
                          )}
                        </div>

                        {!IsReadOnly() && (
                          <div className="fileUpload">
                            <div
                              className="uploadBox"
                              style={{
                                marginRight: 2,
                                borderColor:
                                  errors && errors.indexOf(x.name) > -1
                                    ? "#ff0000"
                                    : undefined,
                              }}
                            >
                              <input
                                id="proiddoc"
                                type="file"
                                name="proiddoc"
                                onClick={(e) => {
                                  e.target.value = null;
                                }}
                                onChange={(e) => OnFileInputChanged(e)}
                              />
                            </div>
                            <div className="buttonUploadLayout">
                              <input
                                type="submit"
                                className="buttonUpload"
                                value="Upload"
                                onClick={(e) => OnUploadDocument(e)}
                              />
                            </div>
                            {ferror && (
                              <div className="submitError">{ferror}</div>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}

              {rows &&
                rows
                  .filter((z) => z.type === "comment")
                  .map((x, index) => {
                    return (
                      <li key={index} className="formColumn_I">
                        <div className="formContentHead">{x.title}</div>
                        {x.required && <div className="formMandatory">*</div>}
                        <div className={x.icon}></div>
                        <input
                          type="text"
                          value={Helper.ToString(row[x.name])}
                          onChange={(e) =>
                            OnInputChanged(x.name, e.target.value)
                          }
                          style={{
                            borderColor:
                              errors && errors.indexOf(x.name) > -1
                                ? "#ff0000"
                                : undefined,
                          }}
                          readOnly={IsReadOnly()}
                        />
                      </li>
                    );
                  })}
            </ul>
            <ViewPdfDoc
              stream={pdfStream}
              setViewPdf={setViewPdf}
              viewPdf={viewPdf}
            />
            {!IsReadOnly() && (
              <div className="form_btn_label_notop">
                <input
                  type="button"
                  value={Helper.ToInteger(row.id) < 1 ? "Submit" : "Update"}
                  className="form_btn"
                  onClick={(e) => OnUpdateProfile(e)}
                />
                {Helper.ToInteger(row.id) < 1 && (
                  <input
                    type="button"
                    value="Reset"
                    className="form_btn"
                    onClick={() => setRow({})}
                  />
                )}
              </div>
            )}
            {error && <div className="errormessage">{error}</div>}
            {updated && <div className="successmessage">{updated}</div>}
          </div>
        </div>
      </li>
    </>
  );
};

export default RenderProfDetailsComponent;
