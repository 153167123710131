import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Session from "../../session";
import MsgAlertComponent from "./msgalerts";
import ChangePasswordComponent from "./changepws";
import helper from "../../helper";
import useTimerSession from "../../helper/useTimerSession";


const OnLinkClicked = (e, roleId, status, nav, url) => {
  e.preventDefault();
  if (parseInt(status) === 1 || url === 'up' || parseInt(roleId) === 1) {
    nav(`/${url}`);
  }
};

const RenderDrawPullDownMenus = ({ roleId, nav, userStatus, mnuName, menu }) => {
  if (
    menu.link === "us" ||
    menu.link === "asm" ||
    menu.link === "uss" ||
    menu.link === "uso"
  )
    return null;
  return (
    <div className="dropdown">
      <button className={mnuName === menu.link ? "dropbtn active" : "dropbtn"}>
        <div
          className={mnuName === menu.link ? menu.icon + " active" : menu.icon}
        ></div>
        {menu.label} &#9660;
      </button>
      <div className="dropdown-content">
        {menu.childs.map((x, index) => {
          return (
            <Link key={index}
              to="#" onClick={(e) => OnLinkClicked(e, roleId, userStatus, nav, x.link)}>
              {x.label}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

const RenderMenuItem = ({ roleId, nav, userStatus, mnuName, menu }) => {

  if (menu.link === "us" || menu.link === "asm" || menu.link === "uss")
    return null;
  return (
    <Link
      to="#"
      onClick={(e) => OnLinkClicked(e, roleId, userStatus, nav, menu.link)}
      className={mnuName === menu.link ? "active" : undefined}
    >
      <div
        className={mnuName === menu.link ? menu.icon + " active" : menu.icon}
      ></div>
      {menu.label}
    </Link>
  );
};

const HeaderComponent = ({ hname }) => {
  const [name, setName] = React.useState("Un-Known");
  const [role, setRole] = React.useState("");
  const [roleId, setRoleId] = React.useState(0);
  const [userStatus] = useTimerSession('status');
  const [mnuItems, setMnuItems] = React.useState([]);
  const [changepws, setChangePws] = React.useState(false);

  const NavigateTo = useNavigate();
  let mnuName = hname || "";

  React.useEffect(() => {
    const fn = async () => {
      let data = await Session.RetrieveJSONAsync("user");
      setName(data.name);
      setRole(data.rolename);
      setRoleId(data.role);
      let modules = await Session.RetrieveJSONAsync("modules");
      setMnuItems(modules);
    };
    fn();
  }, [hname]);

  const OnLogoutClicked = async (e) => {
    e.preventDefault();
    await Session.Remove("user");
    await Session.Remove("modules");
    await Session.Remove("userToken");
    await Session.Remove("prev");
    NavigateTo("/");
  };

  const OnChangePasswordClicked = (e) => {
    setChangePws(true);
  };

  return (
    <>
      <header id="header">
        <div className="widthfix">
          <div className="headerRightBlock">
            <div className="profileMenu">
              <div className="nameMenu_blk">
                <div
                  className="menuname"
                  onClick={(e) => window.onMenuNameClicked(e)}
                >
                  <div className="dropmenu_name">{name}</div>
                  <div className="dropmenu_desc">{role}</div>
                </div>
              </div>
              <div className="touchmenu">
                <div className="dropmenu-structure">
                  <div className="dropmenu-content">
                    <div id="menu-box">
                      <div className="menu-navi">
                        <div className="profile-menu">
                          <ul className="nav-bar">
                            <li className="nav-account">
                              <Link to="/ap">My Profile</Link>
                            </li>
                            <li className="nav-pswd">
                              <Link
                                to="#"
                                onClick={(e) => OnChangePasswordClicked(e)}
                              >
                                Change Password
                              </Link>
                            </li>
                            <li className="nav-logout">
                              <Link to="#" onClick={(e) => OnLogoutClicked(e)}>
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {helper.IsFound(mnuItems, "label", "Settings") && (
              <Link
                to={helper.GetSettingsLink(mnuItems, "label", "Settings")}
                title="Settings"
              >
                <div className="settingicon"></div>
              </Link>
            )}
            <Link
              to="#"
              className="alert_Show"
              title="Alerts"
              onClick={() => window.OnAlertShow(0)}
            >
              <div className="alerticon_notActive"></div>
            </Link>
          </div>
        </div>

        <div className="widthfix">
          <div className="topnav" id="myTopnav">
            <Link to="#">
              <div className="logoPos_aura i3Logo_white"></div>
            </Link>

            {mnuItems &&
              mnuItems.map((x, index) => {
                if (x.childs && x.childs.length > 0) {
                  return (
                    <RenderDrawPullDownMenus
                      mnuName={mnuName}
                      key={index}
                      menu={x}
                      nav={NavigateTo}
                      roleId={roleId}
                      userStatus={userStatus} />
                  );
                } else {
                  return (
                    <RenderMenuItem
                      mnuName={mnuName}
                      key={index}
                      menu={x}
                      nav={NavigateTo}
                      roleId={roleId}
                      userStatus={userStatus} />
                  );
                }
              })}

            <Link
              to="#"
              className="icon"
              onClick={() => window.onMenuIconClicked()}
            ></Link>
          </div>
        </div>
      </header>
      <MsgAlertComponent />
      <ChangePasswordComponent
        changepws={changepws}
        setChangePws={setChangePws}
      />
    </>
  );
};

export default HeaderComponent;
