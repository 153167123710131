import { React, Helper } from "../../../cIncludes";
import ChangePasswordComponent from "../../common/changepws";

const RenderAcctDetailsComponent = ({ actprofile }) => {
  const [changepws, setChangePws] = React.useState(false);

  const OnChangePasswordClicked = (e) => {
    setChangePws(true);
  };

  return (
    <>
      <div className="blk_container">
        <div className="head_blk">
          <div className="blkName">Account Details</div>
        </div>
        <div className="contentarea_bgwhite">
          <div className="accDetails">
            <div className="acc_name">
              {Helper.GetStringJoin(actprofile, ["first_name", "last_name"])}
            </div>
            <div className="memberBlock">
              <div className="memberHead">Member Since:&nbsp;</div>
              <div className="memberTxt">
                {Helper.ToDate(actprofile.createdon, "MMM DD, YYYY")}
              </div>
            </div>
            <div className="memberBlock" style={{ display: "none" }}>
              <div className="memberHead">NMLS ID:</div>
              <div className="memberTxt">1234567890</div>
            </div>
            <div className="mailPhBlock">
              <div className="horBlk">
                <div className="mailIcon"></div>
                <div className="mailPh_txt"></div>
                {actprofile.email}
              </div>
              <div className="horBlk">
                <div className="phIcon"></div>
                <div className="mailPh_txt">{actprofile.mobile_number}</div>
              </div>
            </div>
            <div className="acc_btn_label">
              <input
                type="button"
                className="acc_btn"
                value="Change Password"
                onClick={(e) => OnChangePasswordClicked(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordComponent
        changepws={changepws}
        setChangePws={setChangePws}
      />
    </>
  );
};

export default RenderAcctDetailsComponent;
