import API from "./api";
import session from "../session";

const Add = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post(`stakeholders`, input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Get = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`stakeholders/${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Delete = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.delete(`stakeholders/${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export { Add, Get, Delete };
