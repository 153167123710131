import { React, Header, Footer } from "../../../cIncludes";

import RenderAddNewEntityComponent from "./RenderAddNewEntityComponent";
import RenderEntitiesListComponent from "./RenderEntitiesListComponent";

const AdminEntityComponent = () => {
  return (
    <>
      <Header hname="ae" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_20by80">
                <li className="col_20by80">
                  <RenderAddNewEntityComponent />
                </li>
                <li className="col_20by80">
                  <RenderEntitiesListComponent />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminEntityComponent;
