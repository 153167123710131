import { React, Helper } from "../../../cIncludes";
import { GetBorrower } from "../../../services/commonApi";

const RenderBorrowerSelect = ({
  onProgress,
  showborrower,
  onBorrowerSelect,
}) => {
  const [selectedId, setSelectedId] = React.useState(-1);
  const [name, setName] = React.useState(null);
  const [borrowers, setBorrowers] = React.useState([]);
  const [noContent, setNoContent] = React.useState(
    "Search Borrower to view the list"
  );

  const defaultValues = {
    first_name: null,
    middle_name: null,
    last_name: null,
    ispid: null,
  };

  const ResetControl = () => {
    setBorrowers([]);
    setSelectedId(-1);
    setNoContent("Search Borrower to view the list");
    setName(null);
  };

  const OnCancelClicked = (e) => {
    e.preventDefault();
    ResetControl();
    if (onBorrowerSelect) onBorrowerSelect(defaultValues);
  };

  const OnSubmitClicked = (e) => {
    e.preventDefault();
    let _found = borrowers.filter(
      (x) => parseInt(x.id) === parseInt(selectedId)
    );
    if (onBorrowerSelect) {
      ResetControl();
      let tmp = defaultValues;
      if (_found.length > 0) tmp = _found[0];
      onBorrowerSelect(tmp);
    }
  };

  const OnBorrowerSelected = (e) => {
    setSelectedId(e);
  };

  const OnSearchClicked = async (e) => {
    e.preventDefault();
    setBorrowers([]);
    setNoContent("No Borrower available");
    if (!Helper.IsNullValue(name)) {
      onProgress(true);
      let rslt = await GetBorrower(name);
      onProgress(false);
      if (rslt.status === 100) {
        setBorrowers(rslt.data);
      }
    }
  };

  return showborrower ? (
    <div
      id="popSearchBorrower"
      className="pop_disable_bg"
      style={{ display: "block" }}
    >
      <div className="srcBorrower">
        <div className="pop_head_left">Search Borrower</div>
        <div className="pop_contentArea">
          <ul className="pop_formCol">
            <li className="pop_2col_30by70">
              <div className="srchBor">
                <input
                  type="text"
                  placeholder="Borrower Name"
                  value={Helper.ToString(name)}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </li>
            <li className="pop_2col_30by70">
              <input
                type="button"
                className="pop_button"
                value="Search"
                onClick={(e) => OnSearchClicked(e)}
              />
            </li>
          </ul>
          <div className="pop_contentArea_table_border">
            {borrowers && borrowers.length === 0 ? (
              <div className="noContent" style={{ display: "block" }}>
                {noContent}
              </div>
            ) : (
              <>
                <div className="pop_searchBorrower_Table_head">
                  <table>
                    <thead>
                      <tr>
                        <td>&nbsp;</td>
                        <td>First Name</td>
                        <td>Middle Name</td>
                        <td>Last Name</td>
                        <td>City</td>
                        <td>Phone</td>
                        <td>Email</td>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div className="pop_searchBorrower_Table">
                  <table>
                    <tbody>
                      {borrowers &&
                        borrowers.map((x, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  type="radio"
                                  id={`borrower_${x.id}`}
                                  name="selectbor"
                                  onClick={() => OnBorrowerSelected(x.id)}
                                  value={Helper.ToString(selectedId)}
                                />
                                <label htmlFor={`borrower_${x.id}`}></label>
                              </td>
                              <td>{x.first_name} </td>
                              <td>{x.middle_name}</td>
                              <td>{x.last_name}</td>
                              <td>{x.city}</td>
                              <td>{x.cell_phone}</td>
                              <td>{x.email}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
          <div className="pop_buttonLabel_noTop">
            {borrowers && borrowers.length > 0 && (
              <input
                type="button"
                value="Select Borrower"
                className="pop_button"
                onClick={(e) => OnSubmitClicked(e)}
              />
            )}
            <input
              type="button"
              value="Close"
              className="pop_button"
              onClick={(e) => OnCancelClicked(e)}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default RenderBorrowerSelect;
