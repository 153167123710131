import API from "./api";
import session from "../session";

const Get = async (bUpload, bManage, bStakes) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`loan?u=${bUpload}&m=${bManage}&s=${bStakes}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetManagedLoans = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getmanagedloans`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetSakeHoldersLoans = async () => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`getsakeholdersloans`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const GetLoanById = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.get(`findloanbyid?l=${input}`, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const Set = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post("loan", input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

const SearchLoan = async (input) => {
  let token = await session.GetHeader();
  try {
    const res = await API.post("searchloan", input, {
      headers: token,
    });
    return session.IsSessionExpired(res.data);
  } catch (err) {
    return { statusText: "Could not connect to server", status: 900 };
  }
};

export { Get, Set, GetLoanById, SearchLoan, GetManagedLoans, GetSakeHoldersLoans };
