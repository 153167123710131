import { React, Link, Header, Footer } from "../../../cIncludes";

const AdminSettingsConfigComponent = () => {
  return (
    <>
      <Header hname="as" />
      <div id="container">
        <div id="content">
          <div className="widthfix_10px">
            <div id="pgstructure">
              <ul className="col_15by85">
                <li className="colLeft250">
                  <div className="blk_container">
                    <ul className="accLeftNavi">
                      <li className="accLeftNavi">
                        <Link to="/asm">
                          <div className="leftNavi_configIcon"></div>Module
                          Configuration
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/asd">
                          <div className="leftNavi_userIcon"></div>Document Mapping
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/ade">
                          <div className="leftNavi_userIcon"></div>Document Entity Mapping
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="/asr">
                          <div className="leftNavi_userIcon"></div>Role Mapping
                        </Link>
                      </li>
                      <li className="accLeftNavi">
                        <Link to="#" className="current">
                          <div className="leftNavi_settingIcon"></div>Account
                          Settings
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="col_100pc-250">
                  <div className="blk_container">
                    <div className="head_blk">
                      <div className="blkName">Account Settings</div>
                    </div>
                    <div className="contentarea">
                      <div className="noContent" style={{ display: "block" }}>
                        No data available
                      </div>
                      <div className="config" style={{ display: "none" }}>
                        <div className="selectModuleBlk">
                          <ul className="formColumn">
                            <li className="formColumn_III">
                              <div className="entityFormicon"></div>
                              <select
                                name="filterType"
                                required
                                defaultValue={0}
                              >
                                <option value="0">Select Entity</option>
                                <option value="1">Loan Officer</option>
                                <option value="2">Correspondent Bank</option>
                                <option value="3">Borrower</option>
                              </select>
                            </li>
                          </ul>
                        </div>
                        <div className="configHead">Correspondent Bank</div>
                        <ul className="formColumn">
                          <li className="formColumn_III">
                            <ul className="configStructure">
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config1" />
                                <label htmlFor="config1">Dashboard</label>
                              </li>
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config2" />
                                <label htmlFor="config2">My Profile</label>
                              </li>
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config3" />
                                <label htmlFor="config3">Loans</label>
                              </li>
                              <li className="configStructure_child">
                                <input type="checkbox" id="config4" />
                                <label htmlFor="config4">Search Loans</label>
                              </li>
                              <li className="configStructure_child">
                                <input type="checkbox" id="config5" />
                                <label htmlFor="config5">Add Loans</label>
                              </li>
                              <li className="configStructure_child">
                                <input type="checkbox" id="config6" />
                                <label htmlFor="config6">
                                  Assign Stakeholders
                                </label>
                              </li>
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config7" />
                                <label htmlFor="config7">Documents</label>
                              </li>
                              <li className="configStructure_child">
                                <input type="checkbox" id="config8" />
                                <label htmlFor="config8">
                                  Upload Documents
                                </label>
                              </li>
                              <li className="configStructure_child">
                                <input type="checkbox" id="config9" />
                                <label htmlFor="config9">View Documents</label>
                              </li>
                            </ul>
                          </li>
                          <li className="formColumn_III">
                            <ul className="configStructure">
                              <li className="configStructure_parent">
                                <div className="configHead">Options</div>
                                <div>LoanSelectionType</div>
                                <div>
                                  <select
                                    name="filterType"
                                    required
                                    defaultValue={0}
                                  >
                                    <option value="0">Select Type</option>
                                    <option value="1">Text Box</option>
                                    <option value="2">Dropdown</option>
                                  </select>
                                </div>
                              </li>
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config11" />
                                <label htmlFor="config11">
                                  LoanDefaultSelection
                                </label>
                              </li>
                              <li className="configStructure_parent">
                                <div className="configHead">Roles</div>
                              </li>
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config11" />
                                <label htmlFor="config11">ProfilesEdit</label>
                              </li>
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config11" />
                                <label htmlFor="config11">
                                  CompanyProfilesEdit
                                </label>
                              </li>
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config11" />
                                <label htmlFor="config11">UploadDocument</label>
                              </li>
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config11" />
                                <label htmlFor="config11">
                                  UploadDocAccessView
                                </label>
                              </li>
                              <li className="configStructure_parent">
                                <input type="checkbox" id="config11" />
                                <label htmlFor="config11">
                                  UploadDocAccessEdit
                                </label>
                              </li>
                            </ul>
                          </li>
                          <li className="formColumn_III">&nbsp;</li>
                        </ul>
                      </div>

                      <div
                        className="form_btn_label"
                        style={{ display: "none" }}
                      >
                        <input
                          type="button"
                          value="Update"
                          className="form_btn"
                        />
                        <input
                          type="button"
                          value="Reset"
                          className="form_btn"
                        />
                      </div>
                      <div className="errormessage" style={{ display: "none" }}>
                        All fields are mandatory
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AdminSettingsConfigComponent;
