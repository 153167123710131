import { React, Helper, Link, Loader } from "../../../cIncludes";
import { Add as SetEntities, GetISPLinks } from "../../../services/entitiesApi";

const RenderAddNewEntityComponent = () => {
  const [initlized, setInitlized] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [isplinks, setISPLinks] = React.useState([]);

  React.useEffect(() => {
    setInitlized(true);
  }, []);

  const FetchResults = async () => {
    setProgress(true);
    let rslt = await GetISPLinks();
    if (rslt.status === 100) {
      setISPLinks(rslt.data);
    }
    setProgress(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchResults();
  }

  const OnCreateEntity = async (e) => {
    e.preventDefault();
    setError(null);
    setErrors([]);
    setProgress(true);
    let tmp = isplinks.filter((x) => x._id === row.isp_link);
    if (tmp.length > 0) {
      row.isp_link_name = tmp[0].entity_type_name;
    }
    let rslt = await SetEntities(row);
    setProgress(false);
    if (rslt.status !== 100) {
      setErrors(rslt.errors);
      setError(rslt.statusText);
    } else {
      setRow({});
      window.location.reload();
    }
  };

  const OnInputChanged = (name, val) => {
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const OnBlurChanged = (name, val) => {
    val = Helper.ToTable(val);
    if (val === null) return;
    OnInputChanged(name, val);
  };

  return (
    <>
      <Loader show={progress} />
      <div className="blk_container">
        <div className="head_blk">
          <div className="blkName">Create Entity</div>
        </div>
        <div className="contentarea">
          <ul className="formColumn">
            <li className="formColumn_I">
              <div className="formContentHead">Entity Name</div>
              <div className="formMandatory">*</div>
              <div className="entityFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(row.entity_name)}
                style={{
                  borderColor:
                    errors && errors.indexOf("entity_name") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("entity_name", e.target.value)}
                onBlur={(e) => OnBlurChanged("entity_name", e.target.value)}
              />
            </li>
            <li className="formColumn_I">
              <div className="formContentHead">Display Name</div>
              <div className="formMandatory">*</div>
              <div className="userFormicon"></div>
              <input
                type="text"
                value={Helper.ToString(row.display_name)}
                style={{
                  borderColor:
                    errors && errors.indexOf("display_name") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("display_name", e.target.value)}
              />
            </li>
            <li className="formColumn_I">
              <div className="formContentHead">ISP Link Entity</div>
              <div className="formMandatory">*</div>
              <div className="linkFormicon"></div>
              <select
                name="filterType"
                required
                value={Helper.ToString(row.isp_link)}
                style={{
                  borderColor:
                    errors && errors.indexOf("isp_link") > -1
                      ? "#ff0000"
                      : undefined,
                }}
                onChange={(e) => OnInputChanged("isp_link", e.target.value)}
              >
                {isplinks &&
                  isplinks.map((x, index) => {
                    return (
                      <option value={x._id} key={index}>
                        {x.entity_type_name}
                      </option>
                    );
                  })}
              </select>
            </li>
            <li className="formColumn_I">
              <div className="formContentHead">Entity Schema</div>
              <div className="formMandatory">*</div>
              <div className="formContentHead_right">
                <div className="noteLink">
                  <Link to="#">Sample File</Link>
                </div>
              </div>
              <textarea
                placeholder="Paste JSON Schema"
                rows={15}
                value={Helper.ToString(row.entity_schema)}
                onChange={(e) =>
                  OnInputChanged("entity_schema", e.target.value)
                }
                style={{
                  height: 120,
                  borderColor:
                    errors && errors.indexOf("entity_schema") > -1
                      ? "#ff0000"
                      : undefined,
                }}
              ></textarea>
            </li>
          </ul>
          <div className="form_btn_label_notop">
            <input
              type="button"
              value="Create Entity"
              className="form_btn"
              onClick={(e) => OnCreateEntity(e)}
            />
          </div>
          {error && <div className="errormessage">{error}</div>}
        </div>
      </div>
    </>
  );
};

export default RenderAddNewEntityComponent;
