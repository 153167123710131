import { React, Link, useParams, Loader, Helper } from "../../../cIncludes";
import RolesClass from "../../../helper/roles";
import { Get as GetLoans, SearchLoan } from "../../../services/loanApi";
import RenderUpdateLoanComponent from "./RenderUpdateLoan";

const RenderInputType = ({
  type,
  loannumber,
  onLoanInputChanged,
  onSearchClicked,
  loans,
  defSelectedLoanid,
}) => {
  const [selectedLoanid, setSelectedLoanId] = React.useState(0);

  const OnLoanSelected = (e) => {
    let _number = null;
    let item = loans.filter((x) => parseInt(x.id) === parseInt(e));
    if (item.length > 0) {
      _number = item[0].loannumber;
    }
    setSelectedLoanId(e);
    if (onSearchClicked) onSearchClicked(e, _number);
  };

  const OnKeyPressEvent = (e) => {
    let charCode = e.charCode || e.which;
    if (charCode === 13) {
      if (onSearchClicked) onSearchClicked(null, e.target.value);
    }
  };

  React.useEffect(() => {
    if (defSelectedLoanid > 0) {
      setSelectedLoanId(defSelectedLoanid);
    }
  }, [defSelectedLoanid]);

  if (type === 1) {
    return (
      <>
        <input
          type="text"
          value={Helper.ToString(loannumber)}
          onChange={(e) => onLoanInputChanged("loannumber", e.target.value)}
          onKeyPress={(e) => OnKeyPressEvent(e)}
        />
        <Link
          to="#"
          title="Search Loan"
          onClick={(e) => onSearchClicked(null, loannumber)}
        >
          <div className="iconsearch"></div>
        </Link>
      </>
    );
  } else if (type === 2) {
    return (
      <>
        <div className="loanSearchSelect">
          <select
            name="filterType"
            value={Helper.ToString(selectedLoanid)}
            onChange={(e) => OnLoanSelected(e.target.value)}
          >
            <option value="0">Select</option>
            {loans &&
              loans.map((x, index) => {
                return (
                  <option value={x.id} key={index}>
                    {x.loannumber}
                  </option>
                );
              })}
          </select>
        </div>
      </>
    );
  }
};

const RenderLoanSummaryComponent = (props) => {
  const { onLoadStakeHolders, setLoanItem, upload, stakesonly } = props;
  const [initlized, setInitlized] = React.useState(false);
  const [row, setRow] = React.useState({});
  const [loans, setLoans] = React.useState([]);
  const [loanslist, setLoansList] = React.useState([]);
  const [noresults, setNoResults] = React.useState(false);
  const [nostakes, setNoStakes] = React.useState(false);
  const [loanSelection, setLoanSelection] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  const [showmore, setShowMore] = React.useState(false);
  const [loanid, setLoanId] = React.useState(0);

  const { id } = useParams();
  let RolesObj = new RolesClass();

  const OnLoanInputChanged = (name, val) => {
    setNoResults(false);
    setRow((prev) => ({
      ...prev,
      [name]: val,
    }));
    if (setLoanItem) setLoanItem(row);
  };

  const FetchLoans = async () => {
    setProgress(true);
    let _loanId = id || 0;
    let _list = [];
    if (RolesObj.LoanSelectionType === 2) {
      let rslt = await GetLoans(upload, false, stakesonly);
      if (rslt.status === 100) {
        setLoansList(rslt.data);
        _loanId = _loanId === 0 ? GetDefaultSelected(rslt.data) : _loanId;
        _list = rslt.data;
      }
    }
    DoInitSearch(_list, _loanId);
    setProgress(false);
  };

  const DoInitSearch = async (list, e) => {
    let _number = e || null;
    let item = list.filter((x) => parseInt(x.id) === parseInt(e));
    if (item.length > 0) {
      _number = item[0].loannumber;
    }
    setLoanId(e);
    if (_number) await OnSearchClicked(null, _number);
  };

  const GetDefaultSelected = (items) => {
    let _number = items.length > 0 ? items[0].id : 0;
    if (!Helper.IsNullValue(id)) {
      let item = items.filter((x) => x.id === id);
      if (item.length > 0) {
        _number = item[0].id;
      }
    }
    return _number;
  };

  const OnSearchClicked = async (e, tmpId) => {
    setNoResults(false);
    setNoStakes(false);
    setLoanSelection(false);
    OnTriggerStakeHolders(0, null, [], null);
    let rslt;
    let data = { loannumber: tmpId || null, upload: upload, stakes: stakesonly };
    setRow(data);
    let _number = data.loannumber;
    if (!Helper.IsNullValue(_number)) {
      let tmpRslt = undefined;
      setRow(data);
      setProgress(true);
      rslt = await SearchLoan(data);
      setProgress(false);
      if (rslt.status === 100) {
        tmpRslt = rslt.data;
        if (tmpRslt.length === 0) {
          setNoResults(true);
        } else if (tmpRslt.length > 1) {
          setLoans(tmpRslt);
          setLoanSelection(true);
        } else {
          setRow(tmpRslt[0]);
          OnTriggerStakeHolders(tmpRslt[0].id, tmpRslt[0].loannumber,
            tmpRslt[0].borrower_ids, tmpRslt[0].lender_id);
        }
      } else if (rslt.status === 300) {
        setNoStakes(true);
      } else if (rslt.status === 900) {
        setNoResults(true);
      }
    }
  };

  const OnTriggerStakeHolders = (_loanId, _loanNumber, _borrowers, _lender_id) => {
    if (onLoadStakeHolders) {
      onLoadStakeHolders(_loanId, _loanNumber, _borrowers, _lender_id);
    }
  };

  const RendorStatusMessage = (e) => {
    if (nostakes) {
      return <li className="noLoanResults">No Stake Holders are configured</li>;
    } else if (noresults) {
      return <li className="noLoanResults">No Results Found</li>;
    } else {
      return (
        <li className="noLoan">
          Please search for a loan to view the loan details
        </li>
      );
    }
  };

  const OnLoanSelection = (e) => {
    let tmp = loans[e];
    setRow(tmp);
    if (onLoadStakeHolders) {
      onLoadStakeHolders(tmp.id, tmp.loannumber);
    }
    OnClosePopup();
  };

  const OnClosePopup = (e) => {
    setLoanSelection(false);
  };

  const RenderLoanList = ({ item, index }) => {
    return (
      <>
        <li className="pop_3col_selectCo">{index + 1}</li>
        <li className="pop_3col_selectCo">
          <Link to="#" onClick={(e) => OnLoanSelection(index)}>
            {item.issuingauth}
          </Link>
        </li>
        <li className="pop_3col_selectCo">$ {item.loanamount}</li>
      </>
    );
  };

  /* const OnMoreClicked = (e) => {
    e.preventDefault();
    setShowMore(true);
  }; */

  const OnLoanUpdated = (e) => {
    setRow(e);
    setShowMore(false);
  };

  if (initlized) {
    setInitlized(false);
    FetchLoans();
  }

  React.useEffect(() => {
    setInitlized(true);
  }, [id]);

  return (
    <>
      <Loader show={progress} />
      <div className="loanSummary">
        <ul className="summaryVblk">
          <li className="summaryVblk">
            <div className="summaryHead">Loan Number:</div>
            <RenderInputType
              type={RolesObj.LoanSelectionType}
              loannumber={row.loannumber}
              onLoanInputChanged={OnLoanInputChanged}
              onSearchClicked={OnSearchClicked}
              loans={loanslist}
              defSelectedLoanid={loanid}
            />
          </li>
          {Helper.ToInteger(row.id) < 1 ? (
            <RendorStatusMessage />
          ) : (
            <>
              <li className="summaryVblk">
                <div className="summaryHead">Borrower Name:</div>
                <div className="summaryText">{row.borrower}</div>
              </li>
              <li className="summaryVblk">
                <div className="summaryHead">Loan Amount:</div>
                <div className="summaryText">$ {row.loanamount}</div>
              </li>
              <li className="summaryVblk">
                <div className="summaryHead">Issuing Authority:</div>
                <div className="summaryText">{row.issuingauth}</div>
              </li>
              <li className="summaryVblk">
                <div className="summaryHead">Est Close Date:</div>
                <div className="summaryText">
                  {Helper.ToDate(row.closingdate, "MMM DD, YYYY")}
                </div>
              </li>
              <li className="summaryVblk">
                <div className="summaryHead">Property Address:</div>
                <div className="summaryText">{row.propaddress}</div>
              </li>
              <li className="summaryVblk">
                {/* <input
                  type="button"
                  value="More »"
                  className="loanMoreButton"
                  onClick={(e) => OnMoreClicked(e)}
                /> */}
              </li>
            </>
          )}
        </ul>
      </div>

      {loanSelection && (
        <div
          id="popSelectLoan"
          className="pop_disable_bg"
          style={{ display: "block" }}
        >
          <div className="selectLoan">
            <div className="pop_head">
              Select Company with Loan No: {row.loannumber}
            </div>
            <div className="pop_contentArea">
              <ul className="pop_formCol_height150">
                <li className="pop_3col_selectCo">Sl</li>
                <li className="pop_3col_selectCo">Company Name</li>
                <li className="pop_3col_selectCo">Amount</li>
                {loans &&
                  loans.map((x, index) => {
                    return (
                      <RenderLoanList item={x} key={index} index={index} />
                    );
                  })}
              </ul>
              <div className="pop_buttonLabel_noTop">
                <input
                  type="button"
                  value="Close"
                  className="pop_button"
                  onClick={(e) => OnClosePopup(e)}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <RenderUpdateLoanComponent
        onLoanUpdated={OnLoanUpdated}
        loanid={row.id}
        showmore={showmore}
      />
    </>
  );
};

export default RenderLoanSummaryComponent;
